export abstract class CommandBase<TModel> {
  //#region -- fields --

  protected readonly _execute: (model: TModel) => void;
  protected readonly _canExecute: (model: TModel) => boolean;
  protected readonly _isVisible: (model: TModel) => boolean;

  private readonly _tooltip: string;
  private readonly _icon: string;
  private readonly _text: string;

  //#endregion

  //#region -- properties --

  public get tooltip(): string {
    return this._tooltip;
  }

  public get icon(): string {
    return this._icon;
  }

  public get text(): string {
    return this._text;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    tooltip: string,
    icon: string,
    text: string,
    canExecute: (model: TModel) => boolean,
    execute: (model: TModel) => void,
    isVisible: (model: TModel) => boolean = () => true
  ) {
    this._tooltip = tooltip;
    this._icon = icon;
    this._text = text;
    this._canExecute = canExecute;
    this._execute = execute;
    this._isVisible = isVisible;
  }

  //#endregion

  //#region -- methods --

  public canExecute = (model: TModel): boolean =>
    this._canExecute(model) ;

  public execute = (model: TModel): void =>
    this._execute(model);

  public isVisible = (model: TModel): boolean =>
    this._isVisible(model);

  //#endregion
}
