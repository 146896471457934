<div>
  <kendo-textbox placeholder="{{ 'SEARCH.PLACEHOLDER' | translate }}"
                 [clearButton]="true"
                 [(ngModel)]="model"
                 (keydown)="onKeyDown($event)">
    <ng-template kendoTextBoxSuffixTemplate>
      <kendo-textbox-separator></kendo-textbox-separator>
      <clevermailing-command-button *ngFor="let command of commands"
                                    [command]="command"></clevermailing-command-button>
    </ng-template>
  </kendo-textbox>
</div>