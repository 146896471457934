import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Mapper } from '@automapper/types';
import {
  ColumnComponent,
  GridComponent as KendoGridComponent,
  GridDataResult,
  PageChangeEvent,
  RowArgs,
  SelectableSettings,
  SelectionEvent,
  SortSettings,
} from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { List } from 'linqts';
import { BehaviorSubject, merge, Observable, Subject, timer } from 'rxjs';
import { debounce, take } from 'rxjs/operators';
import { GridColumn } from 'src/common/models/gridColumn';
import { ResourceGridDataResult } from 'src/common/models/resourceGridDataResult';
import { SearchCriteria } from 'src/common/models/searchCriteria';
import { SearchResult } from 'src/common/models/searchResult';
import { MAPPER } from 'src/common/token/tokens';
import { Resource } from 'src/common/webapi/contracts/resource';
import { ResourceDefinition } from 'src/common/webapi/contracts/resourceDefinition';
import { applicationEnvironment } from 'src/environments/application.environment';
import { SubscriptionBase } from 'src/shared/base/subscription.base';
import { isNotNullOrUndefinedOrEmpty, isNullOrUndefined } from 'src/shared/helper/object.helper';
import { ValueChangedInfo } from './cells/cell-selector/valueChangedInfo';
import { CommandBase } from './models/command/base/command.base';
import { CommandCellModelBuilderInfo } from './models/command/commandCellModelBuilderInfo';
import { CustomFilterBase } from './models/filter/base/customFilter.base';
import { CustomFilterDescriptor } from './models/filter/customFilterDescriptor';
import { ParameterFilter } from './models/filter/parameterFilter';
import { PartialUpdateValue } from './models/partialUpdateValue';
import { FilterProvider } from './provider/filter.provider';
import { SelectionProvider } from './provider/selection.provider';
import { SortProvider } from './provider/sort.provider';
import { VirtualizationProvider } from './provider/virtualization.provider';

@Component({
  selector: 'clevermailing-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FilterProvider, SortProvider, VirtualizationProvider, SelectionProvider],
})
export class GridComponent extends SubscriptionBase implements OnInit, OnDestroy {
  //#region -- fields --

  private readonly _dataSource: BehaviorSubject<GridDataResult>;
  private readonly _ngZone: NgZone;
  private readonly _searchCriteriasEventEmitter: EventEmitter<SearchCriteria>;
  private readonly _mapper: Mapper;
  private readonly _sortProvider: SortProvider;
  private readonly _filterProvider: FilterProvider;
  private readonly _virtualizationProvider: VirtualizationProvider;
  private readonly _selectionProvider: SelectionProvider;
  private readonly _searchPhraseSource: Subject<string>;
  private readonly _partialValueChangedEventEmitter: EventEmitter<PartialUpdateValue>;

  private _grid: KendoGridComponent;
  private _height: number;
  private _visibleColumns: GridColumn[];
  private _isLoading: boolean;
  private _commands: CommandBase<any>[];
  private _noDataToShow: boolean;
  private _searchPhrase: string;
  private _resourceDefinition: ResourceDefinition;
  private _customFilters: List<CustomFilterBase<any>>;
  private _rowHeight: number;

  // column-field -> max content length
  private _columnContentLengths = new Map<string, number>();
  private _columnsToFit: string[];
  private _fitColumnsWaitingCounter = 0;
  private _columnsNeverFitted = true;

  //#endregion

  //#region -- properties --

  @ViewChild('grid')
  public set grid(value: KendoGridComponent) {
    this._grid = value;
  }

  @Input()
  public set data(value: SearchResult<Resource>) {
    if (isNullOrUndefined(value)) return;

    this._noDataToShow = value.totalCount === 0;
    this._dataSource.next(this._mapper.map(value, ResourceGridDataResult, SearchResult));

    // key -> max string-length of column-value
    const maxLengths = new Map<string, number>();
    const resources = (this._dataSource.value as ResourceGridDataResult).data;
    resources.forEach(resource => {
      resource.values.forEach(v => {
        const oldLength = maxLengths.get(v.definitionKey) ?? 0;
        const newLength = String(v.value ?? '').length;

        if (newLength >= oldLength) maxLengths.set(v.definitionKey, newLength);
      });
    });

    // find columns to refit
    this._columnsToFit = [];
    maxLengths.forEach((newLength, key) => {
      const oldLength = this._columnContentLengths.get(key) ?? 0;
      if (
        newLength > applicationEnvironment.grid.refitColumnFactors.max * oldLength ||
        newLength < applicationEnvironment.grid.refitColumnFactors.min * oldLength
      )
        this._columnsToFit.push(key);

    });

    // update max lengths of fit columns
    this._columnsToFit.forEach(columnKey => {
      this._columnContentLengths.set(columnKey, maxLengths.get(columnKey));
    });

    this.fitColumns();
  }

  @Input()
  public set visibleColumns(value: GridColumn[]) {
    if (isNullOrUndefined(value)) return;

    this._visibleColumns = value;

    this.resetSortAndFilter();
    this.fitColumns();
  }

  public get visibleColumns(): GridColumn[] {
    return this._visibleColumns;
  }

  @Input()
  public set height(value: number) {
    this._height = value;
  }

  public get height(): number {
    return this._height;
  }

  @Input()
  public set isLoading(value: boolean) {
    this._isLoading = value;
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  @Input()
  public set resourceDefinition(value: ResourceDefinition) {
    this._resourceDefinition = value;
  }

  @Input()
  public set enableSelection(value: boolean) {
    this._selectionProvider.selectionEnabled = value;
  }

  public get showSelectionCheckbox(): boolean {
    return this._selectionProvider.selectionEnabled;
  }

  @Input()
  public set commands(value: CommandBase<any>[]) {
    this._commands = value;
  }

  public get commands(): CommandBase<any>[] {
    return this._commands;
  }

  public get showCommands(): boolean {
    return this._commands.length !== 0 && this._visibleColumns.length !== 0;
  }

  @Input()
  public set searchPhrase(value: string) {
    this._searchPhrase = value;
    this._searchPhraseSource.next(value);
  }

  @Input()
  public set rowHeight(value: number) {
    this._rowHeight = value;
  }

  public get rowHeight(): number {
    return this._rowHeight;
  }

  @Output()
  public get searchCriteriaChanged(): EventEmitter<SearchCriteria> {
    return this._searchCriteriasEventEmitter;
  }

  public get gridData(): Observable<GridDataResult> {
    return this._dataSource.asObservable();
  }

  public get sortSettings(): SortSettings {
    return this._sortProvider.sortSettings;
  }

  public get currentSort(): SortDescriptor[] {
    return this._sortProvider.currentSort;
  }

  public get currentFilter(): CustomFilterDescriptor {
    return this._filterProvider.filterDescriptor;
  }

  public get state(): { skip: number; take: number } {
    return this._virtualizationProvider.state;
  }

  public get selectionSettings(): SelectableSettings {
    return this._selectionProvider.selectionSettings;
  }

  public get noDataToShow(): boolean {
    return this._noDataToShow;
  }

  @Input()
  public set customFilters(value: CustomFilterBase<any>[]) {
    this._customFilters = new List(value);
  }

  @Output()
  public get partialValueChanged(): EventEmitter<PartialUpdateValue> {
    return this._partialValueChangedEventEmitter;
  }

  public get isSelectAll(): boolean {
    return this._selectionProvider.isSelectAll;
  }

  public set isSelectAll(value: boolean) {
    this._selectionProvider.isSelectAll = value;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    @Inject(MAPPER) mapper: Mapper,
    ngZone: NgZone,
    sortProvider: SortProvider,
    filterProvider: FilterProvider,
    virtualizationProvider: VirtualizationProvider,
    selectionProvider: SelectionProvider,
  ) {
    super();

    this._mapper = mapper;
    this._ngZone = ngZone;
    this._sortProvider = sortProvider;
    this._filterProvider = filterProvider;
    this._virtualizationProvider = virtualizationProvider;
    this._selectionProvider = selectionProvider;

    this._dataSource = new BehaviorSubject<GridDataResult>(undefined);
    this._searchCriteriasEventEmitter = new EventEmitter<SearchCriteria>();
    this._searchPhraseSource = new Subject<string>();
    this._partialValueChangedEventEmitter = new EventEmitter<PartialUpdateValue>();

    this._rowHeight = applicationEnvironment.pageing.rowHeight;
  }

  //#endregion

  //#region -- methods --

  ngOnInit(): void {
    this.addSubscriptions([
      merge(
        this._sortProvider.sortChanged,
        this._filterProvider.filterChanged,
        this._virtualizationProvider.stateChanged,
        this._searchPhraseSource,
      )
        .pipe(debounce(() => timer(100)))
        .subscribe(() => this._searchCriteriasEventEmitter.emit(this.getSearchCriteria())),
    ]);

    this._searchCriteriasEventEmitter.emit(this.getSearchCriteria());
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public onValueChanged = (value: ValueChangedInfo, item: Resource): void => {
    if (!this._selectionProvider.containsId(item.id)) this._selectionProvider.selection = [item.id];

    const updateInfo = <PartialUpdateValue>{
      definitionKey: value.definition.key,
      forAll: this._selectionProvider.isSelectAll,
      onlyForResourceIds: !this._selectionProvider.isSelectAll ? this._selectionProvider.selection : [],
      value: value.value,
    };

    this._partialValueChangedEventEmitter.emit(updateInfo);
  };

  public onCellSelectorClick = (event: MouseEvent, item: Resource): void => {
    if (!event.ctrlKey && !event.shiftKey) return;

    if (this._selectionProvider.containsId(item.id)) this._selectionProvider.remove(item.id);
    else this._selectionProvider.add(item.id);
  };

  public onSelectionChange = (event: SelectionEvent): void => {
    if (event.ctrlKey || event.shiftKey) return;

    let currentSelection = new List(this._selectionProvider.selection)
      .Except(new List(event.deselectedRows).Select(row => this.selectionKey(row)))
      .Concat(new List(event.selectedRows).Select(row => this.selectionKey(row)));

    if (this._selectionProvider.isSelectAll) {
      currentSelection = new List((<GridDataResult>this._grid.data).data)
        .Select((item: any) => item.id)
        .Except(new List(event.deselectedRows).Select(row => this.selectionKey(row)));
    }

    this._selectionProvider.selection = currentSelection.ToArray();
  };

  public isRowSelected = (row: RowArgs): boolean => this._selectionProvider.containsId(this.selectionKey(row));

  public selectionKey = (context: RowArgs): number => (<Resource>context.dataItem)?.id;

  public getModelBuilderInfo = (resource: Resource): CommandCellModelBuilderInfo =>
    <CommandCellModelBuilderInfo>{
      resource: resource,
      definition: this._resourceDefinition,
    };

  //#region -- sort --

  public canSort = (column: GridColumn): boolean => this._sortProvider.canSort(column);

  public onSortChange = (event: SortDescriptor[]): SortDescriptor[] => this._sortProvider.onSortChanged(event);

  //#endregion

  //#region -- filter --

  public canFilter = (column: GridColumn): boolean => this._filterProvider.canFilter(column);

  public resetFilter = (): void => {
    this._filterProvider.clear();
  };

  //#endregion

  //#region -- pageChange --

  public onPageChange = ($event: PageChangeEvent): void => this._virtualizationProvider.onStateChanged($event);

  //#endregion

  //#region -- private --

  private fitColumns = (): void => {
    this._fitColumnsWaitingCounter++;
    this.addSubscriptions([
      this._ngZone.onStable
        .asObservable()
        .pipe(take(1))
        .subscribe(() => this.handleAutoFit()),
    ]);
  };

  private handleAutoFit = (): void => {
    this._fitColumnsWaitingCounter--;
    if (this._visibleColumns.length === 0 || isNullOrUndefined(this._dataSource.value) || isNullOrUndefined(this._grid))
      return;

    if (this._fitColumnsWaitingCounter > 0) return;

    const columns = this._grid.visibleColumns.filter((column: ColumnComponent) =>
      this._columnsToFit.includes(column.field),
    );
    if (columns.length > 0 || this._columnsNeverFitted) {
      this._grid.autoFitColumns(this._columnsNeverFitted ? undefined : columns);
      this._columnsNeverFitted = false;
    }
  };

  private getSearchCriteria = (): SearchCriteria => {
    const criteria = <SearchCriteria>{};

    this._virtualizationProvider.setToSearchCriterias(criteria);
    this._sortProvider.setToSearchCriterias(criteria);

    const customFilter = this._customFilters.Where(filter => !(filter instanceof ParameterFilter));

    customFilter.ForEach(filter => this._filterProvider.currentFilter.setFilterWithoutNotification(filter));

    this._filterProvider.setToSearchCriterias(criteria);

    if (isNotNullOrUndefinedOrEmpty(this._searchPhrase)) criteria.phrase = this._searchPhrase;

    this._customFilters
      .Where(filter => filter instanceof ParameterFilter)
      .ForEach((filter: CustomFilterBase<any>) => (criteria[filter.key] = filter.model));

    criteria.visibledefinitions =
      this._sortProvider.isSet || this._filterProvider.isSet || isNotNullOrUndefinedOrEmpty(this._searchPhrase)
        ? new List(this._visibleColumns).Select(column => column.field).ToArray()
        : [];

    customFilter.ForEach(filter => {
      if (!criteria.visibledefinitions.includes(filter.key)) criteria.visibledefinitions.push(filter.key);
    });

    this._customFilters = this._customFilters.Except(customFilter);

    return criteria;
  };

  private resetSortAndFilter = (): void => {
    this._filterProvider.clearByVisibleColumns(this._visibleColumns);
    this._sortProvider.clearByVisibleColumns(this._visibleColumns);
  };

  //#endregion

  //#endregion
}
