<clevermailing-dialog-base [dialogRef]="dialogRef"
                           [title]="'MODULES.INTERESTED_PARTIES.PDF.TITLE'">
  <div class="pdf-viewer">
    <ngx-extended-pdf-viewer [src]="pdf"
                             [showOpenFileButton]="false"
                             [showScrollingButton]="false"
                             [showSidebarButton]="false"
                             [textLayer]="false"
                             (pdfLoaded)="onLoaded()"
                             (pageRendered)="onLoaded()"
                             (pagesLoaded)="onLoaded()"></ngx-extended-pdf-viewer>
  </div>
</clevermailing-dialog-base>
