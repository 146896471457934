import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CheckboxAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/checkboxAttributeDefinition';
import { EditorBaseComponent } from '../editor.base.component';

@Component({
  selector: 'clevermailing-checkbox-editor',
  templateUrl: './checkbox-editor.component.html',
  styleUrls: ['./checkbox-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxEditorComponent
  extends EditorBaseComponent<CheckboxAttributeDefinition, boolean, boolean> {
  //#region -- constructor --

  constructor() {
    super(
      (value: boolean) => value,
      (value: boolean) => value
    );
  }

  //#endregion
}
