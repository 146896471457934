import { convertUsing, ignore, mapFrom } from '@automapper/core';
import { GridSortDescriptor } from '../gridSortDescriptor';
import { SearchCriteria } from '../searchCriteria';
import { directionToSortingDirectionConverter } from './converter/sortDirection.converter';
import { SortingDirections } from '../enums/sortingDirections';

export const gridSortDescriptorProfile = (mapper: any) => {
  mapper.createMap(GridSortDescriptor, SearchCriteria)
    .forMember((destination: { sortBy: string }) => destination.sortBy, mapFrom(source => source.field))
    .forMember((destination: { sortDirection: SortingDirections }) => destination.sortDirection, convertUsing(directionToSortingDirectionConverter, source => source.dir))
    .forMember((destination: { skip: SortingDirections }) => destination.skip, ignore())
    .forMember((destination: { take: SortingDirections }) => destination.take, ignore())
    .forMember((destination: { phrase: SortingDirections }) => destination.phrase, ignore())
    .forMember((destination: { filters: SortingDirections }) => destination.filters, ignore())
    .forMember((destination: { visibledefinitions: SortingDirections }) => destination.visibledefinitions, ignore())
  ;
};
