import { fromValue, mapFrom } from '@automapper/core';
import { Option } from 'src/common/webapi/contracts/attributes/options/option';
import { ClientInfo } from 'src/common/webapi/contracts/clientInfo';
import { OptionTypes } from 'src/common/webapi/contracts/enum/optionTypes';

export const clientInfoProfile = (mapper: any) => {
  mapper.createMap(ClientInfo, Option)
    .forMember((destination: { value: number }) => destination.value, mapFrom(source => source.id))
    .forMember((destination: { text: string }) => destination.text, mapFrom(source => source.name))
    .forMember((destination: { type: OptionTypes }) => destination.type, fromValue(OptionTypes.Text));
};
