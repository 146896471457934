import { Injectable, OnDestroy } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiEndpointConfiguration } from 'src/common/models/apiEndpointConfiguration';
import { SearchCriteria } from 'src/common/models/searchCriteria';
import { SearchResult } from 'src/common/models/searchResult';
import { Resource } from 'src/common/webapi/contracts/resource';
import { ResourceDefinition } from 'src/common/webapi/contracts/resourceDefinition';
import { applicationEnvironment } from 'src/environments/application.environment';
import { LoopingHttpClient } from 'src/shared/http/loopingHttpClient';
import { PartialUpdateValue } from '../components/grid/models/partialUpdateValue';
import { isNotNullOrUndefined, isNullOrUndefinedOrEmpty } from '../helper/object.helper';
import { ResponsibleServiceBase } from './responsible.service.base';

@Injectable()
export class GridService
  extends ResponsibleServiceBase
  implements OnDestroy {
  //#region -- configuration --

  private static readonly PathSegmentForDefaults: string = 'defaults';

  private static readonly initialSearchCriterias: SearchCriteria = <SearchCriteria>{
    skip: 0,
    take: applicationEnvironment.pageing.virtual
  };

  //#endregion

  //#region -- fields --

  private readonly _httpClient: LoopingHttpClient;
  private readonly _definitionsSource: Subject<ResourceDefinition>;
  private readonly _attributesSource: Subject<SearchResult<Resource>>;

  private _defaultSearchCriteria: SearchCriteria;
  private _lastSearchCriteria: SearchCriteria;
  private _urlToDefaults: string;
  private _urlToItems: string;

  //#endregion

  //#region -- properties --

  public get definitions(): Observable<ResourceDefinition> {
    return this._definitionsSource.asObservable();
  }

  public get attributes(): Observable<SearchResult<Resource>> {
    return this._attributesSource.asObservable();
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    httpClient: LoopingHttpClient,
    apiEndpointConfiguration?: ApiEndpointConfiguration,
    responsibleFor?: string
  ) {
    super(responsibleFor);

    this._httpClient = httpClient;
    this._urlToDefaults = `${apiEndpointConfiguration.urlItem}/${GridService.PathSegmentForDefaults}`;
    this._urlToItems = apiEndpointConfiguration.urlItems;

    this._definitionsSource = new Subject<ResourceDefinition>();
    this._attributesSource = new Subject<SearchResult<Resource>>();

    this._defaultSearchCriteria = GridService.initialSearchCriterias;
    this._lastSearchCriteria = GridService.initialSearchCriterias;
  }

  //#endregion

  //#region -- methods ..

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public fetchDefinitions = (): void => {
    this.throwErrorWhenNoValidPathsAreProvided();

    this.callWithLoadingIndicator(
      this._httpClient
        .get<ResourceDefinition>(this._urlToDefaults),
      (result: ResourceDefinition) => this._definitionsSource.next(result)
    );
  };

  public fetch = (criteria?: SearchCriteria): void => {
    this.throwErrorWhenNoValidPathsAreProvided();

    if (isNotNullOrUndefined(criteria))
      this._lastSearchCriteria = criteria;

    this.callWithLoadingIndicator(
      this._httpClient
        .get<SearchResult<Resource>>(this._urlToItems, this._lastSearchCriteria),
      (result: SearchResult<Resource>) => this._attributesSource.next(result));
  };

  public initialize = (defaultSearchCriteria?: SearchCriteria): void => {
    if (isNotNullOrUndefined(defaultSearchCriteria)) {
      this._defaultSearchCriteria = defaultSearchCriteria;
      this._lastSearchCriteria = defaultSearchCriteria;
    }

    this.callWithLoadingIndicator(
      forkJoin(
        {
          defaults: this._httpClient.get<ResourceDefinition>(this._urlToDefaults),
          items: this._httpClient.get<SearchResult<Resource>>(this._urlToItems, this._defaultSearchCriteria)
        }),
      (result: { defaults: ResourceDefinition; items: SearchResult<Resource> }) => {
        this._definitionsSource.next(result.defaults);
        this._attributesSource.next(result.items);
      });
  };

  public partialUpdate = (partialUpdateValue: PartialUpdateValue): Observable<void> =>
    this.callWithLoadingIndicatorAndReturnResult(
      this._httpClient
        .patch(this._urlToItems, partialUpdateValue)
        .pipe(
          map(() => this.fetch(this._lastSearchCriteria))));

  private throwErrorWhenNoValidPathsAreProvided = (): void => {
    if (isNullOrUndefinedOrEmpty(this._urlToDefaults) || isNullOrUndefinedOrEmpty(this._urlToItems))
      throw new Error(`No valid configuration for GridService founnd.`);
  };

  //#endregion
}
