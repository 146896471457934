import { Converter } from '@automapper/types';
import { List } from 'linqts';
import { LinkTargets } from 'src/common/webapi/contracts/enum/linkTargets';

export const targetToTargetStringConverter: Converter<LinkTargets, string> = {
  convert: (source: LinkTargets) =>
    new List<{ target: LinkTargets; path: string }>(
      [
        { target: LinkTargets.None, path: '' },
        { target: LinkTargets.Self, path: '_self' },
        { target: LinkTargets.Blank, path: '_blank' },
      ]
    ).FirstOrDefault(x => x.target === source)?.path
};
