<div class="simple-grid">
  <clevermailing-grid #grid
                      [data]="data | async"
                      [isLoading]="isLoading"
                      [resourceDefinition]="definition"
                      [visibleColumns]="visibleGridColumns | async"
                      [height]="gridHeight | async"
                      [enableSelection]="showSelection"
                      [commands]="gridCommands"
                      [searchPhrase]="searchPhrase"
                      [customFilters]="customFilters"
                      (searchCriteriaChanged)="onSearchCriteriasChanged($event)"
                      (partialValueChanged)="onPartialValueChanged($event)"></clevermailing-grid>
</div>