import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Mapper } from '@automapper/types';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { List } from 'linqts';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridColumn } from 'src/common/models/gridColumn';
import { SearchCriteria } from 'src/common/models/searchCriteria';
import { SearchResult } from 'src/common/models/searchResult';
import { VisibleColumnInfoModel } from 'src/common/models/visibleColumnInfoModel';
import { MAPPER } from 'src/common/token/tokens';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { AttributeValue } from 'src/common/webapi/contracts/attributes/values/attributeValue';
import { Resource } from 'src/common/webapi/contracts/resource';
import { ResourceDefinition } from 'src/common/webapi/contracts/resourceDefinition';
import { applicationEnvironment } from 'src/environments/application.environment';
import { SubscriptionBase } from 'src/shared/base/subscription.base';
import { GridComponent } from 'src/shared/components/grid/grid.component';
import { GridCommand } from 'src/shared/components/grid/models/command/gridCommand';
import { CustomFilterBase } from 'src/shared/components/grid/models/filter/base/customFilter.base';
import { PartialUpdateValue } from 'src/shared/components/grid/models/partialUpdateValue';
import { SelectionProvider } from 'src/shared/components/grid/provider/selection.provider';
import { isNotNullOrUndefinedOrEmpty, isNullOrUndefined } from 'src/shared/helper/object.helper';
import { GridService } from 'src/shared/services/grid.service';

@Component({
  selector: 'clevermailing-simple-grid',
  templateUrl: './simple-grid.component.html',
  styleUrls: ['./simple-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleGridComponent
  extends SubscriptionBase
  implements OnInit, OnDestroy, AfterViewInit {
  //#region -- configuration --

  private static readonly DefaultGridHeight: number = 500;

  //#endregion

  //#region -- fields --

  private readonly _gridServices: List<GridService>;
  private readonly _mapper: Mapper;
  private readonly _dataSource: BehaviorSubject<SearchResult<Resource>>;
  private readonly _visibleColumnsSource: BehaviorSubject<GridColumn[]>;

  private _gridCommands: GridCommand[];
  private _definition: ResourceDefinition;
  private _openDialogRef: DialogRef;
  private _dependendSelectionKey: string;
  private _data: SearchResult<Resource>;
  private _searchPhrase: string;
  private _gridHeightSource: BehaviorSubject<number>;
  private _contentGroup: string;
  private _totalCountChangedEventEmitter: EventEmitter<number>;
  private _visibleColumnsChangedEventEmitter: EventEmitter<VisibleColumnInfoModel>;
  private _visibleColumnInfoModel: VisibleColumnInfoModel;
  private _customFilters: CustomFilterBase<any>[];
  private _partialValueChangedEventEmitter: EventEmitter<PartialUpdateValue>;
  private _canResetFilter: boolean;
  private _grid: GridComponent;
  private _isLoadingByLivecycle: boolean;
  private _customPartialUpdateSettings: AttributeValue[];

  //#endregion

  //#region -- properties --

  @ViewChild('grid')
  public set grid(value: GridComponent) {
    this._grid = value;
  }

  @Input()
  public set height(value: number) {
    this._gridHeightSource.next(value);
  }

  @Input()
  public set gridCommands(value: GridCommand[]) {
    this._gridCommands = value;
  }

  public get gridCommands(): GridCommand[] {
    return this._gridCommands;
  }

  @Input()
  public set searchPhrase(value: string) {
    this._searchPhrase = value;
  }

  public get searchPhrase(): string {
    return this._searchPhrase;
  }

  @Input()
  public set contentGroup(value: string) {
    this._contentGroup = value;
  }

  @Input()
  public set customFilters(value: CustomFilterBase<any>[]) {
    this._customFilters = value;
  }

  public get customFilters(): CustomFilterBase<any>[] {
    return this._customFilters;
  }

  @Input()
  public set customPartialUpdateSettings(value: any) {
    this._customPartialUpdateSettings = value;
  }

  public get gridHeight(): Observable<number> {
    return this._gridHeightSource.asObservable();
  }

  public get definition(): ResourceDefinition {
    return this._definition;
  }

  public get data(): Observable<SearchResult<Resource>> {
    return this._dataSource.asObservable();
  }

  public get pagesize(): number {
    return applicationEnvironment.pageing.virtual;
  }

  @Input()
  public set visibleColumns(value: VisibleColumnInfoModel) {
    this._visibleColumnInfoModel = value;
    this._visibleColumnsSource.next(value.visibleColumns);
  }

  public get visibleColumns(): VisibleColumnInfoModel {
    return this._visibleColumnInfoModel;
  }

  @Output()
  public get visibleColumnsChange(): EventEmitter<VisibleColumnInfoModel> {
    return this._visibleColumnsChangedEventEmitter;
  }


  public get visibleGridColumns(): Observable<GridColumn[]> {
    return this._visibleColumnsSource.asObservable();
  }

  public get isLoading(): boolean {
    return this.gridService.isLoading || this._isLoadingByLivecycle;
  }

  public get openDialogRef(): DialogRef {
    return this._openDialogRef;
  }

  public get showSelection(): boolean {
    return isNotNullOrUndefinedOrEmpty(this._dependendSelectionKey);
  }

  private get gridService(): GridService {
    return isNullOrUndefined(this._contentGroup)
      ? this._gridServices.First()
      : this._gridServices.Single(service => service.responsibleFor === this._contentGroup);
  }

  public get canResetFilter(): boolean {
    return this._canResetFilter;
  }

  @Output()
  public get totalCountChanged(): EventEmitter<number> {
    return this._totalCountChangedEventEmitter;
  }

  @Output()
  public get partialValueChanged(): EventEmitter<PartialUpdateValue> {
    return this._partialValueChangedEventEmitter;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    @Inject(GridService) gridServices: GridService[],
    @Inject(MAPPER) mapper: Mapper
  ) {
    super();

    this._gridServices = new List(gridServices);
    this._mapper = mapper;

    this._visibleColumnsSource = new BehaviorSubject<GridColumn[]>([]);
    this._gridHeightSource = new BehaviorSubject<number>(0);
    this._dataSource = new BehaviorSubject<SearchResult<Resource>>(<SearchResult<Resource>>{});
    this._gridCommands = [];
    this._totalCountChangedEventEmitter = new EventEmitter<number>();
    this._visibleColumnsChangedEventEmitter = new EventEmitter<VisibleColumnInfoModel>();
    this._partialValueChangedEventEmitter = new EventEmitter<PartialUpdateValue>();
    this._canResetFilter = false;
    this._isLoadingByLivecycle = true;
    this._customPartialUpdateSettings = [];
  }

  //#endregion

  //#region -- methods --

  ngOnInit(): void {
    if (this._gridHeightSource.value === 0)
      this._gridHeightSource.next(SimpleGridComponent.DefaultGridHeight);
  }

  ngAfterViewInit(): void {
    this.addSubscriptions(
      [
        this.gridService
          .attributes
          .subscribe(result => {
            this._data = result;

            this._dataSource.next(this._data);
            this._totalCountChangedEventEmitter.emit(this._data?.totalCount);
          }),

        this.gridService
          .definitions
          .subscribe(result => {
            this._definition = result;
            this._dependendSelectionKey = SelectionProvider.getDependendSelectionKey(result);

            const visibleColumns = GridColumn.getDefaultColumns(GridColumn
              .getOrderedDefinitions(this._definition)
              .Select(column => this._mapper.map(column, GridColumn, <any>AttributeDefinitionBase))).ToArray();

            this._visibleColumnsSource
              .next(visibleColumns);

            this._visibleColumnInfoModel = <VisibleColumnInfoModel>{
              definition: this._definition,
              visibleColumns: visibleColumns
            };

            this._visibleColumnsChangedEventEmitter
              .emit(this._visibleColumnInfoModel);
          })
      ]);

    this._isLoadingByLivecycle = false;
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public refresh = (): void =>
    this.gridService.fetch();

  public onSearchCriteriasChanged = (criteria: SearchCriteria): void => {
    this._canResetFilter = criteria?.filters?.length > 0;

    if (isNullOrUndefined(this._definition))
      this.gridService.initialize(criteria);
    else
      this.gridService.fetch(criteria);
  };

  public onPartialValueChanged = (partialUpdateValue: PartialUpdateValue): void => {
    new List(this._customPartialUpdateSettings)
      .ForEach((setting: AttributeValue) => partialUpdateValue[setting.definitionKey] = setting.value);

    this.gridService
      .partialUpdate(partialUpdateValue)
      .subscribe(() => this._partialValueChangedEventEmitter
        .emit(partialUpdateValue));
  };

  public resetFilter = (): void =>
    this._grid.resetFilter();

  //#endregion
}
