export class SearchResult<T> {
  //#region -- fields --

  public result: T[];
  public totalCount: number;

  //#endregion

  //#region -- constructor --

  constructor() {
    this.result = <T[]>[];
    this.totalCount = 0;
  }

  //#endregion
}
