import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'clevermailing-widget-base',
  templateUrl: './widget-base.component.html',
  styleUrls: ['./widget-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetBaseComponent {
  //#region -- fields --

  private _icon: string;
  private _title: string;

  //#endregion

  //#region --fields --

  @Input()
  public set icon(value: string) {
    this._icon = value;
  }

  public get icon(): string {
    return this._icon;
  }

  @Input()
  public set title(value: string) {
    this._title = value;
  }

  public get title(): string {
    return this._title;
  }

  //#endregion
}
