
export class CacheBase {
  //#region -- fields --

  private readonly _key: string;

  //#endregion

  //#region  -- properties --

  public get key(): string {
    return this._key;
  }

  //#endregion

  //#region  -- constructor --

  constructor(
    key: string
  ) {
    this._key = key;
  }

  //#endregion
}
