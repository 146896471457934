import { Injectable } from '@angular/core';
import { List } from 'linqts';
import { CacheBase } from '../models/cache/base/cache.base';

@Injectable({
  providedIn: 'root'
})
export class CacheProvider {
  //#region -- fields --

  private _caches: List<CacheBase>;

  //#endregion

  //#region  -- constructor --

  public constructor() {
    this._caches = new List<CacheBase>();
  }

  //#endregion

  //#region -- methods  --

  public get = <TCache extends CacheBase>(key: string): TCache => {
    if (!this._caches.Any(cache => cache.key === key))
      this._caches.Add(<TCache>(new CacheBase(key)));

    return <TCache>this._caches.First(cache => cache.key === key);
  };

  public clear = (): List<CacheBase> =>
    this._caches = new List<CacheBase>();

  //#endregion
}
