import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'clevermailing-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnderConstructionComponent
  implements OnInit {
  //#region -- fields --

  private readonly _activatedRoute: ActivatedRoute;

  private _routeInfo: any;

  //#endregion

  public get routeInfo(): string {
    return this._routeInfo;
  }


  //#region -- constructor --

  public constructor(
    activatedRoute: ActivatedRoute
  ) {
    this._activatedRoute = activatedRoute;
  }

  //#endregion

  //#region -- methods --

  ngOnInit() {
    this._activatedRoute.data
      .subscribe(data => this._routeInfo = data);
  }

  //#endregion
}
