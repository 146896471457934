import { convertUsing, mapFrom } from '@automapper/core';
import { LinkAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/linkAttributeDefinition';
import { LinkValue } from 'src/common/webapi/contracts/attributes/values/linkValue';
import { ItemBuilderContext } from 'src/shared/components/grid/models/itemBuilder/itemBuilderContext';
import { TextLink } from 'src/shared/components/grid/models/links/textLink';
import { targetToTargetStringConverter } from './converter/linkTargetToTargetString.converter';

export const textLinkProfile = (mapper: any) => {
  mapper.createMap(ItemBuilderContext, TextLink)
    .forMember((destination: { text: string }) => destination.text, mapFrom((source: ItemBuilderContext) => source.value<LinkValue>().name))
    .forMember((destination: { url: string }) => destination.url, mapFrom((source: ItemBuilderContext) => source.value<LinkValue>().url))
    .forMember((destination: { target: string }) => destination.target, convertUsing(targetToTargetStringConverter, (source: ItemBuilderContext) => source.definition<LinkAttributeDefinition>().target))
    ;
};
