/* eslint-disable no-bitwise */
import { Injectable, OnDestroy } from '@angular/core';
import { List } from 'linqts';
import { Observable, Subject } from 'rxjs';
import { GridColumn } from 'src/common/models/gridColumn';
import { SearchCriteria } from 'src/common/models/searchCriteria';
import { AttributeAccessibilities } from 'src/common/webapi/contracts/enum/attributeAccessibilities';
import { CustomFilterDescriptor } from 'src/shared/components/grid/models/filter/customFilterDescriptor';
import { EnumHelper } from 'src/shared/helper/enum.helper';
import { isNotNullOrUndefined } from 'src/shared/helper/object.helper';
import { GridProvider } from './base/grid.provider.base';

@Injectable()
export class FilterProvider
  extends GridProvider
  implements OnDestroy {
  //#region -- fields --

  private readonly _filterChangedSource: Subject<void>;

  private _customFilterDescriptor: CustomFilterDescriptor;

  //#endregion

  //#region -- properties --

  public get filterChanged(): Observable<any> {
    return this._filterChangedSource.asObservable();
  }

  public get currentFilter(): CustomFilterDescriptor {
    return this._customFilterDescriptor;
  }

  public get filterDescriptor(): CustomFilterDescriptor {
    return this._customFilterDescriptor;
  }

  public get isSet(): boolean {
    return this._customFilterDescriptor
      .getFilters()
      .Any();
  }

  //#endregion

  //#region -- constructor --

  public constructor() {
    super();

    this._filterChangedSource = new Subject<void>();
    this._customFilterDescriptor = new CustomFilterDescriptor();

    this.addSubscriptions(
      [
        this._customFilterDescriptor.filterChanged
          .subscribe(() => this._filterChangedSource.next())
      ]);
  }

  //#endregion

  //#region -- methods --

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public canFilter = (column: GridColumn): boolean =>
    isNotNullOrUndefined(column.definition)
    && (EnumHelper.matchFlag<AttributeAccessibilities>(column.definition.accessibility, AttributeAccessibilities.GridRangeFilterable)
      || EnumHelper.matchFlag<AttributeAccessibilities>(column.definition.accessibility, AttributeAccessibilities.GridValueFilterable)
      || EnumHelper.matchFlag<AttributeAccessibilities>(column.definition.accessibility, AttributeAccessibilities.GridValuesFilterable));

  public setToSearchCriterias = (criterias: SearchCriteria): SearchCriteria => {
    criterias.filters = this._customFilterDescriptor
      .getFilters()
      .ToArray();

    return criterias;
  };

  public clearByVisibleColumns = (visibleColumns: GridColumn[]): void => {
    const existingColumns = new List(visibleColumns)
      .Select(column => column.field);

    this._customFilterDescriptor
      .getFilters()
      .Select(filter => filter.key)
      .ForEach(key => {
        if (!existingColumns.Contains(key))
          this._customFilterDescriptor.deleteFilter(key);
      });
  };

  public clear = (): void =>
    this._customFilterDescriptor.clear();

  //#endregion
}
