import { Observable, Subject } from 'rxjs';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { AttributeValue } from 'src/common/webapi/contracts/attributes/values/attributeValue';
import { AttributeAccessibilities } from 'src/common/webapi/contracts/enum/attributeAccessibilities';
import { ArrayHelper } from 'src/shared/helper/array.helper';
import { EnumHelper } from 'src/shared/helper/enum.helper';
import { isNotNullOrUndefined, isNotNullOrUndefinedOrEmpty, isNullOrUndefined } from 'src/shared/helper/object.helper';

export class EditorAttribute {
  //#region -- fields --

  private readonly _definition: AttributeDefinitionBase;
  private readonly _attributeValue: AttributeValue;
  private readonly _itemAttributeChangedSource: Subject<AttributeDefinitionBase>;

  private _value: any;
  private _regEx: RegExp;
  private _isDirty: boolean;
  private _isValid: boolean;
  private _isReadonly: boolean;
  private _isRequired: boolean;

  //#endregion

  //#region -- properties --

  public get isDirty(): boolean {
    return this._isDirty;
  }

  public get isValid(): boolean {
    return this._isValid;
  }

  public get regex(): RegExp {
    if (isNullOrUndefined(this._regEx) && isNotNullOrUndefined(this._definition.regex))
      this._regEx = new RegExp(this._definition.regex);

    return this._regEx;
  }

  public get isReadonly(): boolean {
    if (isNullOrUndefined(this._isReadonly))
      this._isReadonly = EnumHelper.matchFlag<AttributeAccessibilities>(this._definition.accessibility, AttributeAccessibilities.EditorReadOnly);

    return this._isReadonly;
  }

  public get isRequired(): boolean {
    if (isNullOrUndefined(this._isRequired))
      this._isRequired = EnumHelper.matchFlag<AttributeAccessibilities>(this._definition.accessibility, AttributeAccessibilities.EditorRequired);

    return this._isRequired;
  }

  public get attributeChanged(): Observable<AttributeDefinitionBase> {
    return this._itemAttributeChangedSource.asObservable();
  }

  public get definition(): AttributeDefinitionBase {
    return this._definition;
  }

  public get value(): any {
    return this._value;
  }

  public set value(value: any) {
    if (this._value === value)
      return;

    this._value = value;

    this._isDirty = this.checkIfDirty();
    this._isValid = this.checkIfValid();

    this._itemAttributeChangedSource.next(this._definition);
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    definition: AttributeDefinitionBase,
    attributeValue: AttributeValue,
    itemAttributeChangedSource: Subject<AttributeDefinitionBase>
  ) {
    this._definition = definition;
    this._attributeValue = attributeValue;
    this._itemAttributeChangedSource = itemAttributeChangedSource;

    this._value = attributeValue.value;

    this._isDirty = this.checkIfDirty();
    this._isValid = this.checkIfValid();
  }

  //#endregion

  //#region -- methods --

  private checkIfDirty = (): boolean => {
    if (Array.isArray(this._attributeValue))
      return !ArrayHelper.arrayCompare(this._attributeValue.value, this._value);

    if (isNullOrUndefined(this._attributeValue.value))
      return isNotNullOrUndefined(this._value);

    return this._attributeValue.value !== this._value;
  };

  private checkIfValid = (): boolean =>
    (!this.isRequired
      || (isNotNullOrUndefined(this._value) && isNotNullOrUndefinedOrEmpty(this._value)))
    && (isNullOrUndefined(this.regex)
      ? true
      : this.regex.test(this._value));

  //#endregion
}
