<div>
  <div class="icon">
    <span [ngClass]="model?.icon"
          [ngStyle]="{ 'color': model?.hexColor }">
    </span>
  </div>

  <div class="text">
    {{ model?.text }}
  </div>
</div>