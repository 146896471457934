import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconOption } from '../../models/options/iconOption';
import { ItemBaseComponent } from '../item.base.component';

@Component({
  selector: 'clevermailing-icon-option-item',
  templateUrl: './icon-option-item.component.html',
  styleUrls: ['./icon-option-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconOptionItemComponent
  extends ItemBaseComponent<IconOption>{ }
