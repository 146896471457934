import { mapFrom } from '@automapper/core';
import { IconOption as ApiIconOption } from 'src/common/webapi/contracts/attributes/options/iconOption';
import { OptionBuilderContext } from 'src/shared/components/grid/models/itemBuilder/optionBuilderContext';
import { IconOption } from 'src/shared/components/grid/models/options/iconOption';

export const iconOptionProfile = (mapper: any) => {
  mapper.createMap(OptionBuilderContext, IconOption)
    .forMember((destination: { value: number }) => destination.value, mapFrom((source: OptionBuilderContext) => source.option<ApiIconOption>()?.value))
    .forMember((destination: { isDisabled: boolean }) => destination.isDisabled, mapFrom((source: OptionBuilderContext) => source.option<ApiIconOption>()?.isDisabled))
    .forMember((destination: { icon: string }) => destination.icon, mapFrom((source: OptionBuilderContext) => source.option<ApiIconOption>()?.icon))
    .forMember((destination: { hexColor: string }) => destination.hexColor, mapFrom((source: OptionBuilderContext) => source.option<ApiIconOption>()?.hexColor))
    .forMember((destination: { tooltip: string }) => destination.tooltip, mapFrom((source: OptionBuilderContext) => source.option<ApiIconOption>()?.tooltip));
};
