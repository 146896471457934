import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ACCESS_TOKEN } from 'src/common/token/tokens';
import { Authorization } from 'src/common/webapi/contracts/authorization';
import { isNotNullOrUndefined } from '../helper/object.helper';

@Injectable()
export class LoginInterceptor
  implements HttpInterceptor {
  //#region -- configuration --

  private static readonly HeaderName: string = 'Authorization';

  //#endregion

  //#region -- fields --

  private readonly _accessToken: BehaviorSubject<Authorization>;

  //#endregion

  //#region -- constructor  --

  public constructor(
    injector: Injector
  ) {
    this._accessToken = injector.get(ACCESS_TOKEN);
  }

  //#endregion

  //#region -- methods --

  public intercept = (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> => {
    if (isNotNullOrUndefined(this._accessToken.value))
      req = req.clone({ headers: req.headers.set(LoginInterceptor.HeaderName, Authorization.getHeaderValue(this._accessToken.value)) });

    return next.handle(req);
  };

  //#endregion
}
