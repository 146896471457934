/* eslint-disable @typescript-eslint/quotes */
import { AttributeDefinitionBase } from "./bases/attributeDefinitionBase";

export class AttributeGroup {
    //#region --fields --

    public name: string;
    public order: number;
    public attributes: AttributeDefinitionBase[];

    //#endregion

    //#region -- constructor --

    public constructor() {
        this.attributes = [];
    }

    //#endregion
}
