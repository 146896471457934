import { List } from 'linqts';
import { isNotNullOrUndefined } from 'src/shared/helper/object.helper';
import { CustomFilterBase } from './base/customFilter.base';

export class NumericValueFilter
  extends CustomFilterBase<number> {
  // #region -- constructor --

  public constructor(
    key: string,
    regex: string
  ) {
    super(key, regex, undefined);
  }

  //#endregion

  //#region -- methods  --

  public isValid = (): boolean =>
    isNotNullOrUndefined(this.model)
    && this.model >= 0
    && this.validateRegex();

      public toParams = (id: number): List<{ key: string; value: string }> =>
        new List<{ key: string; value: string }>(
          [
            { key: `filters${id}.by`, value: this.key },
            { key: `filters${id}.value`, value: this.model.toString() }
          ]);

  private validateRegex = (): boolean =>
    isNotNullOrUndefined(this.regex)
      ? RegExp(this.regex).test(this.model.toString())
      : true;

  //#endregion
}
