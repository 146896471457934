import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconTextOption } from '../../models/options/iconTextOption';
import { ItemBaseComponent } from '../item.base.component';

@Component({
  selector: 'clevermailing-icon-text-option-item',
  templateUrl: './icon-text-option-item.component.html',
  styleUrls: ['./icon-text-option-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconTextOptionItemComponent
  extends ItemBaseComponent<IconTextOption>{ }
