import { mapFrom } from '@automapper/core';
import { IconTextOption as ApiIconTextOption } from 'src/common/webapi/contracts/attributes/options/iconTextOption';
import { OptionBuilderContext } from 'src/shared/components/grid/models/itemBuilder/optionBuilderContext';
import { IconTextOption } from 'src/shared/components/grid/models/options/iconTextOption';

export const iconTextOptionProfile = (mapper: any) => {
  mapper.createMap(OptionBuilderContext, IconTextOption)
    .forMember((destination: { value: number }) => destination.value, mapFrom((source: OptionBuilderContext) => source.option<ApiIconTextOption>()?.value))
    .forMember((destination: { isDisabled: boolean }) => destination.isDisabled, mapFrom((source: OptionBuilderContext) => source.option<ApiIconTextOption>()?.isDisabled))
    .forMember((destination: { text: string }) => destination.text, mapFrom((source: OptionBuilderContext) => source.option<ApiIconTextOption>()?.text))
    .forMember((destination: { icon: string }) => destination.icon, mapFrom((source: OptionBuilderContext) => source.option<ApiIconTextOption>()?.icon))
    .forMember((destination: { hexColor: string }) => destination.hexColor, mapFrom((source: OptionBuilderContext) => source.option<ApiIconTextOption>()?.hexColor));
};
