import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { isNotNullOrUndefined } from 'src/shared/helper/object.helper';
import { ViewCommand } from '../../models/command/viewCommand';
import { AttributeDefitiontionToFilterConverter } from '../../models/enums/converter/attributeDefinitionToFilterType.converter';
import { FilterTypes } from '../../models/enums/filterTypes';
import { CustomFilterBase } from '../../models/filter/base/customFilter.base';
import { CustomFilterDescriptor } from '../../models/filter/customFilterDescriptor';
import { FilterFactory } from '../../models/filter/filterFactory';

@Component({
  selector: 'clevermailing-filter-selector',
  templateUrl: './filter-selector.component.html',
  styleUrls: ['./filter-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterSelectorComponent
  implements AfterViewInit {
  //#region -- export enum --

  filterTypes = FilterTypes;

  //#endregion

  //#region -- commands --

  private readonly _commands: ViewCommand<any>[] =
    [
      new ViewCommand('GRID.FILTER.COMMANDS.ACCEPT', 'fa-solid fa-check', '', () => this._itemFilter?.isValid(), () => this.setFilter()),
      new ViewCommand('GRID.FILTER.COMMANDS.DELETE', 'fa-solid fa-undo-alt', '', () => this.canDelete(), () => this.deleteFilter()),
      new ViewCommand('GRID.FILTER.COMMANDS.CLOSE', 'fa-solid fa-times', '', () => true, () => this.closeFilter()),
    ];

  //#endregion

  //#region -- fields --

  private readonly _filterFactory: FilterFactory;

  private _definition: AttributeDefinitionBase;
  private _customfilter: CustomFilterDescriptor;
  private _itemFilter: CustomFilterBase<any>;
  private _filterType: FilterTypes;
  private _changeDetectionRef: ChangeDetectorRef;

  //#endregion

  //#region -- properties --

  @Input()
  public set filter(value: CustomFilterDescriptor) {
    this._customfilter = value;
  };

  @Input()
  public set definition(value: AttributeDefinitionBase) {
    this._definition = value;
  }

  public get definition(): AttributeDefinitionBase {
    return this._definition;
  }

  public get filterType(): FilterTypes {
    return this._filterType;
  }

  public get title(): string {
    return this._definition?.name;
  }

  public get itemFilter(): CustomFilterBase<any> {
    return this._itemFilter;
  }

  public get commands(): ViewCommand<any>[] {
    return this._commands;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    filterFactory: FilterFactory,
    changeDetectionRef: ChangeDetectorRef
  ) {
    this._filterFactory = filterFactory;
    this._changeDetectionRef = changeDetectionRef;
  }

  //#endregion

  //#region -- methods --

  public ngAfterViewInit(): void {
    this._filterType = AttributeDefitiontionToFilterConverter.convert(this.definition);
    this._itemFilter = this._filterType !== this.filterTypes.None
      ? this.getItemFilter()
      : undefined;

    this._changeDetectionRef.detectChanges();
  }

  private getItemFilter = (): CustomFilterBase<any> => {
    const filter = this._customfilter.getFilter(this.definition.key);

    return isNotNullOrUndefined(filter)
      ? filter
      : this._filterFactory.create(this.definition, this.filterType);
  };

  private setFilter = (): void => {
    this._customfilter.setFilter(this._itemFilter);
    this.closeFilterMenu();
  };

  private canDelete = (): boolean =>
    isNotNullOrUndefined(this._customfilter.getFilter(this.definition.key));

  private deleteFilter = (): void => {
    this._customfilter.deleteFilter(this._itemFilter.key);
    this.closeFilterMenu();
  };

  private closeFilter = (): void =>
    this.closeFilterMenu();

  private closeFilterMenu = (): void =>
    (<any>document.querySelector('kendo-grid-filter-menu-container > form > div > div.k-actions > button[type=reset]'))?.click();

  //#endregion
}
