import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { SubscriptionBase } from 'src/shared/base/subscription.base';
import { isNullOrUndefined } from 'src/shared/helper/object.helper';
import { CacheProvider } from '../grid/provider/cache.provider';
import { EditorGroup } from './models/editorGroup';
import { EditorItem } from './models/editorItem';

@Component({
  selector: 'clevermailing-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorComponent
  extends SubscriptionBase
  implements OnDestroy {
  //#region -- configuration --

  private static MasonryOptions: NgxMasonryOptions = <NgxMasonryOptions>{
    animations: {},
    columnWidth: 660,
    fitWidth: true
  };

  //#endregion

  //#region -- fields --

  private _item: EditorItem;
  private _masonry: NgxMasonryComponent;
  private _cacheProvider: CacheProvider;

  //#endregion

  //#region -- properties --

  @ViewChild('masonry')
  public set masonry(value: NgxMasonryComponent) {
    this._masonry = value;
  }

  @Input()
  public set item(value: EditorItem) {
    if (isNullOrUndefined(value))
      return;

    this._item = value;

    this._masonry?.layout();
    this._cacheProvider.clear();
  }

  public get item(): EditorItem {
    return this._item;
  }

  public get id(): number {
    return this._item?.id;
  }

  public get groups(): EditorGroup[] {
    return this._item?.groups;
  }

  public get masonryOptions(): NgxMasonryOptions {
    return EditorComponent.MasonryOptions;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    cacheProvider: CacheProvider
  ) {
    super();

    this._cacheProvider = cacheProvider;
  }

  //#endregion

  //#region -- methods --

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  //#endregion
}
