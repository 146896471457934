import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { DecimalNumberAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/decimalNumberAttributeDefinition';
import { NumberAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/numberAttributeDefinition';
import { isNotNullOrUndefined } from 'src/shared/helper/object.helper';
import { NumericRangeFilter } from '../../models/filter/numbericRangeFilter';
import { FilterBaseComponent } from '../filter.base.component';

@Component({
  selector: 'clevermailing-numeric-range-filter',
  templateUrl: './numeric-range-filter.component.html',
  styleUrls: ['./numeric-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumericRangeFilterComponent
  extends FilterBaseComponent<NumberAttributeDefinition, NumericRangeFilter, { start: number; end: number }>{
  //#region -- properties --

  public get format(): NumberFormatOptions {
    const fractionDigits = isNotNullOrUndefined((<DecimalNumberAttributeDefinition>this.definition)?.precision)
      ? (<DecimalNumberAttributeDefinition>this.definition)?.precision?.fractional
      : 0;

    return <NumberFormatOptions>{
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits
    };
  }

  public get max(): number {
    return Number.MAX_SAFE_INTEGER;
  }

  public get min(): number {
    return Number.MIN_SAFE_INTEGER;
  }

  //#endregion
}
