import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { classes } from '@automapper/classes';
import { createMapper } from '@automapper/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BehaviorSubject } from 'rxjs';
import { clientInfoProfile } from 'src/common/models/profiles/clientInfo.profile';
import { clientUserProfile } from 'src/common/models/profiles/clientUser.profile';
import { editorItemGroupProfile } from 'src/common/models/profiles/editorItemGroup.profile';
import { gridColumnProfile } from 'src/common/models/profiles/gridColumn.profile';
import { gridSortDescriptorProfile } from 'src/common/models/profiles/gridSortDescriptor.profile';
import { iconLinkProfile } from 'src/common/models/profiles/iconLink.profile';
import { iconOptionProfile } from 'src/common/models/profiles/iconOption.profile';
import { iconTextOptionProfile } from 'src/common/models/profiles/iconTextOption.profile';
import { resourceGridDataResultProfile } from 'src/common/models/profiles/resourceDataGrid.profile';
import { textLinkProfile } from 'src/common/models/profiles/textLink.profile';
import { textOptionProfile } from 'src/common/models/profiles/textOption.profile';
import { ACCESS_TOKEN, MAPPER } from 'src/common/token/tokens';
import { ComponentsModule } from 'src/components/components.module';
import { UserSettingsItemService } from 'src/components/user-settings/services/user-settings.item.service';
import { applicationEnvironment } from 'src/environments/application.environment';
import { FilterFactory } from 'src/shared/components/grid/models/filter/filterFactory';
import { ItemBuilderFactory } from 'src/shared/components/grid/models/itemBuilder/itemBuilderFactory';
import { LoopingHttpClient } from 'src/shared/http/loopingHttpClient';
import { LoginInterceptor } from 'src/shared/interceptors/login.interceptor';
import { ItemService } from 'src/shared/services/item.service';
import { SharedModule } from 'src/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations:
    [
      AppComponent
    ],
  imports:
    [
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      HttpClientModule,

      SharedModule.forRoot(),
      ComponentsModule,

      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) =>
            new TranslateHttpLoader(http, applicationEnvironment.translation.pathToFiles, applicationEnvironment.translation.fileExtension),
          deps:
            [
              HttpClient
            ]
        },
        defaultLanguage: applicationEnvironment.translation.defaultLanguage
      })
    ],
  providers:
    [
      {
        provide: MAPPER,
        useFactory: () => createMapper({
          name: 'mapper',
          pluginInitializer: classes
        })
          .addProfile(gridColumnProfile)
          .addProfile(resourceGridDataResultProfile)
          .addProfile(textLinkProfile)
          .addProfile(iconLinkProfile)
          .addProfile(textOptionProfile)
          .addProfile(iconOptionProfile)
          .addProfile(iconTextOptionProfile)
          .addProfile(gridSortDescriptorProfile)
          .addProfile(editorItemGroupProfile)
          .addProfile(clientUserProfile)
          .addProfile(clientInfoProfile)
      },
      {
        provide: ItemBuilderFactory,
        useClass: ItemBuilderFactory
      },
      {
        provide: FilterFactory,
        useClass: FilterFactory
      },
      {
        provide: LOCALE_ID,
        useValue: applicationEnvironment.translation.localeId
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoginInterceptor,
        multi: true
      },
      {
        provide: ACCESS_TOKEN,
        useFactory: () => new BehaviorSubject<string>(undefined)
      },
      {
        provide: ItemService,
        useFactory: (httpClient: LoopingHttpClient) =>
          new UserSettingsItemService(
            httpClient,
            applicationEnvironment.api.endpoints.userSettings,
            applicationEnvironment.contentGroups.userSettings),
        deps:
          [
            LoopingHttpClient
          ],
        multi: true
      },
    ],
  bootstrap:
    [
      AppComponent
    ]
})
export class AppModule { }
