import { List } from 'linqts';
import { Subscription } from 'rxjs';

export abstract class SubscriptionBase {
  //#region -- fields --

  private _subscriptions: List<Subscription>;

  //#endregion

  //#region -- constructor --

  protected constructor() {
    this._subscriptions = new List<Subscription>();
  }

  //#endregion

  //#region -- methods --

  protected addSubscription = (subscription: Subscription): void =>
    this._subscriptions
      .Add(subscription);

  protected addSubscriptions = (subscriptions: Subscription[]): void =>
    new List(subscriptions)
      .ForEach((subscription: Subscription) => this.addSubscription(subscription));

  protected clearSubscriptions = (): void => {
    this._subscriptions
      .ForEach((subscription: Subscription) => subscription?.unsubscribe());

    this._subscriptions = new List<Subscription>();
  };

  //#endregion
}
