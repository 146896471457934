import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard
  implements CanActivate {
  //#region -- configuration --

  private static readonly PathForLogin: string = '/login';

  //#endregion

  //#region -- fields --

  private readonly _userService: UserService;
  private readonly _router: Router;

  //#endregion

  //#region -- constructor --

  public constructor(
    router: Router,
    userService: UserService
  ) {
    this._router = router;
    this._userService = userService;

    this._userService
      .userInfoChanged
      .subscribe(() => {
        if (!this._userService.isLoggedIn)
          this._router.navigate([LoggedInGuard.PathForLogin]);
      });
  }

  //#endregion

  //#region -- methods --

  public canActivate = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree =>
    this._userService.isLoggedIn || this._router.parseUrl(LoggedInGuard.PathForLogin);

  //#endregion
}
