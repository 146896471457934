<div class="editor">
  <ngx-masonry #masonry
               [options]="masonryOptions">
    <div ngxMasonryItem
         class="group-item"
         *ngFor="let group of groups">
      <clevermailing-group [group]="group"
                           [item]="item"></clevermailing-group>
    </div>
  </ngx-masonry>
</div>
