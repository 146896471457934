import { ChangeDetectionStrategy, Component } from '@angular/core';
import { applicationEnvironment } from 'src/environments/application.environment';

@Component({
  selector: 'clevermailing-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  //#region -- methods --

  public get applicationName(): string {
    return applicationEnvironment.name;
  }
  public get applicationVersion(): string {
    return applicationEnvironment.version;
  }

  //#endregion
}
