/* eslint-disable @typescript-eslint/member-ordering */
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ACCESS_TOKEN } from 'src/common/token/tokens';
import { Authorization } from 'src/common/webapi/contracts/authorization';
import { LoopingHttpClient } from 'src/shared/http/loopingHttpClient';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  //#region -- configuration --

  private static readonly urlToLogin: string = `authentication/login`;
  private static readonly urlToLogout: string = `authentication/logout`;

  //#endregion

  //#region -- fields --

  private readonly _httpClient: LoopingHttpClient;
  private readonly _accessToken: BehaviorSubject<Authorization>;

  //#endregion

  //#region -- constructor --

  public constructor(
    httpClient: LoopingHttpClient,
    @Inject(ACCESS_TOKEN) accessToken: BehaviorSubject<Authorization>,
  ) {
    this._httpClient = httpClient;
    this._accessToken = accessToken;
  }

  //#endregion

  //#region -- methods --

  public login = (username: string, password: string): Observable<boolean> =>
    this._httpClient
      .create(AuthenticationService.urlToLogin, { username: username, password: password })
      .pipe(
        map((result: Authorization) => {
          this._accessToken.next(result);

          return true;
        }));

  public logout = (): Subscription =>
    this._httpClient
      .get(AuthenticationService.urlToLogout)
      .subscribe(() => { });

  //#endregion
}
