import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { applicationEnvironment } from 'src/environments/application.environment';
import { SubscriptionBase } from 'src/shared/base/subscription.base';
import { isNullOrUndefined } from 'src/shared/helper/object.helper';
import { EditorAttribute } from '../models/editorAttribute';

@Component({
  selector: 'clevermailing-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelComponent
  extends SubscriptionBase
  implements OnDestroy {
  //#region -- fields --

  private readonly _isValidSource: BehaviorSubject<boolean>;

  private _editorAttribute: EditorAttribute;

  //#endregion

  //#region -- properties --

  @Input()
  public set editorAttribute(value: EditorAttribute) {
    if (isNullOrUndefined(value))
      return;

    this._editorAttribute = value;
    this._isValidSource.next(this._editorAttribute.isValid);

    this.addSubscriptions(
      [
        this._editorAttribute
          .attributeChanged
          .subscribe(definition => {
            if (definition.key === this._editorAttribute.definition.key)
              this._isValidSource.next(this._editorAttribute.isValid);
          })
      ]);
  }

  public get label(): string {
    return this._editorAttribute?.definition?.name ?? '';
  }

  public get requiredMaker(): string {
    return this._editorAttribute?.isRequired
      ? applicationEnvironment.defaults.marker.forRequired
      : '';
  }

  public get isValidSource(): Observable<boolean> {
    return this._isValidSource.asObservable();
  }

  //#endregion

  //#region -- constructor --

  public constructor() {
    super();

    this._isValidSource = new BehaviorSubject<boolean>(false);
  }

  //#endregion

  //#region -- methods --

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  //#endregion
}
