import { List } from 'linqts';
import { CustomFilterBase } from './base/customFilter.base';

export class ValuesFilter
  extends CustomFilterBase<number[]> {
  // #region -- constructor --

  public constructor(
    key: string,
    regex: string
  ) {
    super(key, regex, <number[]>[]);
  }

  //#endregion

  //#region -- methods  --

  public isValid = (): boolean =>
    this.model.length > 0;

  public toParams = (id: number): List<{ key: string; value: string }> => {
    const filters = new List<{ key: string; value: string }>(
      [
        { key: `filters${id}.by`, value: this.key }
      ]);
    new List(this.model)
      .Select(item => filters.Add({ key: `filters${id}.values`, value: `${item}` }));

    return filters;
  };

  //#endregion
}
