import { Injectable, OnDestroy } from '@angular/core';
import { List } from 'linqts';
import { Observable, Subject } from 'rxjs';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { AttributeValue } from 'src/common/webapi/contracts/attributes/values/attributeValue';
import { AttributeAccessibilities } from 'src/common/webapi/contracts/enum/attributeAccessibilities';
import { AttributeTypes } from 'src/common/webapi/contracts/enum/attributeTypes';
import { Resource } from 'src/common/webapi/contracts/resource';
import { ResourceDefinition } from 'src/common/webapi/contracts/resourceDefinition';
import { applicationEnvironment } from 'src/environments/application.environment';
import { SubscriptionBase } from 'src/shared/base/subscription.base';
import { EnumHelper } from 'src/shared/helper/enum.helper';
import { isNotNullOrUndefined } from 'src/shared/helper/object.helper';
import { EditorAttribute } from './editorAttribute';
import { EditorGroup } from './editorGroup';

@Injectable()
export class EditorItem
  extends SubscriptionBase
  implements OnDestroy {
  //#region -- configuration --

  private static readonly attributeAccessibilitiesNeeded: List<{ isNew: boolean; accessibility: AttributeAccessibilities }>
    = new List<{ isNew: boolean; accessibility: AttributeAccessibilities }>(
      [
        { isNew: true, accessibility: AttributeAccessibilities.EditorCreate },
        { isNew: false, accessibility: AttributeAccessibilities.EditorModify }
      ]);

  private static readonly attributeTypesToIgnore: List<AttributeTypes>
    = new List<AttributeTypes>(
      [
        AttributeTypes.Link,
        AttributeTypes.Relation,
        AttributeTypes.RelationAttribute
      ]);

  //#endregion

  //#region -- fields --

  private readonly _definition: ResourceDefinition;
  private readonly _resource: Resource;
  private readonly _itemAttributeChangedSource: Subject<AttributeDefinitionBase>;

  private _groups: List<EditorGroup>;
  private _isDirty: boolean;
  private _canSave: boolean;

  //#endregion

  //#region -- properties --

  public get id(): number {
    return this._resource?.id;
  }

  public get groups(): EditorGroup[] {
    return this._groups.ToArray();
  }

  public get isDirty(): boolean {
    return this._isDirty;
  }

  public get canSave(): boolean {
    return this._canSave;
  }

  public get isNew(): boolean {
    return this.id === applicationEnvironment.defaults.identifier.forNewItemId;
  }

  public get onAttributeChanged(): Observable<AttributeDefinitionBase> {
    return this._itemAttributeChangedSource;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    definition: ResourceDefinition,
    resource: Resource,
  ) {
    super();

    this._definition = definition;
    this._resource = resource;

    this._itemAttributeChangedSource = new Subject<AttributeDefinitionBase>();

    this.addSubscriptions(
      [
        this._itemAttributeChangedSource
          .subscribe(() => {
            this._isDirty = this._groups.Any(group => group.isDirty);
            this._canSave = this._groups.All(group => group.editorAttributes.All(attribute => attribute.isValid));
          })
      ]);

    this.initialize();
  }

  //#endregion

  //#region -- methods --
  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public getEditorAttribute = (definition: AttributeDefinitionBase): EditorAttribute =>
    this._groups.SelectMany(groups => groups.editorAttributes)
      .FirstOrDefault(value => value.definition.key === definition.key);

  public getRessource = (): Resource => {
    const allAllowedDefinitions = this.getAllowedAttributes()
      .Select(definition => definition.key);

    const resource = this.isNew
      ? <Resource>{}
      : <Resource>{
        id: this.id
      };
    resource.type = this._definition.type;
    resource.values = this._groups
      .SelectMany(group => group.editorAttributes)
      .Where(attribute => allAllowedDefinitions.Contains(attribute.definition.key)
        && isNotNullOrUndefined(attribute.value)
        && !EnumHelper.matchFlag<AttributeAccessibilities>(attribute.definition.accessibility, AttributeAccessibilities.EditorReadOnly))
      .Select(attribute => <AttributeValue>{
        definitionKey: attribute.definition.key,
        value: attribute.value
      })
      .ToArray();

    return resource;
  };

  private initialize = (): void => {
    const allAllowedDefinitions = this.getAllowedAttributes();
    const values = new List(this._resource.values);

    this._groups = new List(this._definition.groups)
      .Where(group => allAllowedDefinitions
        .Any(allowedDef => group.attributes.includes(allowedDef)))
      .Select(group => <EditorGroup>{
        name: group.name,
        order: group.order,
        definitions: new List(group.attributes)
          .Where(attribute => allAllowedDefinitions
            .Contains(attribute)),
        editorAttributes: new List<EditorAttribute>()
      })
      .OrderBy(group => group.order);

    this._groups
      .ForEach(group => group.definitions
        .ForEach(attributeDefinition => {
          const editorItemValue = values
            .FirstOrDefault(value => value.definitionKey === attributeDefinition.key)
            ?? <AttributeValue>{
              definitionKey: attributeDefinition.key,
              value: undefined
            };
          group.editorAttributes.Add(new EditorAttribute(attributeDefinition, editorItemValue, this._itemAttributeChangedSource));
        }));
  };

  private getAllowedAttributes = (): List<AttributeDefinitionBase> =>
    new List(this._definition.groups)
      .SelectMany(groups => new List(groups.attributes))
      .Where(definition => this.isAllowdByRestriction(definition));

  private isAllowdByRestriction = (definition: AttributeDefinitionBase): boolean =>
    EditorItem.attributeAccessibilitiesNeeded
      .Where(accessibility => accessibility.isNew === this.isNew)
      .Any(accessibility => EnumHelper.matchFlag<AttributeAccessibilities>(definition.accessibility, accessibility.accessibility)
        || EnumHelper.matchFlag<AttributeAccessibilities>(definition.accessibility, AttributeAccessibilities.GridInlineEditing))
    && !EditorItem.attributeTypesToIgnore.Contains(definition.type);

  //#endregion
}
