import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CheckboxAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/checkboxAttributeDefinition';
import { CheckboxFilter } from '../../models/filter/checkboxFilter';
import { FilterBaseComponent } from '../filter.base.component';

@Component({
  selector: 'clevermailing-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: ['./checkbox-filter.component.scss']
})
export class CheckboxFilterComponent
  extends FilterBaseComponent<CheckboxAttributeDefinition, CheckboxFilter, boolean[]> {
  //#endregion -- fields --

  private _items: { value: boolean; text: string }[];

  //#endregion

  //#region -- properties --

  public get items(): { value: boolean; text: string }[] {
    return this._items;
  }

  //#endregion

  //#region -- constructor--

  public constructor(
    translateService: TranslateService
  ) {
    super();

    this._items = [
      { value: false, text: translateService.instant('GRID.FILTER.CHECKBOX.FALSE') },
      { value: true, text: translateService.instant('GRID.FILTER.CHECKBOX.TRUE') }
    ];
  }

  //#endregion
}
