import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { List } from 'linqts';
import { Observable, Subject } from 'rxjs';
import { isNotNullOrUndefined } from 'src/shared/helper/object.helper';
import { CustomFilterBase } from './base/customFilter.base';

export class CustomFilterDescriptor
  implements CompositeFilterDescriptor {
  //#region -- fields --

  private _customFilters: List<CustomFilterBase<any>>;
  private _filterChangedSource: Subject<List<CustomFilterBase<any>>>;

  //#endregion

  //#region  -- properties --

  public get logic(): 'or' | 'and' {
    return 'and';
  }

  public get filters(): (CompositeFilterDescriptor | FilterDescriptor)[] {
    return [
      <CompositeFilterDescriptor>{
        filters: this._customFilters
          .Select(filter => <FilterDescriptor>{
            field: filter.key
          })
          .ToArray()
      }
    ];
  }

  public get filterChanged(): Observable<List<CustomFilterBase<any>>> {
    return this._filterChangedSource.asObservable();
  }

  //#endregion

  //#region -- constructor --

  public constructor() {
    this._filterChangedSource = new Subject<List<CustomFilterBase<any>>>();
    this._customFilters = new List<CustomFilterBase<any>>();
  }

  //#endregion

  //#region -- methods  --

  public getFilter = <TFilter extends CustomFilterBase<any>>(key: string): TFilter =>
    <TFilter>this._customFilters
      .FirstOrDefault(filter => filter.key === key);

  public getFilters = (): List<CustomFilterBase<any>> =>
    this._customFilters;

  public setFilter = <TFilter extends CustomFilterBase<any>>(filter: TFilter): void => {
    this.setFilterWithoutNotification(filter);

    this._filterChangedSource.next(this._customFilters);
  };

  public setFilterWithoutNotification = <TFilter extends CustomFilterBase<any>>(filter: TFilter): void => {
    const existingFilter = this.getFilter(filter.key);

    if (isNotNullOrUndefined(existingFilter))
      this._customFilters.Remove(existingFilter);

    this._customFilters.Add(filter);
  };

  public deleteFilter = (key: string): void => {
    const existingFilter = this.getFilter(key);

    if (isNotNullOrUndefined(existingFilter)) {
      this._customFilters.Remove(this.getFilter(key));

      this._filterChangedSource.next(this._customFilters);
    }
  };

  public clear = (): void =>
    this._customFilters.Clear();

  //#endregion
}

