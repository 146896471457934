import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'clevermailing-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyInfoComponent {
  //#region -- fields --

  private _header: string;
  private _info: string;
  private _logoPath: string;

  //#endregion

  //#region -- properties --

  @Input()
  public set logoPath(value: string) {
    this._logoPath = value;
  }

  public get logoPath(): string {
    return this._logoPath;
  }

  @Input()
  public set header(value: string) {
    this._header = value;
  }

  public get header(): string {
    return this._header;
  }

  @Input()
  public set info(value: string) {
    this._info = value;
  }

  public get info(): string {
    return this._info;
  }

  //#endregion
}
