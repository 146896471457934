import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { List } from 'linqts';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { applicationEnvironment } from 'src/environments/application.environment';
import { SubscriptionBase } from 'src/shared/base/subscription.base';
import { isNotNullOrUndefinedOrEmpty, isNullOrUndefined, isNullOrUndefinedOrEmpty } from 'src/shared/helper/object.helper';
import { ViewCommand } from '../grid/models/command/viewCommand';

@Component({
  selector: 'clevermailing-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent
  extends SubscriptionBase
  implements OnInit, OnDestroy {
  //#region -- configuration--

  private static readonly TriggerKeys: List<string>
    = new List<string>(
      [
        'ENTER'
      ]);

  private readonly _commands: ViewCommand<any>[] =
    [
      new ViewCommand('SEARCH.COMMANDS.SEARCH', 'fa fa-search', '', () => this.canTriggerSearch(), () => this.triggerSearch()),
    ];

  //#endregion

  //#region -- fields --

  private readonly _searchPhraseEmitter: EventEmitter<string>;
  private readonly _debounceSubject: Subject<string>;

  private _model: string;
  private _lastSearch: string;

  //#endregion

  //#region -- properties --

  public get model(): string {
    return this._model;
  }

  public set model(value: string) {
    if (isNullOrUndefinedOrEmpty(value) && isNotNullOrUndefinedOrEmpty(this._model))
      this.handleClearButton();

    this._model = value;
  }

  @Input()
  public set searchPhrase(value: string) {
    if (isNullOrUndefined(value) || value === this._model)
      return;

    this._model = value;
  }

  @Output()
  public get searchPhraseChange(): EventEmitter<string> {
    return this._searchPhraseEmitter;
  }

  public get commands(): ViewCommand<any>[] {
    return this._commands;
  }

  //#endregion

  //#region -- constructor --

  public constructor() {
    super();

    this._searchPhraseEmitter = new EventEmitter<string>();
    this._debounceSubject = new Subject<string>();

    this._model = '';
  }

  //#endregion

  //#region -- methods --

  ngOnInit(): void {
    this.attachDebounce();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public onKeyDown = ($event: KeyboardEvent): void => {
    if (GlobalSearchComponent.TriggerKeys.Contains($event.key.toUpperCase()) && this.canTriggerSearch())
      this._debounceSubject.next(this._model);
  };

  public canTriggerSearch = (): boolean =>
    this._model.length >= applicationEnvironment.search.minSearchLength && this._model !== this._lastSearch;

  public triggerSearch = (): void =>
    this._debounceSubject.next(this._model);

  private attachDebounce = (): void => {
    this.addSubscriptions(
      [
        this._debounceSubject
          .pipe(
            debounceTime(applicationEnvironment.search.debounceTime),
            distinctUntilChanged())
          .subscribe(phrase => {
            if (!this.canTriggerSearch())
              return;

            this._lastSearch = phrase;
            this._searchPhraseEmitter.emit(phrase);
          })
      ]);
  };

  private handleClearButton = () => {
    this.attachDebounce();
    this._lastSearch = '';
    this._searchPhraseEmitter.emit('');
  };

  //#endregion
}
