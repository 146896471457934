<div class="grid">
  <kendo-grid #grid
              [data]="gridData | async"
              [loading]="isLoading"
              [height]="height"
              [resizable]="true"
              [sortable]="sortSettings"
              scrollable="virtual"
              [skip]="state.skip"
              [pageSize]="state.take"
              [rowHeight]="rowHeight"
              [sort]="currentSort"
              [filterable]="'menu'"
              [filter]="currentFilter"
              [selectable]="selectionSettings"
              [kendoGridSelectBy]="selectionKey"
              [rowSelected]="isRowSelected"
              (selectionChange)="onSelectionChange($event)"
              (sortChange)="onSortChange($event)"
              (pageChange)="onPageChange($event)">
    <kendo-grid-column *ngFor="let gridColumn of visibleColumns"
                       [title]="gridColumn.title"
                       [field]="gridColumn.field"
                       [sortable]="canSort(gridColumn)"
                       [filterable]="canFilter(gridColumn)">
      <ng-template kendoGridFilterMenuTemplate>
        <clevermailing-filter-selector #filterTemplate
                                       [template]="filterTemplate"
                                       [filter]="currentFilter"
                                       [definition]="gridColumn.definition"></clevermailing-filter-selector>
      </ng-template>
      <ng-template kendoGridHeaderTemplate>
        <clevermailing-header [gridColumn]="gridColumn"
                              [(isSelectAll)]="isSelectAll"></clevermailing-header>
      </ng-template>
      <ng-template kendoGridCellTemplate
                   let-dataItem>
        <clevermailing-cell-selector [resource]="dataItem"
                                     [definition]="gridColumn.definition"
                                     (click)="onCellSelectorClick($event, dataItem)"
                                     (valueChanged)="onValueChanged($event, dataItem)"> </clevermailing-cell-selector>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="showCommands"
                       [title]="'Bearbeitungsoptionen'">
      <ng-template kendoGridCellTemplate
                   let-dataItem>
        <clevermailing-command-cell [commands]="commands"
                                    [model]="getModelBuilderInfo(dataItem)"></clevermailing-command-cell>
      </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridNoRecordsTemplate>
      <div *ngIf="noDataToShow"
           class="no-data-message">
        {{ 'GRID.MESSAGES.NO_DATA' | translate }}
      </div>
    </ng-template>
  </kendo-grid>
</div>
