<div class="filter-selector">
  <div class="header">
    <div class="title">
      {{ title }}
    </div>
    <div class="commands">
      <clevermailing-command-button *ngFor="let command of commands"
                                    [command]="command"></clevermailing-command-button>
    </div>
  </div>
  <div class="filter">
    <ng-container [ngSwitch]="filterType">
      <clevermailing-value-filter *ngSwitchCase="filterTypes.ValueFilter"
                                  [definition]="definition"
                                  [itemFilter]="itemFilter"></clevermailing-value-filter>
      <clevermailing-numeric-value-filter *ngSwitchCase="filterTypes.NumericValueFilter"
                                          [definition]="definition"
                                          [itemFilter]="itemFilter"></clevermailing-numeric-value-filter>
      <clevermailing-values-filter *ngSwitchCase="filterTypes.ValuesFilter"
                                   [definition]="definition"
                                   [itemFilter]="itemFilter"></clevermailing-values-filter>
      <clevermailing-date-range-filter *ngSwitchCase="filterTypes.DateRangeFilter"
                                       [definition]="definition"
                                       [itemFilter]="itemFilter"></clevermailing-date-range-filter>
      <clevermailing-numeric-range-filter *ngSwitchCase="filterTypes.NumericRangeFilter"
                                          [definition]="definition"
                                          [itemFilter]="itemFilter"></clevermailing-numeric-range-filter>
      <clevermailing-checkbox-filter *ngSwitchCase="filterTypes.CheckboxFilter"
                                     [definition]="definition"
                                     [itemFilter]="itemFilter"></clevermailing-checkbox-filter>
      <!-- <div *ngSwitchDefault>
        unbekannter filter: {{ filterType }}
      </div> -->
    </ng-container>
  </div>
</div>