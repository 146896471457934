/* eslint-disable no-bitwise */
import { List } from 'linqts';
import { OptionTypes } from 'src/common/webapi/contracts/enum/optionTypes';
import { ViewItemTypes } from '../viewItemTypes';

export class OptionTypesToViewTypesConverter {
  //#region -- methods --

  public static convert = (source: OptionTypes): ViewItemTypes =>
    new List(
      [
        { option: OptionTypes.Text, viewItemType: ViewItemTypes.TextOption },
        { option: OptionTypes.Icon, viewItemType: ViewItemTypes.IconOption },
        { option: OptionTypes.Icon | OptionTypes.Text, viewItemType: ViewItemTypes.IconTextOption },
      ]
    ).FirstOrDefault(x => x.option === source)?.viewItemType
    ?? ViewItemTypes.None;

  //#endregion
}
