import { OptionTypes } from '../../enum/optionTypes';
import { OptionBase } from './bases/optionBase';

export class Option
    extends OptionBase {
    //#region -- fields --

    public type: OptionTypes = OptionTypes.Text;

    public text: string;

    //#endregion
}
