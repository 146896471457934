import { Mapper } from '@automapper/types';
import { ItemBuilderContext } from './itemBuilderContext';

export class ItemBuilder<TType, TResult> {
  //#region -- fields --

  private _type: TType;
  private _builder: (mapper: Mapper, input: ItemBuilderContext) => TResult;

  //#endregion

  //#region -- properties --

  public get type(): TType {
    return this._type;
  }

  public get build(): (mapper: Mapper, input: ItemBuilderContext) => TResult {
    return this._builder;
  };

  //#endregion

  //#region -- constructor --

  constructor(
    type: TType,
    build: (mapper: Mapper, input: ItemBuilderContext) => TResult
  ) {
    this._type = type;
    this._builder = build;
  }

  //#endregion
}
