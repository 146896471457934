<div class="column-selector">
  <div class="header">
    <div class="title">
      {{ 'COLUMN_SELECTOR.TITLE' | translate }}
    </div>
    <div class="commands">
      <clevermailing-command-button *ngFor="let command of commands"
                                    [command]="command"></clevermailing-command-button>
    </div>
  </div>
  <div class="actions">
    <div class="select-all">
      <input type="checkbox"
             kendoCheckBox
             [disabled]="!canSelectAllColumns()"
             (change)="toggle()"
             [checked]="isAllSelected"
             [indeterminate]="isIndeterminate" />
      <span>
        {{ 'COLUMN_SELECTOR.SELECT_ALL' | translate }}
      </span>
    </div>
  </div>
  <div class="content">
    <kendo-grid [ngStyle]="{ 'border': 'none' }"
                [data]="data"
                [groupable]="false"
                [hideHeader]="true"
                scrollable="none">
      <kendo-grid-column>
        <ng-template kendoGridCellTemplate
                     let-dataItem
                     let-rowIndex="rowIndex">
          <div class="item">
            <div class="checkbox">
              <input kendoCheckBox
                     type="checkbox"
                     [(ngModel)]="dataItem.isSelected"
                     [disabled]="isRequired(dataItem)" />
            </div>
            <div class="name">
              {{ dataItem.title }}
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="group.name"
                         [hidden]="true">
        <ng-template kendoGridGroupHeaderTemplate
                     let-value="value"
                     hidden="true">
          {{ value }}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>