<div class="widget-base">
  <div class="header">
    <span class="{{ icon }}"></span>
    <div class="title">
      {{ title | translate }}
    </div>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>