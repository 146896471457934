/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SearchCriteria } from 'src/common/models/searchCriteria';
import { applicationEnvironment } from 'src/environments/application.environment';

@Injectable()
export class VirtualizationProvider {
  //#region -- fields --

  private readonly _stateChangedSource: Subject<{ skip: number; take: number }>;

  private _state: { skip: number; take: number };

  //#endregion

  //#region -- properties --

  public get stateChanged(): Observable<{ skip: number; take: number }> {
    return this._stateChangedSource.asObservable();
  }

  public get state(): { skip: number; take: number } {
    return this._state;
  }

  //#endregion

  //#region -- constructor --

  public constructor() {
    this._stateChangedSource = new Subject<{ skip: number; take: number }>();
    this._state = {
      skip: 0,
      take: applicationEnvironment.pageing.virtual
    };
  }

  //#endregion

  //#region -- methods --

  public onStateChanged = (value: { skip: number; take: number }): void => {
    if (this._state !== value) {
      this._state = value;
      this._stateChangedSource.next(value);
    }
  };

  public setToSearchCriterias = (criterias: SearchCriteria): SearchCriteria => {
    criterias.skip = this._state.skip;
    criterias.take = this._state.take;

    return criterias;
  };

  //#endregion
}
