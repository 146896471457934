import { Injectable } from '@angular/core';
import { List } from 'linqts';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { FilterTypes } from '../enums/filterTypes';
import { CustomFilterBase } from './base/customFilter.base';
import { CheckboxFilter } from './checkboxFilter';
import { DateRangeFilter } from './dateRangeFilter';
import { NumericRangeFilter } from './numbericRangeFilter';
import { NumericValueFilter } from './numericValueFilter';
import { ValueFilter } from './valueFilter';
import { ValuesFilter } from './valuesFilter';

@Injectable({
  providedIn: 'root'
})
export class FilterFactory {
  //#region -- configuration --

  private static readonly FilterMap = new List<{ key: FilterTypes; creator: (definition: AttributeDefinitionBase) => CustomFilterBase<any> }>(
    [
      { key: FilterTypes.ValueFilter, creator: definition => new ValueFilter(definition.key, definition.regex) },
      { key: FilterTypes.NumericValueFilter, creator: definition => new NumericValueFilter(definition.key, definition.regex) },
      { key: FilterTypes.ValuesFilter, creator: definition => new ValuesFilter(definition.key, definition.regex) },
      { key: FilterTypes.DateRangeFilter, creator: definition => new DateRangeFilter(definition.key, definition.regex) },
      { key: FilterTypes.NumericRangeFilter, creator: definition => new NumericRangeFilter(definition.key, definition.regex) },
      { key: FilterTypes.CheckboxFilter, creator: definition => new CheckboxFilter(definition.key, definition.regex) }
    ]
  );

  //#endregion

  //#region -- methods --

  public create = (definition: AttributeDefinitionBase, filterType: FilterTypes): CustomFilterBase<any> =>
    FilterFactory.FilterMap
      .FirstOrDefault(item => item.key === filterType)
      .creator(definition);

  //#endregion
}
