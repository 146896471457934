import { List } from 'linqts';
import { isNotNullOrUndefined } from 'src/shared/helper/object.helper';
import { CustomFilterBase } from './base/customFilter.base';

export class NumericRangeFilter
  extends CustomFilterBase<{ start: number; end: number }> {
  // #region -- constructor --

  public constructor(
    key: string,
    regex: string
  ) {
    super(key, regex, { start: undefined, end: undefined });
  }

  //#endregion

  //#region -- methods  --

  public isValid = (): boolean =>
    isNotNullOrUndefined(this.model.start)
    && isNotNullOrUndefined(this.model.end)
    && this.validateRegex();

  public toParams = (id: number): List<{ key: string; value: string }> =>
    new List<{ key: string; value: string }>(
      [
        { key: `filters${id}.by`, value: this.key },
        { key: `filters${id}.min`, value: `${this.model.start}` },
        { key: `filters${id}.max`, value: `${this.model.end}` }
      ]);

  private validateRegex = (): boolean =>
    isNotNullOrUndefined(this.regex)
      ? RegExp(this.regex).test(this.model.start.toString())
        && RegExp(this.regex).test(this.model.end.toString())
      : true;

  //#endregion
}
