import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { List } from 'linqts';
import { Subscription } from 'rxjs';
import { AttributeGroup } from 'src/common/webapi/contracts/attributes/attributeGroup';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { AttributeValue } from 'src/common/webapi/contracts/attributes/values/attributeValue';
import { Resource } from 'src/common/webapi/contracts/resource';
import { ResourceDefinition } from 'src/common/webapi/contracts/resourceDefinition';
import { isNullOrUndefined } from 'src/shared/helper/object.helper';
import { EditorItem } from '../../editor/models/editorItem';

@Directive()
export abstract class CellBaseComponent<TDefinitionType extends AttributeDefinitionBase>
  implements OnDestroy {
  //#region -- configuration --

  private static valueList: List<AttributeValue>;
  private static valueId: number;

  //#endregion

  //#region -- fields --

  private readonly _valueChangedEventEmitter: EventEmitter<any>;

  private _editorItemSubscription: Subscription;
  private _inlineEditorItem: EditorItem;
  private _resource: Resource;
  private _definition: TDefinitionType;
  private _attributeValue: AttributeValue;
  private _value: any;

  //#endregion

  //#region -- properties --

  @Input()
  public set resource(value: Resource) {
    this._resource = value;

    this.initialize();
  }

  public get resource(): Resource {
    return this._resource;
  }

  @Input()
  public set definition(value: TDefinitionType) {
    this._definition = value;

    this.initialize();
  }

  public get definition(): TDefinitionType {
    return this._definition;
  }

  public get attributeValue(): AttributeValue {
    return this._attributeValue;
  }

  public get value(): any {
    return this._value;
  }

  public set value(value: any) {
    this._value = value;
    this._valueChangedEventEmitter.emit(value);
  }

  public get isEditable(): boolean {
    return AttributeDefinitionBase.isEditable(this._definition)
      && this.attributeValue?.isInlineEditable;
  }

  public get inlineEditorItem(): EditorItem {
    return isNullOrUndefined(this._inlineEditorItem) || this._inlineEditorItem.id !== this._resource.id
      ? this.getInlineEditorItem()
      : this._inlineEditorItem;
  }

  @Output()
  public get valueChanged(): EventEmitter<any> {
    return this._valueChangedEventEmitter;
  }

  //#endregion

  //#region -- constructor --

  public constructor() {
    this._valueChangedEventEmitter = new EventEmitter<any>();
  }

  //#endregion

  //#region -- methods --

  ngOnDestroy(): void {
    this._editorItemSubscription?.unsubscribe();
    this._editorItemSubscription = undefined;
  }

  public onClick = (event: MouseEvent): void => {
    if (event.ctrlKey || event.shiftKey)
      event.preventDefault();
  };

  public getInlineEditorItem = (): EditorItem => {
    this._inlineEditorItem = this.createInlineEditorItem();

    this._editorItemSubscription = this._inlineEditorItem
      .onAttributeChanged
      .subscribe(_ => this.value = this._inlineEditorItem.getEditorAttribute(this.definition).value);

    return this._inlineEditorItem;
  };

  private initialize = (): void => {
    if (isNullOrUndefined(this._definition) || isNullOrUndefined(this._resource))
      return;

    this._attributeValue = this.getAttributeValue();
    this._value = this.getValue();
  };

  private getAttributeValue = (): AttributeValue => {
    if (isNullOrUndefined(CellBaseComponent.valueList) || CellBaseComponent.valueId !== this._resource.id) {
      CellBaseComponent.valueList = new List<AttributeValue>(this._resource.values);
      CellBaseComponent.valueId = this._resource.id;
    }

    return new List<AttributeValue>(this._resource.values)
      .FirstOrDefault((attributeValue: AttributeValue) => attributeValue.definitionKey === this._definition.key)
      ?? <AttributeValue>{
        definitionKey: this._definition.key,
        value: ''
      };
  };

  private createInlineEditorItem = (): EditorItem =>
    new EditorItem(<ResourceDefinition>{
      groups: [
        <AttributeGroup>{
          name: this._definition.key,
          order: 0,
          attributes:
            [
              this.definition
            ]
        }
      ]
    }, this.resource);

  public abstract getValue(): any;

  //#endregion
}
