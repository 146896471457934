import { List } from 'linqts';
import { ComboboxAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/comboboxAttributeDefinition';
import { OptionBase } from 'src/common/webapi/contracts/attributes/options/bases/optionBase';
import { AttributeValue } from 'src/common/webapi/contracts/attributes/values/attributeValue';
import { isNullOrUndefined } from 'src/shared/helper/object.helper';
import { ItemBuilderContext } from './itemBuilderContext';

export class OptionBuilderContext
  extends ItemBuilderContext {
  //#region -- fields --

  private _option: OptionBase;

  //#endregion

  //#region -- constructor --

  constructor(
    definition: ComboboxAttributeDefinition,
    value: AttributeValue
  ) {
    super(definition, value);

    this._option = this.optionExtractor(definition, value);
  }

  //#endregion

  //#region -- methods --

  public option = <TOption extends OptionBase>(): TOption =>
    <TOption>this._option;

  private readonly optionExtractor = (definition: ComboboxAttributeDefinition, value: AttributeValue): OptionBase => {
    if (isNullOrUndefined(definition) || isNullOrUndefined(value))
      return undefined;

    return new List(definition.options)
      .FirstOrDefault(x => x.value === value.value);
  };

  //#endregion
}
