import { AttributeGroup } from './attributes/attributeGroup';
import { ResourceTypes } from './enum/resourceTypes';

export class ResourceDefinition {
    //#region -- fields --

    public type: ResourceTypes;
    public groups: AttributeGroup[];

    //#endregion

    //#region -- constructors --

    public constructor() {
        this.groups = [];
    }

    //#endregion
}
