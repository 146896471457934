/* eslint-disable no-bitwise */
import { Inject, Injectable } from '@angular/core';
import { Mapper } from '@automapper/types';
import { SortSettings } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { List } from 'linqts';
import { Observable, Subject } from 'rxjs';
import { GridColumn } from 'src/common/models/gridColumn';
import { GridSortDescriptor } from 'src/common/models/gridSortDescriptor';
import { SearchCriteria } from 'src/common/models/searchCriteria';
import { MAPPER } from 'src/common/token/tokens';
import { AttributeAccessibilities } from 'src/common/webapi/contracts/enum/attributeAccessibilities';
import { EnumHelper } from 'src/shared/helper/enum.helper';
import { isNotNullOrUndefined } from 'src/shared/helper/object.helper';
import { GridProvider } from './base/grid.provider.base';

@Injectable()
export class SortProvider
  extends GridProvider {
  //#region -- configuration  --

  private static defaultSortSettings: SortSettings = <SortSettings>{
    allowUnsort: true,
    mode: 'single'
  };

  //#endregion

  //#region -- fields --

  private readonly _mapper: Mapper;
  private readonly _sortChangedSource: Subject<void>;

  private _currentSort: SortDescriptor[];

  //#endregion

  //#region -- properties --

  public get sortChanged(): Observable<void> {
    return this._sortChangedSource.asObservable();
  }

  public get sortSettings(): SortSettings {
    return SortProvider.defaultSortSettings;
  }

  public get currentSort(): SortDescriptor[] {
    return this._currentSort;
  }

  public get isSet(): boolean {
    return this._currentSort?.length > 0;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    @Inject(MAPPER) mapper: Mapper
  ) {
    super();

    this._mapper = mapper;

    this._currentSort = <SortDescriptor[]>[];
    this._sortChangedSource = new Subject<void>();
  }

  //#endregion

  //#region -- methods --

  public canSort = (column: GridColumn): boolean =>
    isNotNullOrUndefined(column.definition)
    && EnumHelper.matchFlag<AttributeAccessibilities>(column.definition.accessibility, AttributeAccessibilities.GridSortable);

  public onSortChanged = (descriptors: SortDescriptor[]): SortDescriptor[] => {
    if (this._currentSort === descriptors)
      return;

    this._currentSort = descriptors.length > 0 && isNotNullOrUndefined(descriptors[0].dir)
      ? descriptors
      : [];

    this._sortChangedSource.next();
  };

  public setToSearchCriterias = (criterias: SearchCriteria): SearchCriteria => {
    if (this._currentSort?.length === 0)
      return criterias;

    const mapped = this._mapper.map(this._currentSort[0], SearchCriteria, GridSortDescriptor);

    criterias.sortBy = mapped.sortBy;
    criterias.sortDirection = mapped.sortDirection;

    return criterias;
  };

  public clearByVisibleColumns = (visibleColumns: GridColumn[]): void => {
    const columnKeys = new List(visibleColumns).Select(column => column.field);

    if (this._currentSort.length > 0 && !columnKeys.Contains(this._currentSort[0].field)) {
      this._currentSort = [];
      this._sortChangedSource.next();
    }
  };

  public clear = (): SortDescriptor[] =>
    this.onSortChanged([]);

  //#endregion
}
