import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SubscriptionBase } from 'src/shared/base/subscription.base';
import { Size } from '../models/size';

@Component({
  selector: 'clevermailing-view-content',
  templateUrl: './view-content.component.html',
  styleUrls: ['./view-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewContentComponent
  extends SubscriptionBase
  implements OnInit, OnDestroy, AfterViewInit {
  //#region -- configuration --

  private static readonly SizeChangeDebounceTime: number = 200;

  //#endregion

  //#region -- fields --

  private readonly _sizeChangedDebounceSource: Subject<Size>;
  private readonly _sizeChangedEventEmitter: EventEmitter<Size>;

  private _elementRef: ElementRef<any>;

  //#endregion

  //#region -- properties --

  @Output()
  public get sizeChanged(): EventEmitter<Size> {
    return this._sizeChangedEventEmitter;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    elementRef: ElementRef
  ) {
    super();

    this._elementRef = elementRef;

    this._sizeChangedDebounceSource = new Subject<Size>();
    this._sizeChangedEventEmitter = new EventEmitter<Size>();
  }

  //#endregion

  //#region -- host listener --

  @HostListener('window:resize', ['$event'])
  onResize(_: Event): void {
    this._sizeChangedDebounceSource.next(this.getSize());
  }

  //#endregion

  //#region -- methods --

  ngOnInit(): void {
    this.addSubscriptions(
      [
        this._sizeChangedDebounceSource
          .pipe(
            debounceTime(ViewContentComponent.SizeChangeDebounceTime),
            distinctUntilChanged())
          .subscribe(size => this._sizeChangedEventEmitter.emit(size)
          )
      ]);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  ngAfterViewInit(): void {
    this._sizeChangedDebounceSource.next(this.getSize());
  }

  private getSize = (): Size =>
    <Size>{
      height: (<HTMLElement>this._elementRef.nativeElement).offsetHeight,
      width: (<HTMLElement>this._elementRef.nativeElement).offsetWidth,
    };

  //#endregion
}

