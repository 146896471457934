import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CheckboxAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/checkboxAttributeDefinition';
import { UserService } from 'src/shared/services/user.service';
import { CellBaseComponent } from '../cell.base.component';

@Component({
  selector: 'clevermailing-checkbox-cell',
  templateUrl: './checkbox-cell.component.html',
  styleUrls: ['./checkbox-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxCellComponent
  extends CellBaseComponent<CheckboxAttributeDefinition> {
  //#region -- fields --

  private readonly _userService: UserService;

  //#endregion

  //#region -- properties --

  public get isEditable(): boolean {
    return !this._userService.isAdmin
      && super.isEditable;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    userService: UserService
  ) {
    super();

    this._userService = userService;
  }

  //#endregion

  //#region -- methods --

  public getValue = (): boolean =>
    this.attributeValue?.value;

  //#endregion
}
