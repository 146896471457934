<div class="dialog-base">
  <div class="header">
    <div class="title">
      {{ title | translate }}
    </div>
    <div class="commands">
      <clevermailing-command-button *ngFor="let command of commands"
                                    [command]="command"></clevermailing-command-button>
    </div>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>