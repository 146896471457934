import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Resource } from 'src/common/webapi/contracts/resource';
import { LoopingHttpClient } from '../http/loopingHttpClient';

@Injectable()
export class DocumentService {
  //#region -- fields --

  private readonly _httpClient: LoopingHttpClient;
  private readonly _itemUrl: string;

  //#endregion

  //#region -- constructor --

  public constructor(
    httpClient: LoopingHttpClient,
    itemUrl: string
  ) {
    this._httpClient = httpClient;
    this._itemUrl = itemUrl;
  }

  //#endregion

  //#region -- methods --

  public getDocument = (resource: Resource): Observable<Blob> =>
    this._httpClient.downloadOctetStream(this.buildDocumentUrl(resource));

  private buildDocumentUrl = (resource: Resource): string =>
    `${this._itemUrl}/${resource.id}`;

  //#endregion
}
