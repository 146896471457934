/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { List } from 'linqts';
import { AttributeAccessibilities } from 'src/common/webapi/contracts/enum/attributeAccessibilities';
import { ResourceDefinition } from 'src/common/webapi/contracts/resourceDefinition';
import { EnumHelper } from 'src/shared/helper/enum.helper';

@Injectable()
export class SelectionProvider {
  //#region -- configuration  --

  private static defaultSelectionSettingsEnabled: SelectableSettings = <SelectableSettings>{
    enabled: true,
    drag: true,
    mode: 'multiple'
  };

  private static defaultSelectionSettingsDisabled: SelectableSettings = <SelectableSettings>{
    enabled: false
  };

  //#endregion

  //#region -- fields --

  private _selectionEnabled: boolean;
  private _selectionSettings: SelectableSettings;
  private _isSelectAll: boolean;
  private _selection: List<number>;

  //#endregion

  //#region -- properties --

  public get selectionSettings(): SelectableSettings {
    return this._selectionSettings;
  }

  public get selection(): number[] {
    return this._selection.ToArray();
  }

  public set selection(value: number[]) {
    this._selection = new List(value);

    this._isSelectAll = false;
  }

  public set selectionEnabled(value: boolean) {
    this._selectionEnabled = value;

    this._selectionSettings = value
      ? SelectionProvider.defaultSelectionSettingsEnabled
      : SelectionProvider.defaultSelectionSettingsDisabled;
  }

  public get selectionEnabled(): boolean {
    return this._selectionEnabled;
  }

  public get isSelectAll(): boolean {
    return this._isSelectAll;
  }

  public set isSelectAll(value: boolean) {
    this._isSelectAll = value;
  }

  //#endregion

  //#region -- constructor --

  public constructor() {
    this._selectionSettings = SelectionProvider.defaultSelectionSettingsDisabled;
    this._isSelectAll = false;
    this._selection = new List<number>();
  }

  //#endregion

  //#region -- methods --

  public static getDependendSelectionKey = (definitions: ResourceDefinition): string =>
    new List(definitions.groups)
      .SelectMany(group => new List(group.attributes))
      .FirstOrDefault(attribute => EnumHelper.matchFlag<AttributeAccessibilities>(attribute.accessibility, AttributeAccessibilities.GridSelectAllDependency))?.key
    ?? undefined;

  public containsId = (id: number): boolean => {
    return this._isSelectAll || this._selection.Contains(id);
  };

  public add = (id: number): void =>
    this._selection.Add(id);

  public remove = (id: number): boolean =>
    this._selection.Remove(id);

  //#endregion
}
