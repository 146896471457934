import { GridColumn } from 'src/common/models/gridColumn';
import { SubscriptionBase } from 'src/shared/base/subscription.base';

export abstract class GridProvider
  extends SubscriptionBase {
  //#region -- fields --

  public abstract get isSet(): boolean;

  //#endregion

  //#region -- methods --

  public abstract clearByVisibleColumns(visibleColumns: GridColumn[]): void;
  public abstract clear(): void;

  //#endregion
}
