import { HttpParams } from '@angular/common/http';
import { List } from 'linqts';
import { SearchCriteria } from 'src/common/models/searchCriteria';
import { CustomFilterBase } from 'src/shared/components/grid/models/filter/base/customFilter.base';
import { HttpHelper } from '../../helper/http.helper';
import { isNotNullOrUndefined } from '../../helper/object.helper';
import { QueryStringBuilderRuleBase } from './base/queryStringBuilderRuleBase';

export class SearchCriteriaQueryStringBuilderRule
  extends QueryStringBuilderRuleBase {
  //#region -- configuration --

  private static readonly FilterIdentifier: string = 'filters';
  private static readonly VisibleColoumsIdentifier: string = 'visibledefinitions';

  private static readonly QueryRuleIdentifier: List<string> = new List<string>(
    [
      SearchCriteriaQueryStringBuilderRule.FilterIdentifier,
      SearchCriteriaQueryStringBuilderRule.VisibleColoumsIdentifier
    ]);

  //#endregion

  //#region  -- methods --

  public canBuild = (params?: any): boolean =>
    isNotNullOrUndefined(params)
    && new List(Object.keys(params))
      .Any(prop => SearchCriteriaQueryStringBuilderRule.QueryRuleIdentifier.Contains(prop));

  public build = (params: any): HttpParams => {
    const criterias = <SearchCriteria>params;

    const item: any = {};
    new List(Object.keys(params))
      .Where(prop => !SearchCriteriaQueryStringBuilderRule.QueryRuleIdentifier.Contains(prop))
      .ForEach(prop => item[prop] = params[prop]);

    return (isNotNullOrUndefined(criterias.filters) && criterias.filters.length > 0) || criterias.visibledefinitions.length > 0
      ? this.buildFilters(criterias, HttpHelper.getHttpParamsFromAny(item))
      : HttpHelper.getHttpParamsFromAny(item);
  };

  private buildFilters = (searchCriteria: SearchCriteria, params: HttpParams): HttpParams => {
    params = this.generateFilters(new List(searchCriteria.filters), params);
    params = this.generateVisibleDefinitions(new List(searchCriteria.visibledefinitions), params);

    return params;
  };

  private generateFilters = (filters: List<CustomFilterBase<any>>, params: HttpParams): HttpParams => {
    let filterCount = 0;

    filters.ForEach(filter => {
      filter.toParams(++filterCount)
        .ForEach(param => params = params.append(param.key, param.value));
    });

    return params;
  };

  private generateVisibleDefinitions = (columnKey: List<string>, params: HttpParams): HttpParams => {
    columnKey
      .ForEach(column => params = params.append(SearchCriteriaQueryStringBuilderRule.VisibleColoumsIdentifier, column));
    return params;
  };

  //#endregion
}
