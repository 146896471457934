import { SortDescriptor } from '@progress/kendo-data-query';

export class GridSortDescriptor
  implements SortDescriptor {
  //#region -- fields --

  field: string;
  dir?: 'asc' | 'desc';

  //#endregion
}
