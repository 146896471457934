export class Authorization {
  //#region -- fields --

  public access_token: string;
  public token_type: string;

  //#endregion

  //#region -- methods --

  public static getHeaderValue = (authorization: Authorization): string =>
    `${authorization.token_type} ${authorization.access_token}`;

  //#endregion
}
