import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SubscriptionBase } from './subscription.base';

@Injectable()
export abstract class LoadingIndicatorBase
  extends SubscriptionBase
  implements OnDestroy {
  //#region -- fields --

  private _currentLoadingCount: number = 0;
  private _activeSubscription: Subscription;

  //#endregion

  //#region -- properties --

  public get isLoading(): boolean {
    return this._currentLoadingCount > 0;
  }

  //#endregion

  //#region -- methods --

  ngOnDestroy(): void {
    this.clearSubscriptions();

    this._activeSubscription?.unsubscribe();
    this._activeSubscription = undefined;
  }

  protected callWithLoadingIndicator = (request: Observable<any>, resultHandler: (result: any) => void): void => {
    this._activeSubscription = this.callWithLoadingIndicatorAndReturnResult(request, resultHandler)
      .subscribe();
  };

  protected callWithLoadingIndicatorAndReturnResult = (request: Observable<any>, resultHandler: (result: any) => void = () => { }): Observable<void> => {
    this._currentLoadingCount++;

    return request
      .pipe(
        map(result => resultHandler(result)),
        tap(() => this._currentLoadingCount--),
        catchError(error => {
          this._currentLoadingCount--;

          return throwError(error);
        }));
  };

  //#endregion
}
