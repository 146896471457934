import { HttpParams } from '@angular/common/http';
import { HttpHelper } from 'src/shared/helper/http.helper';
import { QueryStringBuilderRuleBase } from './base/queryStringBuilderRuleBase';

export class DefaultQueryStringBuilderRule
  extends QueryStringBuilderRuleBase {
  //#region  -- methods --

  public canBuild = (_?: any): boolean =>
    true;

  public build = (params: any): HttpParams =>
    HttpHelper.getHttpParamsFromAny(params);

  //#endregion
}
