import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DrawerComponent, DrawerItem, DrawerSelectEvent } from '@progress/kendo-angular-layout';
import { List } from 'linqts';
import { Title } from '@angular/platform-browser';
import { ExtendedDrawerItem } from 'src/components/models/extendedDrawerItem';
import { applicationEnvironment } from 'src/environments/application.environment';
import { navigationConfiguration } from 'src/environments/navigation.configuration';
import { isNotNullOrUndefinedOrEmpty, isNullOrUndefined, isNullOrUndefinedOrEmpty } from 'src/shared/helper/object.helper';
import { UserService } from 'src/shared/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  //#region -- configuration --

  private static readonly ClassNameForSelectedItem: string = 'k-state-selected';
  private static readonly TagNameForDrawer: string = 'kendo-drawer';
  private static readonly TagNameForDrawerItem: string = 'li';

  //#endregion

  //#region -- fields --

  private readonly _items: List<ExtendedDrawerItem>;
  private readonly _userService: UserService;
  private readonly _router: Router;
  private readonly _translateService: TranslateService;

  private _titleService: Title;
  private _isExpanded: boolean;
  private _drawer: DrawerComponent;

  //#endregion

  //#region -- properties --

  @ViewChild('drawer')
  public set drawer(value: DrawerComponent) {
    this._drawer = value;
  }

  public set isExpanded(value: boolean) {
    this._isExpanded = value;
  }

  public get isExpanded(): boolean {
    return this._isExpanded;
  }

  public get navigationItems(): DrawerItem[] {
    return this._items.ToArray();
  }

  public get isNavigationDisabled(): boolean {
    return !this._userService.isLoggedIn || this._userService.mustImpersonate;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    router: Router,
    translateService: TranslateService,
    userService: UserService,
    titleService: Title
  ) {

    this._router = router;
    this._translateService = translateService;
    this._userService = userService;
    this._titleService = titleService;
    this._titleService.setTitle(applicationEnvironment.name);

    this._items = new List(navigationConfiguration);
    this._isExpanded = false;

    translateService.setDefaultLang(applicationEnvironment.translation.defaultLanguage);

    this._router.events
      .subscribe(event => this.setCurrentNavigationItem(event));

    this._userService.userInfoChanged
      .subscribe(userInfo => this.setUserLanguage(userInfo?.language));
  }

  //#endregion

  //#region -- methods --

  public onSelect = (event: DrawerSelectEvent): void => {
    if (this.isNavigationDisabled)
      return;

    const item = <ExtendedDrawerItem>event.item;

    if (item.extendOnClick)
      return this.handleToggle(event);

    if (isNotNullOrUndefinedOrEmpty(item.path))
      this._router.navigate([item.path]);
  };

  private handleToggle = (event: DrawerSelectEvent): void => {
    this._drawer.toggle();
    event.preventDefault();
  };

  private setCurrentNavigationItem = (event: Event): void => {
    if (event instanceof NavigationEnd) {
      const itemToSelect = this._items
        .FirstOrDefault(x => x.path === event.url);

      if (isNullOrUndefined(itemToSelect))
        return;

      for (const item of [].slice.call(document.querySelector(AppComponent.TagNameForDrawer).getElementsByTagName(AppComponent.TagNameForDrawerItem)))
        item.classList.remove(AppComponent.ClassNameForSelectedItem);

      document.querySelector(`[title='${this._translateService.instant(itemToSelect.text)}']`)?.parentElement
        .classList.add(AppComponent.ClassNameForSelectedItem);
    }
  };

  private setUserLanguage = (language: string): void => {
    if (isNullOrUndefinedOrEmpty(language))
      return;

    this._translateService.use(language);
  };

  //#endregion
}
