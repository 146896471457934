import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TextLink } from '../../models/links/textLink';
import { ItemBaseComponent } from '../item.base.component';

@Component({
  selector: 'clevermailing-text-link-item',
  templateUrl: './text-link-item.component.html',
  styleUrls: ['./text-link-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextLinkItemComponent
  extends ItemBaseComponent<TextLink> { }
