<div class="cell-selector"
     [ngSwitch]="definition.type">
  <clevermailing-text-cell *ngSwitchCase="attributeTypes.Text"
                           [resource]="resource"
                           [definition]="definition"
                           (valueChanged)="onValueChanged($event)"></clevermailing-text-cell>
  <clevermailing-number-cell *ngSwitchCase="attributeTypes.Number"
                             [resource]="resource"
                             [definition]="definition"
                             (valueChanged)="onValueChanged($event)"></clevermailing-number-cell>
  <clevermailing-datetime-cell *ngSwitchCase="attributeTypes.Datetime"
                               [resource]="resource"
                               [definition]="definition"
                               (valueChanged)="onValueChanged($event)"></clevermailing-datetime-cell>
  <clevermailing-combobox-cell *ngSwitchCase="attributeTypes.Combobox"
                               [resource]="resource"
                               [definition]="definition"
                               (valueChanged)="onValueChanged($event)"></clevermailing-combobox-cell>
  <clevermailing-checkbox-cell *ngSwitchCase="attributeTypes.Checkbox"
                               [resource]="resource"
                               [definition]="definition"
                               (valueChanged)="onValueChanged($event)"></clevermailing-checkbox-cell>
  <clevermailing-link-cell *ngSwitchCase="attributeTypes.Link"
                           [resource]="resource"
                           [definition]="definition"
                           (valueChanged)="onValueChanged($event)"></clevermailing-link-cell>
</div>