import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { EditorAttribute } from '../models/editorAttribute';
import { EditorGroup } from '../models/editorGroup';
import { EditorItem } from '../models/editorItem';

@Component({
  selector: 'clevermailing-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupComponent {
  //#region -- fields --

  private _group: EditorGroup;
  private _item: EditorItem;

  //#endregion

  //#region -- properties --

  @Input()
  public set group(value: EditorGroup) {
    this._group = value;
  }

  @Input()
  public set item(value: EditorItem) {
    this._item = value;
  }

  public get item(): EditorItem {
    return this._item;
  }

  public get name(): string {
    return this._group?.name;
  }

  public get attributeDefinitions(): AttributeDefinitionBase[] {
    return this._group?.definitions.ToArray();
  }

  //#endregion

  //#region -- methods --

  public getEditorAttribute = (definition: AttributeDefinitionBase): EditorAttribute =>
    this._item.getEditorAttribute(definition);

  public isValid = (definition: AttributeDefinitionBase): boolean =>
    this.getEditorAttribute(definition)?.isValid;

  //#endregion
}
