import { Component, Input } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ViewCommand } from 'src/shared/components/grid/models/command/viewCommand';
import { isNullOrUndefined } from 'src/shared/helper/object.helper';
import { DialogResult } from '../models/dialogResult';
import { DialogResultState } from '../models/enums/dialogResultState';

@Component({
  selector: 'clevermailing-dialog-base',
  templateUrl: './dialog-base.component.html',
  styleUrls: ['./dialog-base.component.scss']
})
export class DialogBaseComponent {
  //#region -- commands --

  private _commands: ViewCommand<any>[] =
    [
      new ViewCommand('DIALOG_BASE.COMMANDS.CLOSE', 'fa-solid fa-times', '', () => true, () => this._dialogRef?.close(new DialogResult(DialogResultState.Closed)))
    ];

  //#endregion

  //#region -- fields --

  private _title: string;
  private _dialogRef: DialogRef;

  //#endregion

  //#region -- properties --

  @Input()
  public set dialogRef(value: DialogRef) {
    this._dialogRef = value;
  }

  @Input()
  public set commands(value: ViewCommand<any>[]) {
    if (isNullOrUndefined(value) || value.length === 0)
      return;

    this._commands = value.concat(this._commands);
  }

  public get commands(): ViewCommand<any>[] {
    return this._commands;
  }

  @Input()
  public set title(value: string) {
    this._title = value;
  }

  public get title(): string {
    return this._title;
  }

  //#endregion
}
