import { HttpParams } from '@angular/common/http';

export abstract class QueryStringBuilderRuleBase {
  //#region  -- methods --

  public abstract canBuild(params?: any): boolean;
  public abstract build(params: any): HttpParams;

  //#endregion
}
