import { List } from 'linqts';

export abstract class CustomFilterBase<TModel> {
  //#region -- fields --

  private _key: string;
  private _regex: string;
  private _value: TModel;

  //#endregion

  //#region  -- properties --

  public get key(): string {
    return this._key;
  }

  public get regex(): string {
    return this._regex;
  }

  public set model(value: TModel) {
    this._value = value;
  }

  public get model(): TModel {
    return this._value;
  }

  //#endregion

  //#region -- constructor --

  protected constructor(
    key: string,
    regex: string,
    value: TModel
  ) {
    this._key = key;
    this._regex = regex;
    this._value = value;
  }

  //#endregion

  //#region -- methods  --

  public abstract isValid(): boolean;
  public abstract toParams(id: number): List<{ key: string; value: string }>;

  //#endregion
}

