<div class="company-info">
  <div class="logo">
    <img [src]="logoPath">
  </div>
  <div class="title">
    <div class="head">
      {{ header }}
    </div>
    <div class="info">
      {{ info }}
    </div>
  </div>
</div>