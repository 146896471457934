import { Directive, Input } from '@angular/core';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { isNullOrUndefined } from 'src/shared/helper/object.helper';
import { EditorAttribute } from '../models/editorAttribute';

@Directive()
export abstract class EditorBaseComponent<
  TDefinitionType extends AttributeDefinitionBase,
  TModel,
  TAttributeValue
  > {
  //#region -- fields --

  private readonly _fromModel: (value: TModel) => TAttributeValue;
  private readonly _toModel: (value: TAttributeValue) => TModel;
  private readonly _defaultValueForModel: TModel;

  private _model: TModel;
  private _editorAttribute: EditorAttribute;

  //#endregion

  //#region -- properties --

  @Input()
  public set editorAttribute(value: EditorAttribute) {
    this._editorAttribute = value;
    this._model = undefined;
  }

  public get editorAttribute(): EditorAttribute {
    return this._editorAttribute;
  }

  public get model(): TModel {
    if (isNullOrUndefined(this._model))
      this._model = this._toModel(this._editorAttribute.value);

    return this._model;
  }

  public set model(value: TModel) {
    this._editorAttribute.value = this._fromModel(value);
  }

  public get definition(): TDefinitionType {
    return <TDefinitionType>this._editorAttribute.definition;
  }

  public get regex(): string {
    return this.editorAttribute.definition.regex;
  }

  public get isReadonly(): boolean {
    return this._editorAttribute.isReadonly;
  }

  public get isRequired(): boolean {
    return this._editorAttribute.isRequired;
  }

  public get isValid(): boolean {
    return this._editorAttribute.isValid;
  }

  //#endregion

  //#region -- constructor --

  protected constructor(
    fromModel: (value: TModel) => TAttributeValue,
    toModel: (value: TAttributeValue) => TModel,
    defaultValueForModel?: TModel
  ) {
    this._fromModel = fromModel;
    this._toModel = toModel;

    this._defaultValueForModel = defaultValueForModel;
  }

  //#endregion
}
