import { GridDataResult } from '@progress/kendo-angular-grid';
import { Resource } from '../webapi/contracts/resource';

export class ResourceGridDataResult
  implements GridDataResult {
  // #region -- fields --

  data: Resource[];
  total: number;

  //#endregion
}
