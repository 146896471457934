<div>
  <input #selectAll
         *ngIf="showSelectAll"
         kendoCheckBox
         class="select-all"
         type="checkbox"
         [(ngModel)]="isSelectAll"
         [disabled]="isDisabled">
  <span class="title">
    {{ title }}&nbsp;
  </span>
</div>