import { ChangeDetectionStrategy, Component } from '@angular/core';
import { applicationEnvironment } from 'src/environments/application.environment';

@Component({
  selector: 'clevermailing-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  //#region -- properties --

  public get logoPath(): string {
    return applicationEnvironment.companyInfo.logoPath;
  }

  public get header(): string {
    return applicationEnvironment.companyInfo.headerText;
  }

  public get info(): string {
    return applicationEnvironment.companyInfo.infoText;
  }

  //#endregion
}
