import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { AttributeValue } from 'src/common/webapi/contracts/attributes/values/attributeValue';

export class ItemBuilderContext {
  //#region -- fields --

  private _definition: AttributeDefinitionBase;
  private _value: AttributeValue;

  //#endregion

  //#region -- constructor --

  constructor(
    definition: AttributeDefinitionBase,
    value: AttributeValue
  ) {
    this._definition = definition;
    this._value = value;
  }

  //#endregion

  //#region -- methods --

  public definition = <TDefinition extends AttributeDefinitionBase>(): TDefinition =>
    <TDefinition>this._definition;

  public value = <TValue>(): TValue =>
    <TValue>this._value.value;

  //#endregion
}
