import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TextAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/textAttributeDefinition';
import { EditorBaseComponent } from '../editor.base.component';

@Component({
  selector: 'clevermailing-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextEditorComponent
  extends EditorBaseComponent<TextAttributeDefinition, string, string>{
  //#region -- constructor  --

  constructor() {
    super(
      (value: string) => value,
      (value: string) => value
    );
  }

  //#endregion
}
