import { ApiEndpointConfiguration } from 'src/common/models/apiEndpointConfiguration';
import { InterestedPartyApiEndpointConfiguration } from 'src/modules/interested-parties/models/interestedPartiesApiEndpointConfiguration';
import { TaskApiEndpointConfiguration } from 'src/modules/tasks/models/taskApiEndpointConfiguration';
import packageInfo from '../../package.json';
import { environment } from './environment';

export const applicationEnvironment = {
  version: packageInfo.version,
  name: packageInfo.name,
  contentGroups: {
    interestedparty: 'interestedparty',
    transaction: 'transaction',
    broadcast: 'broadcast',
    action: 'action',
    contact: 'contact',
    task: 'task',
    shippingSchedule: 'shippingSchedule',
    widgetTask: 'widgetTask',
    widgetNews: 'widgetNews',
    userSettings: 'userSettings',
    company: 'company',
    companyAlternatives: 'companyAlternatives',
    accountability: 'accountability',
    signature: 'signature'
  },
  api: {
    base: environment.api.base,
    endpoints: {
      interestedparty: <InterestedPartyApiEndpointConfiguration>{
        urlItem: 'interestedparty',
        urlItems: 'interestedparties',
        urlForShippingSchedule: 'interestedparty/shippingschedule/{id}'
      },
      transaction: <ApiEndpointConfiguration>{
        urlItem: 'businesstransaction',
        urlItems: 'businesstransactions'
      },
      broadcast: <ApiEndpointConfiguration>{
        urlItem: 'broadcast',
        urlItems: 'broadcasts'
      },
      action: <ApiEndpointConfiguration>{
        urlItem: 'action',
        urlItems: 'actions'
      },
      contact: <ApiEndpointConfiguration>{
        urlItem: 'contactperson',
        urlItems: 'contactpersons'
      },
      document: <ApiEndpointConfiguration>{
        urlItem: 'document'
      },
      task: <TaskApiEndpointConfiguration>{
        urlItem: 'task',
        urlItems: 'tasks',
        urlForRevoke: 'task/{id}/state/revoke',
        urlForComplete: 'task/{id}/state/complete'
      },
      shippingSchedule: <ApiEndpointConfiguration>{
        urlItem: 'shippingschedule',
        urlItems: 'shippingschedules'
      },
      widgetTask: <ApiEndpointConfiguration>{
        urlItem: 'widget/task',
        urlItems: 'widget/tasks'
      },
      widgetNews: <ApiEndpointConfiguration>{
        urlItem: 'widget/news',
        urlItems: 'widget/news'
      },
      userSettings: <ApiEndpointConfiguration>{
        urlItem: 'user/setting',
        urlItems: 'user/setting'
      },
      company: <ApiEndpointConfiguration>{
        urlItem: 'company',
        urlItems: 'company'
      },
      companyAlternatives: <ApiEndpointConfiguration>{
        urlItem: 'companyalternative',
        urlItems: 'companyalternatives'
      },
      accountability: <ApiEndpointConfiguration>{
        urlItem: 'accountability',
        urlItems: 'accountabilities'
      },
      signature: <ApiEndpointConfiguration>{
        urlItem: 'signature'
      }
    }
  },
  translation: {
    localeId: 'de-DE',
    pathToFiles: './assets/i18n/',
    fileExtension: '.json',
    rootPrefix: 'ROOT.',
    defaultLanguage: 'de-DE'
  },
  dialogs: {
    default: {
      minWidth: 500,
      maxWidth: 500,
      minHeight: 700,
      maxHeight: 700
    },
    userSettings: {
      minWidth: 700,
      maxWidth: 700,
      minHeight: 185,
      maxHeight: 185
    },
    impersonisationSettings: {
      minWidth: 700,
      maxWidth: 700,
      minHeight: 245,
      maxHeight: 320
    },
    editor: {
      minWidth: 692,
      maxWidth: 1400,
      minHeight: 100,
      maxHeight: 1280
    },
    transaction: {
      minWidth: 1000,
      maxWidth: 1200,
      minHeight: 600,
      maxHeight: 1000
    },
    broadcast: {
      minWidth: 1280,
      maxWidth: 1280,
      minHeight: 800,
      maxHeight: 800
    },
    configurator: {
      minWidth: 800,
      maxWidth: 1200,
      minHeight: 600,
      maxHeight: 1000
    },
    pdf: {
      minWidth: '80%',
      maxWidth: '80%',
      minHeight: '95%',
      maxHeight: '95%',
    },
    newsView: {
      minWidth: '60%',
      maxWidth: '60%',
      minHeight: '40%',
      maxHeight: '40%',
    }
  },
  pageing: {
    virtual: 100,
    rowHeight: 35
  },
  defaults: {
    identifier: {
      forNewItemPath: 'new',
      forNewItemId: 0,
      forId: 'id'
    },
    marker: {
      forRequired: '*'
    }
  },
  search: {
    minSearchLength: 3,
    debounceTime: 200
  },
  companyInfo: {
    logoPath: '/assets/Skoda_Wordmark_RGB_Black.svg',
    headerText: '',
    infoText: ''
  },
  companyInfoOverview: {
    maxNumberOfContacts: 1
  },
  login: {
    urlWhenLoggedIn: '/home'
  },
  notification: {
    hideAfter: 5000,
    fadeDuration: 400
  },
  mailingConfiguration: {
    url: environment.mailingConfiguration.url
  }
};
