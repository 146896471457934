/* eslint-disable no-shadow */
export enum FilterTypes {
  None = 0,
  ValueFilter,
  NumericValueFilter,
  ValuesFilter,
  DateRangeFilter,
  NumericRangeFilter,
  CheckboxFilter
}
