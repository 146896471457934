import { LinkBase } from './link.base';

export class TextLink
  extends LinkBase {
  //#region -- fields --

  public text: string;

  //#endregion
}
