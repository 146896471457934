import { KeyValue } from '@angular/common';
import { List } from 'linqts';

export {
  isNullOrUndefined,
  isNotNullOrUndefined,
  isNullOrUndefinedOrEmpty,
  isNotNullOrUndefinedOrEmpty,
  getAsKeyValue
};

const isNullOrUndefined = (object: any): boolean => object === null || object === undefined;

const isNotNullOrUndefined = (object: any): boolean => !isNullOrUndefined(object);

const isNullOrUndefinedOrEmpty = (value: string): boolean => isNullOrUndefined(value) || value.length === 0;

const isNotNullOrUndefinedOrEmpty = (value: string): boolean => !isNullOrUndefinedOrEmpty(value);

const getAsKeyValue = (objectList: any[]): KeyValue<string, string>[] => {
  const result = new List<KeyValue<string, string>>();

  for (const [key, value] of Object.entries(objectList)) {
    result.Add(<KeyValue<string, string>>{
      key: <string>key,
      value: <string>value
    });
  }

  return result.ToArray();
};
