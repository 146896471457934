  <div class="transaction">
    <clevermailing-view-title [title]="'MODULES.INTERESTED_PARTIES.TRANSACTION.TITLE' | translate:{displayName:displayName}"
                              [notificationCount]="notificationCount">
      <clevermailing-global-search class="search"
                                   [(searchPhrase)]="searchPhrase"></clevermailing-global-search>
      <div class="commands">
        <clevermailing-command-button *ngFor="let command of viewCommands"
                                      [command]="command"></clevermailing-command-button>
      </div>
    </clevermailing-view-title>
    <clevermailing-view-content>
      <div class="grid">
        <clevermailing-simple-grid #transactionGrid
                                   [height]="500"
                                   [contentGroup]="contentGroups.transaction"
                                   [gridCommands]="gridCommands"
                                   [customFilters]="customFilters"
                                   [searchPhrase]="searchPhrase"
                                   [customPartialUpdateSettings]="customPartialUpdateSettings"
                                   (totalCountChanged)="onTotalCountChanged($event)"></clevermailing-simple-grid>
      </div>
      <div class="templates">
        <ng-template #columnSelector>
          <clevermailing-column-selector [dialogRef]="openDialogRef"
                                         [(visibleColumns)]="transactionGrid.visibleColumns">
          </clevermailing-column-selector>
        </ng-template>
        <ng-template #info>
          <clevermailing-detail [dialogRef]="openDialogRef"
                                [definition]="infoDefinition"
                                [resource]="infoResource"
                                [isInfo]="true"></clevermailing-detail>
        </ng-template>
      </div>
    </clevermailing-view-content>
  </div>