/* eslint-disable no-bitwise */
import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Mapper } from '@automapper/types';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { List } from 'linqts';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MAPPER } from 'src/common/token/tokens';
import { AttributeGroup } from 'src/common/webapi/contracts/attributes/attributeGroup';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { ComboboxAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/comboboxAttributeDefinition';
import { OptionBase } from 'src/common/webapi/contracts/attributes/options/bases/optionBase';
import { Option } from 'src/common/webapi/contracts/attributes/options/option';
import { ClientInfo } from 'src/common/webapi/contracts/clientInfo';
import { ClientUser } from 'src/common/webapi/contracts/clientUser';
import { AttributeAccessibilities } from 'src/common/webapi/contracts/enum/attributeAccessibilities';
import { AttributeTypes } from 'src/common/webapi/contracts/enum/attributeTypes';
import { OptionTypes } from 'src/common/webapi/contracts/enum/optionTypes';
import { Resource } from 'src/common/webapi/contracts/resource';
import { ResourceDefinition } from 'src/common/webapi/contracts/resourceDefinition';
import { SubscriptionBase } from 'src/shared/base/subscription.base';
import { EditorItem } from 'src/shared/components/editor/models/editorItem';
import { ViewCommand } from 'src/shared/components/grid/models/command/viewCommand';
import { isNotNullOrUndefined, isNullOrUndefined } from 'src/shared/helper/object.helper';
import { ImpersonationService } from 'src/shared/services/impersonation.service';

@Component({
  selector: 'clevermailing-impersonation-settings',
  templateUrl: './impersonation-settings.component.html',
  styleUrls: ['./impersonation-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImpersonationSettingsComponent
  extends SubscriptionBase
  implements OnInit, OnDestroy {
  //#region -- configuration --

  private static readonly IdentifierForClientDefinition: string = 'clients';
  private static readonly IdentifierForUserDefinition: string = 'users';

  //#endregion

  //#region -- commands --

  private readonly _commands: ViewCommand<any>[] =
    [
      new ViewCommand('IMPERSONATION_SETTINGS.COMMANDS.ACCEPT', 'fa-solid fa-check', '', () => this._editorItemSource.value?.canSave, () => this.applySettings())
    ];

  //#endregion

  //#region -- fields --

  private readonly _editorItemSource: BehaviorSubject<EditorItem>;
  private readonly _impersonationService: ImpersonationService;
  private readonly _mapper: Mapper;

  private _dialogRef: DialogRef;
  private _item: EditorItem;
  private _clientOptions: OptionBase[];
  private _userOptions: OptionBase[];
  private _subscription: Subscription;
  private _selectedUser: any;
  private _resource: Resource;


  //#endregion

  //#region -- properties --

  @Input()
  public set dialogRef(value: DialogRef) {
    this._dialogRef = value;
  }

  public get dialogRef(): DialogRef {
    return this._dialogRef;
  }

  public get editorItem(): Observable<EditorItem> {
    return this._editorItemSource.asObservable();
  }

  public get commands(): ViewCommand<any>[] {
    return this._commands;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    impersonationService: ImpersonationService,
    @Inject(MAPPER) mapper: Mapper
  ) {
    super();

    this._impersonationService = impersonationService;
    this._mapper = mapper;

    this._editorItemSource = new BehaviorSubject<EditorItem>(undefined);
    this._resource = new Resource();
  }

  //#endregion

  //#region -- methods --

  ngOnInit(): void {
    this.addSubscriptions(
      [
        this._impersonationService
          .clientsChanged
          .subscribe(clients => {
            this._clientOptions = new List(clients)
              .Select(client => this._mapper.map(client, Option, ClientInfo))
              .ToArray();

            const resourceDefinition = this.buildResourceDefinition(
              [
                this.buildClient(this._clientOptions),
                this.buildUserClosed()
              ]);
            this.updateItem(new EditorItem(resourceDefinition, this._resource));
          }),

        this._impersonationService
          .usersChanged
          .subscribe(users => {
            this._userOptions = new List(users)
              .Select(user => this._mapper.map(user, Option, ClientUser))
              .ToArray();

            const resourceDefinition = this.buildResourceDefinition(
              [
                this.buildClient(this._clientOptions),
                this.buildUserOpen(this._userOptions)
              ]);
            this.updateItem(new EditorItem(resourceDefinition, this._item.getRessource()));
          })
      ]);

    this._impersonationService.fetchClients();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
    this._subscription.unsubscribe();
    this._dialogRef?.close();
  }

  private updateItem = (item: EditorItem): void => {
    this._item = item;
    this._subscription = this._item.onAttributeChanged
      .subscribe(definition => this.handleAttributeChanged(definition));

    this._editorItemSource.next(item);
  };

  private handleAttributeChanged = (definition: AttributeDefinitionBase): void => {
    if (definition.key === ImpersonationSettingsComponent.IdentifierForClientDefinition) {
      this._selectedUser = undefined;

      if (isNullOrUndefined(this._item.getEditorAttribute(definition)?.value)) {
        const resourceDefinition = this.buildResourceDefinition(
          [
            this.buildClient(this._clientOptions),
            this.buildUserClosed()
          ]);
        this.updateItem(new EditorItem(resourceDefinition, this._item.getRessource()));
      } else
        this._impersonationService.fetchUsersForClient(this._item.getEditorAttribute(definition)?.value);
    }

    if (definition.key === ImpersonationSettingsComponent.IdentifierForUserDefinition)
      this._selectedUser = this._item.getEditorAttribute(definition)?.value;
  };

  private applySettings = (): void => {
    if (isNotNullOrUndefined(this._selectedUser)) {
      this._impersonationService
        .impersonate(this._selectedUser);
    }

    this._dialogRef.close();
  };

  // #region -- build definitions --

  private buildClient = (options: OptionBase[]): AttributeDefinitionBase =>
    this.buildDefinition(ImpersonationSettingsComponent.IdentifierForClientDefinition, 'IMPERSONATION_SETTINGS.DEFINITIONS.BASES.CLIENT', AttributeAccessibilities.EditorModify | AttributeAccessibilities.EditorRequired, options);

  private buildUserClosed = (): AttributeDefinitionBase =>
    this.buildDefinition(ImpersonationSettingsComponent.IdentifierForUserDefinition, 'IMPERSONATION_SETTINGS.DEFINITIONS.BASES.USER', AttributeAccessibilities.EditorModify | AttributeAccessibilities.EditorReadOnly, []);

  private buildUserOpen = (options: OptionBase[]): AttributeDefinitionBase =>
    this.buildDefinition(ImpersonationSettingsComponent.IdentifierForUserDefinition, 'IMPERSONATION_SETTINGS.DEFINITIONS.BASES.USER', AttributeAccessibilities.EditorModify | AttributeAccessibilities.EditorRequired, options);

  private buildDefinition = (key: string, name: string, accessibilities: AttributeAccessibilities, options: OptionBase[]): ComboboxAttributeDefinition =>
    <ComboboxAttributeDefinition>{
      order: 0,
      key: key,
      name: name,
      type: AttributeTypes.Combobox,
      isMultiSelect: false,
      valueType: OptionTypes.Text,
      accessibility: accessibilities,
      options: options
    };

  private buildResourceDefinition = (identityDefinitions: AttributeDefinitionBase[]): ResourceDefinition =>
    <ResourceDefinition>{
      groups: <AttributeGroup[]>
        [
          <AttributeGroup>{
            name: 'IMPERSONATION_SETTINGS.DEFINITIONS.GROUPS.IDENTITY',
            order: 0,
            attributes: identityDefinitions
          }
        ]
    };

  //#endregion

  //#endregion
}
