import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconLink } from '../../models/links/iconLink';
import { ItemBaseComponent } from '../item.base.component';

@Component({
  selector: 'clevermailing-icon-link-item',
  templateUrl: './icon-link-item.component.html',
  styleUrls: ['./icon-link-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconLinkItemComponent
  extends ItemBaseComponent<IconLink> { }
