/* eslint-disable no-bitwise */
import { List } from 'linqts';
import { EnumHelper } from 'src/shared/helper/enum.helper';
import { AttributeDefinitionBase } from '../webapi/contracts/attributes/bases/attributeDefinitionBase';
import { AttributeAccessibilities } from '../webapi/contracts/enum/attributeAccessibilities';
import { ResourceDefinition } from '../webapi/contracts/resourceDefinition';

export class GridColumn {
  //#region -- fields --

  public title: string;
  public field: string;
  public definition: AttributeDefinitionBase;
  public isSelected: boolean;
  public isEditable: boolean;

  //#endregion

  //#region -- constructor --
  constructor() {
  }
  //#endregion

  //#region -- methods --

  public static getOrderedDefinitions = (definition: ResourceDefinition): List<AttributeDefinitionBase> =>
    new List(definition.groups)
      .OrderBy(group => group.order)
      .SelectMany(group => new List(group.attributes)
        // eslint-disable-next-line no-bitwise
        .Where(attribute => (attribute.accessibility & AttributeAccessibilities.Grid) === AttributeAccessibilities.Grid)
        .OrderBy(attribute => attribute.order)
        .ThenBy(attribute => attribute.name));

  public static getDefaultColumns = (columns: List<GridColumn>): List<GridColumn> =>
    columns.Where(column =>
      EnumHelper.matchFlag<AttributeAccessibilities>(column.definition.accessibility, AttributeAccessibilities.ColumnSelectorDefaultColumn)
      || EnumHelper.matchFlag<AttributeAccessibilities>(column.definition.accessibility, AttributeAccessibilities.ColumnSelectorRequiredColumn));

  //#endregion
}
