import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DatetimeAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/datetimeAttributeDefinition';
import { DateRangeFilter } from '../../models/filter/dateRangeFilter';
import { FilterBaseComponent } from '../filter.base.component';

@Component({
  selector: 'clevermailing-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeFilterComponent
  extends FilterBaseComponent<DatetimeAttributeDefinition, DateRangeFilter, { start: Date; end: Date }> { }
