<div class="group">
  <div class="title">
    <span>{{ name | translate }}</span>
  </div>
  <div *ngFor="let definition of attributeDefinitions"
       class="attributes">
    <clevermailing-label [editorAttribute]="getEditorAttribute(definition)"></clevermailing-label>
    <clevermailing-editor-selector [definition]="definition"
                                   [item]="item"></clevermailing-editor-selector>
  </div>
</div>