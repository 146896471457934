import { List } from 'linqts';
import { DateTime } from 'luxon';
import { CustomFilterBase } from './base/customFilter.base';

export class DateRangeFilter
  extends CustomFilterBase<{ start: Date; end: Date }> {
  //#region -- configruation --

  private static readonly DateFilterFormat: string = 'yyyy-MM-dd';
  private static readonly StartTime: string = '00:00:00.000000';
  private static readonly EndTime: string = '23:59:59.999999';

  //#endregion

  // #region -- constructor --

  public constructor(
    key: string,
    regex: string
  ) {
    super(key, regex, { start: new Date(), end: new Date() });
  }

  //#endregion

  //#region -- methods  --

  public isValid = (): boolean =>
    true;

  public toParams = (id: number): List<{ key: string; value: string }> =>
    new List<{ key: string; value: string }>(
      [
        { key: `filters${id}.by`, value: this.key },
        { key: `filters${id}.min`, value: this.formatFilterDate(this.model.start, DateRangeFilter.StartTime) },
        { key: `filters${id}.max`, value: this.formatFilterDate(this.model.end, DateRangeFilter.EndTime) }
      ]);

  private formatFilterDate = (date: Date, timeString: string): string =>
    `${DateTime.fromJSDate(date).toFormat(DateRangeFilter.DateFilterFormat)}T${timeString}Z`;

  //#endregion
}
