import { Injectable } from '@angular/core';
import { DialogResult, DialogService } from '@progress/kendo-angular-dialog';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { Observable } from 'rxjs';
import { QuestionInfo } from 'src/common/models/questionInfo';
import { QuestionDialogComponent } from 'src/components/question-dialog/question-dialog.component';
import { ToastHelperService } from './toast.helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserInteractionService {
  //#region -- fields --

  private readonly _notificationService: NotificationService;
  private readonly _dialogService: DialogService;
  private readonly _toastHelperService: ToastHelperService;

  //#endregion

  //#region -- constructor --

  public constructor(
    notificationService: NotificationService,
    dialogService: DialogService,
    toastHelperService: ToastHelperService
  ) {
    this._notificationService = notificationService;
    this._dialogService = dialogService;
    this._toastHelperService = toastHelperService;
  }

  //#endregion

  //#region -- methods --

  public showQuestionDialog = (title: string, message: string): Observable<DialogResult> => {
    const dialogRef = this._dialogService.open(
      {
        content: QuestionDialogComponent
      });

    dialogRef.content.instance.info = <QuestionInfo>{
      dialogRef: dialogRef,
      title: title,
      message: message
    };

    return dialogRef.result;
  };

  public showToastSuccess = (message: string): NotificationRef =>
    this._notificationService
      .show(this._toastHelperService
        .success(message));

  //#endregion
}


