import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserInfo } from 'src/common/webapi/contracts/authorization/userInfo';
import { Rights } from 'src/common/webapi/contracts/enum/rights';
import { LoopingHttpClient } from 'src/shared/http/loopingHttpClient';
import { EnumHelper } from '../helper/enum.helper';
import { isNotNullOrUndefined } from '../helper/object.helper';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  //#region -- configuration --

  private static readonly urlToCurrentUser: string = 'user/current';

  //#endregion

  //#region -- fields --

  private readonly _httpClient: LoopingHttpClient;
  private readonly _userInfoSource: Subject<UserInfo>;

  private _userInfo: UserInfo;
  private _mustImpersonate: boolean;

  //#endregion

  //#region -- properties --

  public get userInfoChanged(): Observable<UserInfo> {
    return this._userInfoSource.asObservable();
  }

  public get userInfo(): UserInfo {
    return this._userInfo;
  }

  public get isLoggedIn(): boolean {
    return isNotNullOrUndefined(this._userInfo);
  }

  public get mustImpersonate(): boolean {
    return this._mustImpersonate;
  }

  public get isAdmin(): boolean {
    return this._userInfo.isImpersonated;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    httpClient: LoopingHttpClient,
  ) {
    this._httpClient = httpClient;

    this._userInfoSource = new Subject<UserInfo>();
  }

  //#endregion

  //#region -- methods --

  public logout = (): void => {
    this.updateUserInfo(undefined);
  };

  public login = (): Subscription =>
    this.fetch();

  public fetch = (): Subscription =>
    this._httpClient
      .get<UserInfo>(UserService.urlToCurrentUser)
      .subscribe(userInfo => this.updateUserInfo(userInfo));

  private updateUserInfo = (userInfo: UserInfo): void => {
    this._userInfo = userInfo;
    this._mustImpersonate = EnumHelper.matchFlag<Rights>(userInfo?.rights, Rights.Impersonate);

    this._userInfoSource.next(this._userInfo);
  };

  //#endregion
}
