<div [ngClass]="{ 'not-valid': !isValid }">
  <kendo-multiselect #multiSelect
                     [data]="items"
                     [(ngModel)]="model"
                     [itemDisabled]="itemDisabled"
                     [valueField]="'value'"
                     [textField]="'text'"
                     [disabled]="isReadonly"
                     [valuePrimitive]="true">
    <ng-template kendoMultiSelectItemTemplate
                 let-dataItem
                 [ngSwitch]="type">
      <clevermailing-text-option-item *ngSwitchCase="viewItemTypes.TextOption"
                                      [model]="dataItem"></clevermailing-text-option-item>
      <clevermailing-icon-option-item *ngSwitchCase="viewItemTypes.IconOption"
                                      [model]="dataItem"></clevermailing-icon-option-item>
      <clevermailing-icon-text-option-item *ngSwitchCase="viewItemTypes.IconTextOption"
                                           [model]="dataItem"></clevermailing-icon-text-option-item>
    </ng-template>
    <ng-template kendoMultiSelectTagTemplate
                 let-dataItem
                 [ngSwitch]="type">
      <clevermailing-text-option-item *ngSwitchCase="viewItemTypes.TextOption"
                                      [model]="dataItem"></clevermailing-text-option-item>
      <clevermailing-icon-option-item *ngSwitchCase="viewItemTypes.IconOption"
                                      [model]="dataItem"></clevermailing-icon-option-item>
      <clevermailing-icon-text-option-item *ngSwitchCase="viewItemTypes.IconTextOption"
                                           [model]="dataItem"></clevermailing-icon-text-option-item>
    </ng-template>
  </kendo-multiselect>
</div>
