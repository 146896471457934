import { HttpParameterCodec } from '@angular/common/http';

export class keyValueHttpParamaterCodec
  implements HttpParameterCodec {
  //#region HttpParameterCodec

  public encodeKey = (key: string): string =>
    encodeURIComponent(key);

  public encodeValue = (value: string): string =>
    encodeURIComponent(value);

  public decodeKey = (key: string): string =>
    decodeURIComponent(key);

  public decodeValue = (value: string): string =>
    decodeURIComponent(value);

  //#endregion
}
