/* eslint-disable no-shadow */
export enum ResourceTypes {
    None = 0,
    Action,
    Accountability,
    BusinessTransaction,
    Campaign,
    Company,
    CompanyAlternative,
    ContactPerson,
    InterestedParty,
    News,
    Mailing,
    Task,

    SystemSettings,
    UserSettings
}
