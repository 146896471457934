import { Component, Input } from '@angular/core';
import { CommandBase } from '../grid/models/command/base/command.base';

@Component({
  selector: 'clevermailing-command-button',
  templateUrl: './command-button.component.html',
  styleUrls: ['./command-button.component.scss']
})
export class CommandButtonComponent {
  //#region -- fields --

  private _command: CommandBase<any>;
  private _model: any;

  //#endregion

  //#region -- properties --

  @Input()
  public set model(value: any) {
    this._model = value;
  }

  public get model(): any {
    return this._model;
  }

  @Input()
  public set command(value: CommandBase<any>) {
    this._command = value;
  }

  public get command(): CommandBase<any> {
    return this._command;
  }

  //#endregion

  //#region -- constructor --

  public constructor() {
    this._model = undefined;
  }

  //#endregion

  //#region -- methods --

  public onClick = (event: MouseEvent): void => {
    this.command.execute(this._model);

    event.stopPropagation();
    event.preventDefault();
  };

  //#endregion
}
