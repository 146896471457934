import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { List } from 'linqts';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { Resource } from 'src/common/webapi/contracts/resource';
import { ResourceDefinition } from 'src/common/webapi/contracts/resourceDefinition';
import { DialogResult } from 'src/components/models/dialogResult';
import { DialogResultState } from 'src/components/models/enums/dialogResultState';
import { SubscriptionBase } from 'src/shared/base/subscription.base';
import { EditorItem } from 'src/shared/components/editor/models/editorItem';
import { ViewCommand } from 'src/shared/components/grid/models/command/viewCommand';
import { isNullOrUndefined } from 'src/shared/helper/object.helper';
import { ItemService } from 'src/shared/services/item.service';

@Component({
  selector: 'clevermailing-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailComponent
  extends SubscriptionBase
  implements OnInit, OnDestroy {
  //#region -- commands --

  private readonly _commands: ViewCommand<any>[] =
    [
      new ViewCommand('EDITOR.COMMANDS.SAVE', 'fa-solid fa-check', '', () => this._itemSource.value?.canSave, () => this.saveItem())
    ];

  //#endregion

  //#region -- fields --

  private readonly _itemServices: List<ItemService>;

  private _definitionSource: BehaviorSubject<ResourceDefinition>;
  private _resourceSource: BehaviorSubject<Resource>;
  private _itemSource: BehaviorSubject<EditorItem>;

  private _title: string;
  private _dialogRef: DialogRef;
  private _contentGroup: string;
  private _isInfo: boolean;
  private _displayName: string;

  //#endregion

  //#region -- properties --

  @Input()
  public set dialogRef(value: DialogRef) {
    this._dialogRef = value;
  }

  public get dialogRef(): DialogRef {
    return this._dialogRef;
  }

  @Input()
  public set definition(value: ResourceDefinition) {
    this._definitionSource.next(value);
  }

  @Input()
  public set resource(value: Resource) {
    this._displayName = value.displayName;

    this._resourceSource.next(value);
  }

  @Input()
  public set contentGroup(value: string) {
    this._contentGroup = value;
  }

  @Input()
  public set isInfo(value: boolean) {
    this._isInfo = value;
  }

  public get itemSource(): Observable<EditorItem> {
    return this._itemSource.asObservable();
  }

  public get commands(): ViewCommand<any>[] {
    return !this._isInfo
      ? this._commands
      : [];
  }

  public get title(): string {
    return this._title;
  }

  public get displayName(): string {
    return this._displayName;
  }

  private get itemService(): ItemService {
    return isNullOrUndefined(this._contentGroup)
      ? this._itemServices.First()
      : this._itemServices.Single(service => service.responsibleFor === this._contentGroup);
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    @Inject(ItemService) itemServices: ItemService[]
  ) {
    super();

    this._itemServices = new List(itemServices);

    this._definitionSource = new BehaviorSubject<ResourceDefinition>(undefined);
    this._resourceSource = new BehaviorSubject<Resource>(undefined);
    this._itemSource = new BehaviorSubject<EditorItem>(undefined);

    this._isInfo = false;
  }

  //#endregion

  //#region -- methods --

  ngOnInit(): void {
    this.addSubscriptions(
      [
        merge(
          this._definitionSource,
          this._resourceSource
        ).subscribe(() => this.initializeItem())
      ]);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  private initializeItem = (): void => {
    if (isNullOrUndefined(this._definitionSource.value)
      || isNullOrUndefined(this._resourceSource.value))
      return;

    const item = new EditorItem(this._definitionSource.value, this._resourceSource.value);
    this._title = this.buildTitle(item);

    this._itemSource.next(item);
  };

  private saveItem = (): void => {
    (this._itemSource.value.isNew
      ? this.itemService.save(this._itemSource.value.getRessource())
      : this.itemService.update(this._itemSource.value.getRessource())
    ).subscribe(() => this._dialogRef.close(new DialogResult(DialogResultState.Success)));
  };

  private buildTitle = (item: EditorItem): string =>
    this._isInfo
      ? 'EDITOR.TITLE.INFO'
      : item.isNew
        ? 'EDITOR.TITLE.NEW'
        : 'EDITOR.TITLE.EDIT';

  //#endregion
}
