import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LinkAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/linkAttributeDefinition';
import { LinkTypesToViewTypesConverter } from '../../models/enums/converter/linkTypesToViewTypes.converter';
import { ViewItemTypes } from '../../models/enums/viewItemTypes';
import { ItemBuilderContext } from '../../models/itemBuilder/itemBuilderContext';
import { ItemBuilderFactory } from '../../models/itemBuilder/itemBuilderFactory';
import { LinkBase } from '../../models/links/link.base';
import { CellBaseComponent } from '../cell.base.component';

@Component({
  selector: 'clevermailing-link-cell',
  templateUrl: './link-cell.component.html',
  styleUrls: ['./link-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkCellComponent
  extends CellBaseComponent<LinkAttributeDefinition>{
  //#region -- export enum --

  viewItemTypes = ViewItemTypes;

  //#endregion

  //#region -- fields --

  private readonly _itemBuilderFactory: ItemBuilderFactory;

  private _type: ViewItemTypes;

  //#endregion

  //#region -- properties --

  public get type(): ViewItemTypes {
    return this._type;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    itemBuilderFactory: ItemBuilderFactory
  ) {
    super();

    this._itemBuilderFactory = itemBuilderFactory;
  }

  //#endregion

  //#region -- methods --

  public getValue = (): LinkBase => {
    this._type = LinkTypesToViewTypesConverter.convert(this.definition.valueType);

    return <LinkBase>this._itemBuilderFactory
      .create(this._type, new ItemBuilderContext(this.definition, this.attributeValue));
  };

  //#endregion
}
