import { List } from 'linqts';
import { LinkTypes } from 'src/common/webapi/contracts/enum/linkTypes';
import { ViewItemTypes } from '../viewItemTypes';

export class LinkTypesToViewTypesConverter {
  //#region -- methods --

  public static convert = (source: LinkTypes): ViewItemTypes =>
    new List(
      [
        { option: LinkTypes.Text, viewItemType: ViewItemTypes.TextLink },
        { option: LinkTypes.Icon, viewItemType: ViewItemTypes.IconLink },
      ]
    ).FirstOrDefault(x => x.option === source)?.viewItemType
    ?? ViewItemTypes.None;

    //#endregion
}
