import { Inject, Injectable } from '@angular/core';
import { Mapper } from '@automapper/types';
import { List } from 'linqts';
import { MAPPER } from 'src/common/token/tokens';
import { ViewItemTypes } from '../enums/viewItemTypes';
import { IconLink } from '../links/iconLink';
import { LinkBase } from '../links/link.base';
import { TextLink } from '../links/textLink';
import { IconOption } from '../options/iconOption';
import { IconTextOption } from '../options/iconTextOption';
import { OptionBase } from '../options/option.base';
import { TextOption } from '../options/textOption';
import { ItemBuilder } from './itemBuilder';
import { ItemBuilderContext } from './itemBuilderContext';
import { OptionBuilderContext } from './optionBuilderContext';

@Injectable({
  providedIn: 'root'
})
export class ItemBuilderFactory {
  //#region -- configuration --

  private static readonly ItemBuilderList: List<ItemBuilder<ViewItemTypes, LinkBase | OptionBase>>
    = new List<ItemBuilder<ViewItemTypes, LinkBase | OptionBase>>(
      [
        new ItemBuilder(ViewItemTypes.TextLink, (mapper, context) => mapper.map(context, TextLink, ItemBuilderContext)),
        new ItemBuilder(ViewItemTypes.IconLink, (mapper, context) => mapper.map(context, IconLink, ItemBuilderContext)),
        new ItemBuilder(ViewItemTypes.TextOption, (mapper, context) => mapper.map(context, TextOption, OptionBuilderContext)),
        new ItemBuilder(ViewItemTypes.IconOption, (mapper, context) => mapper.map(context, IconOption, OptionBuilderContext)),
        new ItemBuilder(ViewItemTypes.IconTextOption, (mapper, context) => mapper.map(context, IconTextOption, OptionBuilderContext)),
      ]);

  //#endregion

  //#region -- fields --

  private readonly _mapper: Mapper;

  //#endregion

  //#region -- constructor --

  public constructor(
    @Inject(MAPPER) mapper: Mapper
  ) {
    this._mapper = mapper;
  }

  //#endregion

  //#region -- methods --

  public create = (type: ViewItemTypes, context: ItemBuilderContext): LinkBase | OptionBase =>
    ItemBuilderFactory.ItemBuilderList
      .First(x => x.type === type)
      .build(this._mapper, context);

  //#endregion
}
