import { Component } from '@angular/core';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { DecimalNumberAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/decimalNumberAttributeDefinition';
import { TextAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/textAttributeDefinition';
import { isNotNullOrUndefined } from 'src/shared/helper/object.helper';
import { NumericValueFilter } from '../../models/filter/numericValueFilter';
import { FilterBaseComponent } from '../filter.base.component';

@Component({
  selector: 'clevermailing-numeric-value-filter',
  templateUrl: './numeric-value-filter.component.html',
  styleUrls: ['./numeric-value-filter.component.scss']
})
export class NumericValueFilterComponent
  extends FilterBaseComponent<TextAttributeDefinition, NumericValueFilter, number> {
  //#region -- properties --

  public get format(): NumberFormatOptions {
    const fractionDigits = isNotNullOrUndefined((<DecimalNumberAttributeDefinition>this.definition)?.precision)
      ? (<DecimalNumberAttributeDefinition>this.definition)?.precision?.fractional
      : 0;

    return <NumberFormatOptions>{
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits
    };
  }

  //#endregion
}
