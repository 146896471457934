import { CommandBase } from './base/command.base';

export class ViewCommand<TModel>
  extends CommandBase<TModel> {
  //#region -- fields --

  private _commandModel: TModel;

  //#endregion

  //#region -- constructor --

  public constructor(
    tooltip: string,
    icon: string,
    text: string,
    canExecute: (model: TModel) => boolean,
    execute: (model: TModel) => void,
    commandModel?: TModel
  ) {
    super(tooltip, icon, text, canExecute, execute);

    this._commandModel = commandModel;
  }

  //#endregion

  //#region -- methods --

  public canExecute = (): boolean =>
    this._canExecute(this._commandModel);

  public execute = (): void =>
    this._execute(this._commandModel);

  //#endregion
}

