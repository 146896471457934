import { convertUsing, mapFrom } from '@automapper/core';
import { GridSortDescriptor } from '../gridSortDescriptor';
import { SearchCriteria } from '../searchCriteria';
import { directionToSortingDirectionConverter } from './converter/sortDirection.converter';
import { SortingDirections } from '../enums/sortingDirections';

export const gridSortDescriptorProfile = (mapper: any) => {
  mapper.createMap(GridSortDescriptor, SearchCriteria)
    .forMember((destination: { sortBy: string }) => destination.sortBy, mapFrom(source => source.field))
    .forMember((destination: { sortDirection: SortingDirections }) => destination.sortDirection, convertUsing(directionToSortingDirectionConverter, source => source.dir));
};
