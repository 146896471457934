import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridColumn } from 'src/common/models/gridColumn';
import { SearchResult } from 'src/common/models/searchResult';
import { TransactionSearchCriteria } from 'src/common/models/transactionSearchCriteria';
import { AttributeValue } from 'src/common/webapi/contracts/attributes/values/attributeValue';
import { ResourceTypes } from 'src/common/webapi/contracts/enum/resourceTypes';
import { Resource } from 'src/common/webapi/contracts/resource';
import { ResourceDefinition } from 'src/common/webapi/contracts/resourceDefinition';
import { DialogResult } from 'src/components/models/dialogResult';
import { DialogResultState } from 'src/components/models/enums/dialogResultState';
import { applicationEnvironment } from 'src/environments/application.environment';
import { SubscriptionBase } from 'src/shared/base/subscription.base';
import { ColumnSelectorComponent } from 'src/shared/components/column-selector/column-selector.component';
import { GridCommand } from 'src/shared/components/grid/models/command/gridCommand';
import { ResourceCommandModel } from 'src/shared/components/grid/models/command/resourceCommandModel';
import { ViewCommand } from 'src/shared/components/grid/models/command/viewCommand';
import { CustomFilterBase } from 'src/shared/components/grid/models/filter/base/customFilter.base';
import { ParameterFilter } from 'src/shared/components/grid/models/filter/parameterFilter';
import { DialogHelper } from 'src/shared/helper/dialog.helper';
import { GridService } from 'src/shared/services/grid.service';
import { SimpleGridComponent } from '../simple-grid/simple-grid.component';

@Component({
  selector: 'clevermailing-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionComponent
  extends SubscriptionBase
  implements OnInit {
  //#region -- exports --

  contentGroups: any = applicationEnvironment.contentGroups;

  //#endregion

  //#region -- commands --

  private readonly _viewCommands: ViewCommand<any>[] =
    [
      new ViewCommand('VIEW.COMMANDS.COLUMN_SELECTOR', 'fa-solid fa-columns', '', () => this.canOpenColumnSelector(), () => this.openColumnSelector()),
      new ViewCommand('DIALOG_BASE.COMMANDS.CLOSE', 'fa-solid fa-times', '', () => true, () => this.closeDialog())
    ];

  private _gridCommands: GridCommand[] =
    [
      new GridCommand('MODULES.INTERESTED_PARTIES.TRANSACTION.COMMANDS.INFO', 'fa-solid fa-info-circle', '', () => true, model => this.openInfoOverlay(model))
    ];

  //#endregion

  //#region -- fields --

  private readonly _dataSource: BehaviorSubject<SearchResult<Resource>>;

  private _dialogRef: any;
  private _resource: Resource;
  private _definition: ResourceDefinition;
  private _visibleColumnsSource: BehaviorSubject<any[]>;
  private _gridService: GridService;
  private _displayName: string;
  private _openDialogRef: DialogRef;
  private _dialogService: DialogService;
  private _infoTemplate: TemplateRef<any>;
  private _transactionDetailId: number;
  private _notificationCount: number;
  private _searchPhrase: string;
  private _columnSelectorTemplate: TemplateRef<ColumnSelectorComponent>;
  private _infoDefinition: ResourceDefinition;
  private _infoResource: Resource;
  private _customPartialUpdateSettings: AttributeValue[];
  private _customFilters: CustomFilterBase<any>[];
  private _transactionGrid: SimpleGridComponent;

  //#endregion

  //#region -- properties --

  @ViewChild('transactionGrid')
  public set transactionGrid(value: SimpleGridComponent) {
    this._transactionGrid = value;
  }

  @ViewChild('info')
  public set infoTemplate(value: TemplateRef<any>) {
    this._infoTemplate = value;
  }

  @ViewChild('columnSelector')
  public set columnSelectorTemplate(value: TemplateRef<ColumnSelectorComponent>) {
    this._columnSelectorTemplate = value;
  }

  @Input()
  public set dialogRef(value: DialogRef) {
    this._dialogRef = value;
  }

  public get dialogRef(): DialogRef {
    return this._dialogRef;
  }

  @Input()
  public set resource(value: Resource) {
    this._resource = value;

    this._displayName = value.displayName;
  }

  public get customFilters(): CustomFilterBase<any>[] {
    return this._customFilters;
  }

  public get definition(): ResourceDefinition {
    return this._definition;
  }

  public get transactionDetailId(): number {
    return this._transactionDetailId;
  }

  public get data(): Observable<SearchResult<Resource>> {
    return this._dataSource.asObservable();
  }

  public get visibleGridColumns(): Observable<GridColumn[]> {
    return this._visibleColumnsSource.asObservable();
  }

  public get showSelection(): boolean {
    return false;
  }

  public get viewCommands(): ViewCommand<any>[] {
    return this._viewCommands;
  }

  public get gridCommands(): GridCommand[] {
    return this._gridCommands;
  }

  public get isLoading(): boolean {
    return this._gridService?.isLoading;
  }

  public get displayName(): string {
    return this._displayName;
  }

  public get openDialogRef(): DialogRef {
    return this._openDialogRef;
  }

  public get notificationCount(): number {
    return this._notificationCount;
  }

  public set searchPhrase(value: string) {
    this._searchPhrase = value;
  }

  public get searchPhrase(): string {
    return this._searchPhrase;
  }

  public get infoDefinition(): ResourceDefinition {
    return this._infoDefinition;
  }

  public get infoResource(): Resource {
    return this._infoResource;
  }

  public get customPartialUpdateSettings(): any {
    return this._customPartialUpdateSettings;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    dialogService: DialogService,
  ) {
    super();

    this._dialogService = dialogService;

    this._visibleColumnsSource = new BehaviorSubject<GridColumn[]>([]);
    this._dataSource = new BehaviorSubject<SearchResult<Resource>>(<SearchResult<Resource>>{});
    this._customPartialUpdateSettings = [];
  }

  //#endregion

  //#region -- methods --

  public ngOnInit(): void {
    this._customFilters = [
      new ParameterFilter(
        `${ResourceTypes[this._resource.type]?.toLowerCase()}id`,
        `${this._resource.id}`)
    ];

    this._customPartialUpdateSettings = [
      <AttributeValue>{
        definitionKey: `${ResourceTypes[this._resource.type]?.toLowerCase()}id`,
        value: this._resource.id
      }
    ];
  }

  public onTotalCountChanged = (totalCount: number): number =>
    this._notificationCount = totalCount;

  public onSearchCriteriasChanged = (criteria: TransactionSearchCriteria): void => {
    criteria.interestedPartyId = this._resource.id;   // todo: nope, this should also allow for mailingId

    this._gridService.fetch(criteria);
  };

  private openInfoOverlay = (model: ResourceCommandModel): void => {
    this._infoDefinition = model.definition;
    this._infoResource = model.resource;

    this._openDialogRef = this._dialogService
      .open(DialogHelper.editorDialogSettings(this._infoTemplate));
  };

  private canOpenColumnSelector = (): boolean =>
    this._transactionGrid?.visibleColumns?.visibleColumns?.length > 0;

  private openColumnSelector = (): DialogRef =>
    this._openDialogRef = this._dialogService
      .open(DialogHelper.templateDialogSettings(this._columnSelectorTemplate));

  private closeDialog = (): void =>
    this._dialogRef.close(new DialogResult(DialogResultState.Closed));

  //#endregion
}
