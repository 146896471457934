import { LoadingIndicatorBase } from '../base/loading-indicator.base';

export class ResponsibleServiceBase
  extends LoadingIndicatorBase {
  //#region -- fields --

  private _responsibleFor: string;

  //#endregion

  //#region -- properties --

  public get responsibleFor(): string {
    return this._responsibleFor;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    responsibleFor: string = 'default'
  ) {
    super();

    this._responsibleFor = responsibleFor;
  }

  //#endregion
}
