/* eslint-disable no-bitwise */
import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { List } from 'linqts';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Resource } from 'src/common/webapi/contracts/resource';
import { ResourceDefinition } from 'src/common/webapi/contracts/resourceDefinition';
import { applicationEnvironment } from 'src/environments/application.environment';
import { SubscriptionBase } from 'src/shared/base/subscription.base';
import { EditorItem } from 'src/shared/components/editor/models/editorItem';
import { ViewCommand } from 'src/shared/components/grid/models/command/viewCommand';
import { isNullOrUndefined } from 'src/shared/helper/object.helper';
import { ItemService } from 'src/shared/services/item.service';
import { UserService } from 'src/shared/services/user.service';
import { UserInteractionService } from 'src/shared/services/userinteraction.service';
import { UserSettingsItemService } from './services/user-settings.item.service';

@Component({
  selector: 'clevermailing-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSettingsComponent
  extends SubscriptionBase
  implements OnInit, OnDestroy {
  //#region -- commands --

  private readonly _commands: ViewCommand<any>[] =
    [
      new ViewCommand('USER_SETTINGS.COMMANDS.ACCEPT', 'fa-solid fa-check', '', () => this._editorItemSource.value?.canSave, () => this.applySettings()),
    ];

  //#endregion

  //#region -- fields --

  private readonly _editorItemSource: BehaviorSubject<EditorItem>;
  private readonly _itemServices: List<ItemService>;
  private readonly _userInteractionService: UserInteractionService;
  private readonly _userService: UserService;

  private _dialogRef: DialogRef;
  private _definition: ResourceDefinition;
  private _resource: Resource;

  //#endregion

  //#region -- fields --

  @Input()
  public set dialogRef(value: DialogRef) {
    this._dialogRef = value;
  }

  public get dialogRef(): DialogRef {
    return this._dialogRef;
  }

  public get editorItem(): Observable<EditorItem> {
    return this._editorItemSource.asObservable();
  }

  public get commands(): ViewCommand<any>[] {
    return this._commands;
  }

  private get itemService(): UserSettingsItemService {
    return <UserSettingsItemService>this._itemServices
      .Single(service => service.responsibleFor === applicationEnvironment.contentGroups.userSettings);
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    userInteractionService: UserInteractionService,
    userService: UserService,
    @Inject(ItemService) itemServices: ItemService[]
  ) {
    super();

    this._userInteractionService = userInteractionService;
    this._userService = userService;
    this._itemServices = new List(itemServices);

    this._editorItemSource = new BehaviorSubject<EditorItem>(undefined);
  }

  //#endregion

  //#region -- methods --

  ngOnInit(): void {
    this.addSubscriptions(
      [
        this.itemService
          .definition
          .subscribe(definition => {
            this._definition = definition;

            this.initializeItem();
          }),

        this.itemService
          .resource
          .subscribe(resource => {
            this._resource = resource;

            this.initializeItem();
          })
      ]);

    this.itemService.fetchDefinitions();
    this.itemService.fetch();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  private applySettings = (): void => {
    this.itemService
      .update(this._editorItemSource.value.getRessource())
      .subscribe(() => {
        this._userService.userInfoChanged
            .pipe(first())
            .subscribe(() => this._userInteractionService.showToastSuccess('USER_SETTINGS.MESSAGES.UPDATE_OK'));
        this._userService.fetch();

        this._dialogRef.close();
      });
  };

  private initializeItem = (): void => {
    if (isNullOrUndefined(this._definition) || isNullOrUndefined(this._resource))
      return;

    this._editorItemSource.next(new EditorItem(this._definition, this._resource));
  };

  //#endregion
}
