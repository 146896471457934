/* eslint-disable guard-for-in */
/* eslint-disable prefer-arrow-functions/prefer-arrow-functions */
export class JsonHelper {
  public static resolveReferences = (json: string): any => {
    // https://stackoverflow.com/questions/15312529/resolve-circular-references-from-json-object

    if (typeof json === 'string')
      json = JSON.parse(json);

    const byId: any = {}; // all objects by id
    const refs: any[] = []; // references to objects that could not be resolved

    json = (function recurse(obj: any | any[], prop: number | any, parent: any) {
      if (typeof obj !== 'object' || !obj)
        return obj; // a primitive value

      if (Object.prototype.toString.call(obj) === '[object Array]') {
        for (let i = 0; i < (<any[]>obj).length; i++) {
          // check also if the array element is not a primitive value
          if (typeof obj[i] !== 'object' || !obj[i])
            continue;   // a primitive value
          else if ('$ref' in obj[i])
            (<any>obj[i]) = recurse(obj[i], i, obj);
          else
            (<any>obj[i]) = recurse(obj[i], prop, obj);
        }
        return obj;
      }

      if ('$ref' in obj) {  // a reference
        const ref = (<any>obj).$ref;
        if (ref in byId)
          return byId[ref];

        refs.push([parent, prop, ref]);   // else we have to make it lazy:
        return;
      } else if ('$id' in obj) {
        const id = (<any>obj).$id;
        delete (<any>obj).$id;
        if ('$values' in obj)        // an array
          obj = (<any>obj).$values.map(recurse);
        else {
          for (const prop2 in (<any>obj)) {    // a plain object
            (<any>obj[prop2]) = recurse(obj[prop2], prop2, obj);
          }
        }
        byId[id] = obj;
      }
      return obj;
    })(json, undefined, undefined); // run it!

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < refs.length; i++) {
      // resolve previously unknown references
      const ref = refs[i];
      ref[0][ref[1]] = byId[ref[2]];
      // Notice that this throws if you put in a reference at top-level
    }
    return json;
  };
}
