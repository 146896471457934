import { OptionBase } from './option.base';

export class IconOption
  extends OptionBase {
  //#region -- fields --

  public icon: string;
  public hexColor: string;
  public tooltip: string;

  //#endregion
}
