import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NgxMasonryModule } from 'ngx-masonry';
import { applicationEnvironment } from 'src/environments/application.environment';
import { ColumnSelectorComponent } from './components/column-selector/column-selector.component';
import { CommandButtonComponent } from './components/command-button/command-button.component';
import { EditorSelectorComponent } from './components/editor/editor-selector/editor-selector.component';
import { EditorComponent } from './components/editor/editor.component';
import { CheckboxEditorComponent } from './components/editor/editors/checkbox-editor/checkbox-editor.component';
import { ComboboxEditorComponent } from './components/editor/editors/combobox-editor/combobox-editor.component';
import { DatetimeEditorComponent } from './components/editor/editors/datetime-editor/datetime-editor.component';
import { NumberEditorComponent } from './components/editor/editors/number-editor/number-editor.component';
import { TextEditorComponent } from './components/editor/editors/text-editor/text-editor.component';
import { GroupComponent } from './components/editor/group/group.component';
import { LabelComponent } from './components/editor/label/label.component';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { CellSelectorComponent } from './components/grid/cells/cell-selector/cell-selector.component';
import { CheckboxCellComponent } from './components/grid/cells/checkbox-cell/checkbox-cell.component';
import { ComboboxCellComponent } from './components/grid/cells/combobox-cell/combobox-cell.component';
import { DatetimeCellComponent } from './components/grid/cells/datetime-cell/datetime-cell.component';
import { LinkCellComponent } from './components/grid/cells/link-cell/link-cell.component';
import { NumberCellComponent } from './components/grid/cells/number-cell/number-cell.component';
import { TextCellComponent } from './components/grid/cells/text-cell/text-cell.component';
import { CommandCellComponent } from './components/grid/command-cell/command-cell.component';
import { CheckboxFilterComponent } from './components/grid/filter/checkbox-filter/checkbox-filter.component';
import { DateRangeFilterComponent } from './components/grid/filter/date-range-filter/date-range-filter.component';
import { FilterSelectorComponent } from './components/grid/filter/filter-selector/filter-selector.component';
import { NumericRangeFilterComponent } from './components/grid/filter/numeric-range-filter/numeric-range-filter.component';
import { NumericValueFilterComponent } from './components/grid/filter/numeric-value-filter/numeric-value-filter.component';
import { ValueFilterComponent } from './components/grid/filter/value-filter/value-filter.component';
import { ValuesFilterComponent } from './components/grid/filter/values-filter/values-filter.component';
import { GridComponent } from './components/grid/grid.component';
import { HeaderComponent } from './components/grid/header/header.component';
import { IconLinkItemComponent } from './components/grid/items/icon-link-item/icon-link-item.component';
import { IconOptionItemComponent } from './components/grid/items/icon-option-item/icon-option-item.component';
import { IconTextOptionItemComponent } from './components/grid/items/icon-text-option-item/icon-text-option-item.component';
import { TextLinkItemComponent } from './components/grid/items/text-link-item/text-link-item.component';
import { TextOptionItemComponent } from './components/grid/items/text-option-item/text-option-item.component';
import { CacheProvider } from './components/grid/provider/cache.provider';
import { LoopingHttpClient } from './http/loopingHttpClient';
import { QueryStringBuilderRuleBase } from './http/queryStringBuilderRules/base/queryStringBuilderRuleBase';
import { DefaultQueryStringBuilderRule } from './http/queryStringBuilderRules/defaultQueryStringBuilderRule';
import { SearchCriteriaQueryStringBuilderRule } from './http/queryStringBuilderRules/searchCriteriaQueryStringBuilderRule';
import { KendoModule } from './kendo.module';
import { AuthenticationService } from './services/authentication.service';
import { DocumentService } from './services/document.service';
import { ImpersonationService } from './services/impersonation.service';
import { ToastHelperService } from './services/toast.helper.service';
import { UserService } from './services/user.service';
import { UserInteractionService } from './services/userinteraction.service';

@NgModule({
  imports:
    [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
      TranslateModule.forChild({
        // parser: {
        //   provide: TranslateParser,
        //   useClass: ModuleTranslationParser
        // }
      }),
      KendoModule,
      NgxMasonryModule
    ],
  declarations:
    [
      GridComponent,
      CellSelectorComponent,
      TextCellComponent,
      NumberCellComponent,
      DatetimeCellComponent,
      ComboboxCellComponent,
      CheckboxCellComponent,
      LinkCellComponent,
      TextLinkItemComponent,
      IconLinkItemComponent,
      TextOptionItemComponent,
      IconOptionItemComponent,
      IconTextOptionItemComponent,
      HeaderComponent,
      FilterSelectorComponent,
      ValueFilterComponent,
      NumericValueFilterComponent,
      ValuesFilterComponent,
      DateRangeFilterComponent,
      NumericRangeFilterComponent,
      CheckboxFilterComponent,
      CommandCellComponent,

      EditorComponent,
      GroupComponent,
      LabelComponent,
      EditorSelectorComponent,
      TextEditorComponent,
      DatetimeEditorComponent,
      NumberEditorComponent,
      CheckboxEditorComponent,
      ComboboxEditorComponent,

      ColumnSelectorComponent,
      CommandButtonComponent,
      GlobalSearchComponent
    ],
  exports:
    [
      KendoModule,
      CommonModule,
      FormsModule,
      TranslateModule,

      GridComponent,
      ColumnSelectorComponent,
      EditorComponent,
      CommandButtonComponent,
      GlobalSearchComponent
    ],
  providers:
    [
      {
        provide: LoopingHttpClient,
        useFactory: (
          rules: QueryStringBuilderRuleBase[],
          notificationService: NotificationService,
          toastHelperService: ToastHelperService,
          http: HttpClient) => new LoopingHttpClient(rules, http, notificationService, toastHelperService),
        deps:
          [
            QueryStringBuilderRuleBase,
            NotificationService,
            ToastHelperService,
            HttpClient
          ]
      },
      {
        provide: QueryStringBuilderRuleBase,
        useClass: SearchCriteriaQueryStringBuilderRule,
        multi: true
      },
      {
        provide: QueryStringBuilderRuleBase,
        useClass: DefaultQueryStringBuilderRule,
        multi: true
      },
      CacheProvider,
      ToastHelperService,
      {
        provide: DocumentService,
        useFactory: (httpClient: LoopingHttpClient) => new DocumentService(httpClient, applicationEnvironment.api.endpoints.document.urlItem),
        deps:
          [
            LoopingHttpClient
          ]
      },
      UserInteractionService
    ]
})
export class SharedModule {
  //#region -- forRoot --

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers:
        [
          UserService,
          ImpersonationService,
          AuthenticationService
        ]
    };
  }

  //#endregion
}
