import { EnumHelper } from 'src/shared/helper/enum.helper';
import { AttributeAccessibilities } from '../../enum/attributeAccessibilities';
import { AttributeTypes } from '../../enum/attributeTypes';

export abstract class AttributeDefinitionBase {
    //#region -- fields --

    public name: string;
    public key: string;
    public accessibility: AttributeAccessibilities;
    public order: number;
    public regex: string;

    public abstract type: AttributeTypes;

    //#endregion

    //#region -- methods --

    public static isEditable = (item: AttributeDefinitionBase): boolean =>
        AttributeDefinitionBase.isSelectAllDependency(item)
        || EnumHelper.matchFlag<AttributeAccessibilities>(item.accessibility, AttributeAccessibilities.GridInlineEditing);

    public static isSelectAllDependency = (item: AttributeDefinitionBase): boolean =>
        EnumHelper.matchFlag<AttributeAccessibilities>(item.accessibility, AttributeAccessibilities.GridSelectAllDependency);

    //#endregion
}
