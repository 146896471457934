/* eslint-disable no-bitwise */
/* eslint-disable no-shadow */
export enum AttributeAccessibilities {
    None = 0,
    Grid = 1 << 0,
    GridSelectAllDependency = 1 << 1,
    GridInlineEditing = 1 << 2,
    GridSortable = 1 << 3,
    GridValueFilterable = 1 << 4,
    GridValuesFilterable = 1 << 5,
    GridRangeFilterable = 1 << 6,
    EditorReadOnly = 1 << 7,
    EditorRequired = 1 << 8,
    EditorCreate = 1 << 9,
    EditorModify = 1 << 10,
    ColumnSelectorDefaultColumn = 1 << 11,
    ColumnSelectorRequiredColumn = 1 << 12
}
