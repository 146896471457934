import { Directive, Input } from '@angular/core';

@Directive()
export class ItemBaseComponent<TModel> {
  //#region -- fields --

  private _model: TModel;

  //#endregion

  //#region -- properties --

  @Input()
  public set model(value: TModel) {
    this._model = value;
  }

  public get model(): TModel {
    return this._model;
  }

  //#endregion
}
