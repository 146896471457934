import { ExtendedDrawerItem } from 'src/components/models/extendedDrawerItem';

export const navigationConfiguration = <ExtendedDrawerItem[]>
  [
    { text: '', icon: 'fa-solid fa-bars', extendOnClick: true },
    { text: 'NAVIGATION.HOME', icon: 'fa-solid fa-house', path: '/home' },
    { text: 'NAVIGATION.TASKS', icon: 'fa-solid fa-tasks', path: '/tasks' },
    { text: 'NAVIGATION.COMPANYINFO', icon: 'fa-solid fa-shop', path: '/company-info' },
    { text: 'NAVIGATION.INTERESTED_PARTIES', icon: 'fa-solid fa-people-arrows', path: '/interested-parties' },
    { text: 'NAVIGATION.SHIPPING_SCHEDULE', icon: 'fa-solid fa-list', path: '/shipping-schedule' },
    { text: 'NAVIGATION.ACTIONS', icon: 'fa-solid fa-bullhorn', path: '/actions' },
    { text: 'NAVIGATION.MAILING_CONFIGURATION', icon: 'fa-solid fa-envelopes-bulk', path: '/mailing-configuration' }
  ];
