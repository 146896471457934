import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NumberAttributedefinitionBase } from 'src/common/webapi/contracts/attributes/bases/numberAttributedefinitionBase';
import { CellBaseComponent } from '../cell.base.component';

@Component({
  selector: 'clevermailing-number-cell',
  templateUrl: './number-cell.component.html',
  styleUrls: ['./number-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberCellComponent
  extends CellBaseComponent<NumberAttributedefinitionBase> {
  //#region -- methods --

  public getValue = (): string => this.attributeValue?.value;

  //#endregion
}
