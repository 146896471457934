/* eslint-disable no-bitwise */
import { List } from 'linqts';

export class EnumHelper {
  //#region -- methods --

  public static toEnumName = (enumeration: any, value: number): string =>
    EnumHelper.toEnumNames(enumeration)[value];

  public static toEnumNames = (enumeration: any): string[] =>
    new List(Object.keys(enumeration))
      .Select(x => enumeration[x])
      .Where(x => isNaN(+x))
      .Cast<string>()
      .Select(x => x.toLowerCase())
      .ToArray();

  public static matchFlag = <TItem extends number>(flagged: number, item: TItem): boolean =>
    (flagged & item) === item;

  //#endregion
}
