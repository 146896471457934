/* eslint-disable no-shadow */
export enum AttributeTypes {
    None = 0,
    Checkbox = 1,
    Combobox = 2,
    Datetime = 3,
    Link = 4,
    Number = 5,
    Relation = 6,
    RelationAttribute = 7,
    Text = 8
}
