/* eslint-disable no-bitwise */
/* eslint-disable no-shadow */
export enum Rights {
  None = 0,
  Read = 1 << 0,
  Create = 1 << 1,
  Edit = 1 << 2,
  Delete = 1 << 3,
  Impersonate = 1 << 4
}
