<div class="datetime-cell">
  <ng-container [ngSwitch]="isEditable">
    <ng-container *ngSwitchCase="false">
      {{ value }}
    </ng-container>
    <ng-container *ngSwitchCase="true">
      <div class="item editable">
        <clevermailing-editor-selector [definition]="definition"
                                       [item]="inlineEditorItem"></clevermailing-editor-selector>
      </div>
    </ng-container>
  </ng-container>
</div>
