import { List } from 'linqts';
import { CustomFilterBase } from './base/customFilter.base';

export class ParameterFilter
  extends CustomFilterBase<string> {
  // #region -- constructor --

  public constructor(
    key: string,
    value: any
  ) {
    super(key, '', '');

    this.model = value;
  }

  //#endregion

  //#region -- methods  --

  public isValid = (): boolean =>
    true;

  public toParams = (id: number): List<{ key: string; value: string }> =>
    new List<{ key: string; value: string }>();

  //#endregion
}
