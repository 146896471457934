<div class="app">
  <div class="header">
    <clevermailing-header>
      <clevermailing-profile-info></clevermailing-profile-info>
    </clevermailing-header>
  </div>
  <div class="content">
    <kendo-drawer-container>
      <kendo-drawer #drawer
                    [ngClass]="{ 'disabled': isNavigationDisabled }"
                    [items]="navigationItems"
                    [autoCollapse]="false"
                    [mode]="'push'"
                    [mini]="true"
                    [(expanded)]="isExpanded"
                    (select)="onSelect($event)">
        <ng-template kendoDrawerItemTemplate
                     let-item>
          <div class="drawer-item"
               title="{{ item.text | translate }}">
            <span class="{{ item.icon }}"></span>
            <div *ngIf="isExpanded">
              <div>{{ item.text | translate }}</div>
            </div>
          </div>
        </ng-template>
      </kendo-drawer>
      <kendo-drawer-content>
        <div class="outlet">
          <router-outlet></router-outlet>
        </div>
      </kendo-drawer-content>
    </kendo-drawer-container>
  </div>
  <div class="footer">
    <clevermailing-footer></clevermailing-footer>
  </div>
</div>
<div kendoDialogContainer></div>