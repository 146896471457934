import { mapFrom } from '@automapper/core';
import { List } from 'linqts';
import { AttributeGroup } from 'src/common/webapi/contracts/attributes/attributeGroup';
import { EditorAttribute } from 'src/shared/components/editor/models/editorAttribute';
import { EditorGroup } from 'src/shared/components/editor/models/editorGroup';
import { AttributeDefinitionBase } from '../../webapi/contracts/attributes/bases/attributeDefinitionBase';

export const editorItemGroupProfile = (mapper: any) => {
  mapper.createMap(AttributeGroup, EditorGroup)
    .forMember((destination: { name: string }) => destination.name, mapFrom(source => source.name))
    .forMember((destination: { order: number }) => destination.order, mapFrom(source => source.order))
    .forMember((destination: { attributes: AttributeDefinitionBase[] }) => destination.attributes, mapFrom(source => new List(source.attributes)))
    .forMember((destination: { editorAttributes: List<EditorAttribute> }) => destination.editorAttributes, mapFrom(source => new List<EditorAttribute>()));
};
