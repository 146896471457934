import { mapFrom } from '@automapper/core';
import { ResourceGridDataResult } from '../resourceGridDataResult';
import { SearchResult } from '../searchResult';
import { Resource } from '../../webapi/contracts/resource';

export const resourceGridDataResultProfile = (mapper: any) => {
  mapper.createMap(SearchResult, ResourceGridDataResult)
    .forMember((destination: { data: Resource[] }) => destination.data, mapFrom(source => source.result))
    .forMember((destination: { total: number }) => destination.total, mapFrom(source => source.totalCount));
};
