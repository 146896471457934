import { TextOption } from './textOption';

export class IconTextOption
  extends TextOption {
  //#region -- fields --

  public icon: string;
  public hexColor: string;

  //#endregion
}
