<div class="combobox-cell"
     [ngClass]="{ 'centered': type ===  viewItemTypes.IconOption }">
  <ng-container [ngSwitch]="isEditable">
    <ng-container *ngSwitchCase="false">
      <div class="item">
        <div *ngFor="let option of value"
             class="item"
             [ngClass]="{ 'centered': type ===  viewItemTypes.IconOption }"
             [ngSwitch]="type">
          <clevermailing-text-option-item *ngSwitchCase="viewItemTypes.TextOption"
                                          [model]="option"></clevermailing-text-option-item>
          <clevermailing-icon-option-item *ngSwitchCase="viewItemTypes.IconOption"
                                          [model]="option"></clevermailing-icon-option-item>
          <clevermailing-icon-text-option-item *ngSwitchCase="viewItemTypes.IconTextOption"
                                               [model]="option"></clevermailing-icon-text-option-item>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="true">
      <div class="item editable">
        <clevermailing-editor-selector [definition]="definition"
                                       [item]="inlineEditorItem"></clevermailing-editor-selector>
      </div>
    </ng-container>
  </ng-container>
</div>