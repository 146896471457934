import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { QuestionInfo } from 'src/common/models/questionInfo';
import { ViewCommand } from 'src/shared/components/grid/models/command/viewCommand';
import { UserService } from 'src/shared/services/user.service';
import { DialogResult } from '../models/dialogResult';
import { DialogResultState } from '../models/enums/dialogResultState';

@Component({
  selector: 'clevermailing-question-dialog',
  templateUrl: './question-dialog.component.html',
  styleUrls: ['./question-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionDialogComponent {
  //#region -- commands --

  private readonly _commands: ViewCommand<any>[] =
    [
      new ViewCommand('DIALOG_BASE.COMMANDS.ACCEPT', 'fa-solid fa-check', '',
        () => !this._userService.isAdmin,
        () => this._questionInfo?.dialogRef?.close(new DialogResult(DialogResultState.Success)))
    ];

  //#endregion

  //#region -- fields --

  private readonly _userService: UserService;

  private _questionInfo: QuestionInfo;

  //#endregion

  //#region -- properties --

  @Input()
  public set info(value: QuestionInfo) {
    this._questionInfo = value;
  }

  public get commands(): ViewCommand<any>[] {
    return this._commands;
  }

  public get title(): string {
    return this._questionInfo?.title;
  }

  public get message(): string {
    return this._questionInfo?.message;
  }

  public get dialogRef(): DialogRef {
    return this._questionInfo.dialogRef;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    userService: UserService,
  ) {
    this._userService = userService;
  }

  //#endregion
}
