
export class ArrayHelper {
  //#region -- methods --

  public static removeItemFromArray = <T>(item: T, array: Array<T>): void => {
    const index = array.indexOf(item);
    if (index === -1)
      throw new Error('Array does not contain this item!');

    array.splice(index, 1);
  };


  public static arrayCompare = (array1: Array<any>, array2: Array<any>): boolean => {
    if (
      !Array.isArray(array1)
      || !Array.isArray(array2)
      || array1.length !== array2.length
    )
      return false;

    const arr1 = array1.concat().sort();
    const arr2 = array2.concat().sort();

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i])
        return false;
    }

    return true;
  };

  //#endregion
}

