/* eslint-disable no-bitwise */
import { List } from 'linqts';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { AttributeAccessibilities } from 'src/common/webapi/contracts/enum/attributeAccessibilities';
import { AttributeTypes } from 'src/common/webapi/contracts/enum/attributeTypes';
import { EnumHelper } from 'src/shared/helper/enum.helper';
import { FilterTypes } from '../filterTypes';

// TODO CHECK FOR (e.g.) source.accessibility attribute. In tests it currently is not initialized
// therefore the test fails.
export class AttributeDefitiontionToFilterConverter {
  //#region -- methods --

  public static convert = (source: AttributeDefinitionBase): FilterTypes =>
    new List(
      [
        {
          key:
          {
            accessibility: AttributeAccessibilities.GridRangeFilterable,
            type: AttributeTypes.Datetime
          },
          type: FilterTypes.DateRangeFilter
        },
        {
          key:
          {
            accessibility: AttributeAccessibilities.GridRangeFilterable,
            type: AttributeTypes.Number
          },
          type: FilterTypes.NumericRangeFilter
        },
        {
          key:
          {
            accessibility: AttributeAccessibilities.GridValuesFilterable,
            type: AttributeTypes.Checkbox
          },
          type: FilterTypes.CheckboxFilter
        },
        {
          key:
          {
            accessibility: AttributeAccessibilities.GridValuesFilterable,
            type: undefined
          },
          type: FilterTypes.ValuesFilter
        },
        {
          key:
          {
            accessibility: AttributeAccessibilities.GridValueFilterable,
            type: AttributeTypes.Text
          },
          type: FilterTypes.ValueFilter
        },
        {
          key:
          {
            accessibility: AttributeAccessibilities.GridValueFilterable,
            type: AttributeTypes.Number
          },
          type: FilterTypes.NumericValueFilter
        }
      ]
    ).FirstOrDefault(
      item => EnumHelper.matchFlag<AttributeAccessibilities>(source.accessibility, item.key.accessibility)
        && (item.key.type === source.type || item.key.type === undefined))?.type
    ?? FilterTypes.None;

  //#endregion
}
