import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { List } from 'linqts';
import { ComboboxAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/comboboxAttributeDefinition';
import { OptionBase } from 'src/common/webapi/contracts/attributes/options/bases/optionBase';
import { AttributeValue } from 'src/common/webapi/contracts/attributes/values/attributeValue';
import { OptionCache } from 'src/shared/components/grid/models/cache/optionCache';
import { OptionTypesToViewTypesConverter } from 'src/shared/components/grid/models/enums/converter/optionTypesToViewTypes.converter';
import { ViewItemTypes } from 'src/shared/components/grid/models/enums/viewItemTypes';
import { ItemBuilderFactory } from 'src/shared/components/grid/models/itemBuilder/itemBuilderFactory';
import { OptionBuilderContext } from 'src/shared/components/grid/models/itemBuilder/optionBuilderContext';
import { CacheProvider } from 'src/shared/components/grid/provider/cache.provider';
import { isNullOrUndefined } from 'src/shared/helper/object.helper';
import { EditorBaseComponent } from '../editor.base.component';

@Component({
  selector: 'clevermailing-combobox-editor',
  templateUrl: './combobox-editor.component.html',
  styleUrls: ['./combobox-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComboboxEditorComponent
  extends EditorBaseComponent<ComboboxAttributeDefinition, number[], number[] | number> {
  //#region -- export enum --

  viewItemTypes = ViewItemTypes;

  //#endregion

  //#region -- fields --

  private readonly _itemBuilderFactory: ItemBuilderFactory;
  private readonly _cacheProvider: CacheProvider;

  private _cache: OptionCache<OptionBase>;
  private _control: MultiSelectComponent;

  //#endregion

  //#region -- properties --

  @ViewChild('multiSelect')
  public set control(value: MultiSelectComponent) {
    this._control = value;
  }

  public get type(): ViewItemTypes {
    if (isNullOrUndefined(this.cache.viewItemType))
      this.cache.viewItemType = OptionTypesToViewTypesConverter.convert(this.definition.valueType);

    return this.cache?.viewItemType;
  }

  public get items(): OptionBase[] {
    if (isNullOrUndefined(this.cache.options)) {
      this.cache.options = new List(this.definition.options)
        .Select(option => <AttributeValue>{
          definitionKey: this.definition.key,
          value: option.value
        })
        .Select(value => <OptionBase>this._itemBuilderFactory.create(this.type, new OptionBuilderContext(this.definition, value)))
        .ToArray();
    }

    return this.cache?.options;
  }

  private get cache(): OptionCache<OptionBase> {
    if (isNullOrUndefined(this._cache))
      this._cache = this._cacheProvider.get<OptionCache<OptionBase>>(this.definition.key);

    return this._cache;
  };

  //#endregion

  //#region -- constructor --

  public constructor(
    itemBuilderFactory: ItemBuilderFactory,
    cacheProvider: CacheProvider
  ) {
    super(
      (value: number[]) => this.fromModel(value),
      (value: number | number[]) => this.toModel(value)
    );

    this._itemBuilderFactory = itemBuilderFactory;
    this._cacheProvider = cacheProvider;
  }

  //#endregion

  //#region -- methods --

  public itemDisabled(itemArgs: { dataItem: OptionBase; index: number }) {
    return itemArgs.dataItem.isDisabled;
  }

  private fromModel = (value: number[]): number | number[] =>
    this.definition.isMultiSelect
      ? this.fromModelForMultiSelect(value)
      : this.forModelForSingleSelect(value);


  private toModel = (value: number | number[]): number[] => {
    if (this.definition.isMultiSelect && isNullOrUndefined(value))
      return [];

    return Array.isArray(value)
      ? value
      : [value];
  };

  private fromModelForMultiSelect = (value: number[]): number[] | undefined =>
    value.length === 0
      ? undefined
      : value;

  private forModelForSingleSelect = (value: number[]): number | undefined => {
    const newValue = new List(value).LastOrDefault();
    this._control.value = [newValue];

    return newValue;
  };

  //#endregion
}
