import { List } from 'linqts';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { EditorAttribute } from './editorAttribute';

export class EditorGroup {
  //#region --fields --

  public name: string;
  public order: number;
  public definitions: List<AttributeDefinitionBase>;
  public editorAttributes: List<EditorAttribute>;

  //#endregion

  //#region --properties --

  public get isDirty(): boolean {
    return this.editorAttributes.Any(itemAttribute => itemAttribute.isDirty);
  }

  //#endregion
}
