import { LinkBase } from './link.base';

export class IconLink
  extends LinkBase {
  //#region -- fields --

  public icon: string;
  public hexColor: string;
  public tooltip: string;

  //#endregion
}
