import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { List } from 'linqts';
import { SubscriptionBase } from 'src/shared/base/subscription.base';
import { CommandBase } from '../models/command/base/command.base';
import { CommandCellModelBuilderInfo } from '../models/command/commandCellModelBuilderInfo';
import { ResourceCommandModel } from '../models/command/resourceCommandModel';

@Component({
  selector: 'clevermailing-command-cell',
  templateUrl: './command-cell.component.html',
  styleUrls: ['./command-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommandCellComponent
  extends SubscriptionBase
  implements OnDestroy {

  //#region -- fields --

  private _commands: List<CommandBase<any>>;
  private _model: CommandCellModelBuilderInfo;

  //#endregion

  //#region -- properties --

  @Input()
  public set model(value: CommandCellModelBuilderInfo) {
    this._model = value;
  }

  @Input()
  public set commands(value: CommandBase<any>[]) {
    this._commands = new List(value);
  }

  public get commands(): CommandBase<any>[] {
    return this._commands
      .Where(command => command.isVisible(this.getModel()))
      .ToArray();
  }

  //#endregion

  //#region -- constructor --

  public constructor() {
    super();

    this._commands = new List<CommandBase<any>>();
  }

  //#endregion

  //#region -- methods --

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public getModel = (): ResourceCommandModel =>
    <ResourceCommandModel>{
      resource: this._model?.resource,
      definition: this._model?.definition
    };

  //#endregion
}
