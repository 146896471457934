import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'clevermailing-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfViewerComponent {
  //#region -- configuration --

  private static readonly AssetsFolderPath: string = 'bleeding-edge';

  //#endregion

  //#region -- fields --

  private readonly _changeDetector: ChangeDetectorRef;

  private _pdf: Blob;
  private _dialogRef: DialogRef;

  //#endregion

  //#region -- properties --

  @Input()
  public set dialogRef(value: DialogRef) {
    this._dialogRef = value;
  }

  public get dialogRef(): DialogRef {
    return this._dialogRef;
  }

  @Input()
  public set pdf(value: Blob) {
    this._pdf = value;
  }

  public get pdf(): Blob {
    return this._pdf;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    changeDetector: ChangeDetectorRef
  ) {
    this._changeDetector = changeDetector;

    pdfDefaultOptions.assetsFolder = PdfViewerComponent.AssetsFolderPath;
  }

  //#endregion

  //#region -- methods --

  public onLoaded = (): void =>
    this._changeDetector.detectChanges();

  //#endregion
}
