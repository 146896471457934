<div>
  <span *ngIf="!model?.hasLink">
    {{ model.text }}
  </span>
  <a *ngIf="model?.hasLink"
     [href]="model?.url"
     [target]="model?.target"
     (click)="$event.stopPropagation()">
    {{ model?.text }}
  </a>
</div>
