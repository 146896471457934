import { mapFrom } from '@automapper/core';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { GridColumn } from '../gridColumn';

export const gridColumnProfile = (mapper: any) => {
  mapper.createMap(AttributeDefinitionBase, GridColumn)
    .forMember((destination: { definition: AttributeDefinitionBase }) => destination.definition, mapFrom(source => source))
    .forMember((destination: { title: string }) => destination.title, mapFrom(source => source.name))
    .forMember((destination: { field: string }) => destination.field, mapFrom(source => source.key))
    .forMember((destination: { isEditable: boolean }) => destination.isEditable, mapFrom(source => AttributeDefinitionBase.isEditable(source)));
};
