import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DateTime } from 'luxon';
import { DatetimeAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/datetimeAttributeDefinition';
import { isNotNullOrUndefined, isNullOrUndefined } from 'src/shared/helper/object.helper';
import { CellBaseComponent } from '../cell.base.component';

@Component({
  selector: 'clevermailing-datetime-cell',
  templateUrl: './datetime-cell.component.html',
  styleUrls: ['./datetime-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatetimeCellComponent
  extends CellBaseComponent<DatetimeAttributeDefinition> {
  //#region -- methods --

  public getValue = (): string => {
    if (isNullOrUndefined(this.attributeValue?.value) || this.attributeValue?.value === '')
      return '';

    const date = DateTime
      .fromISO(this.attributeValue?.value);

    return isNotNullOrUndefined(this.definition?.format) && isNotNullOrUndefined(date)
      ? date.toFormat(this.definition.format)
      : date.toLocaleString();
  };

  //#endregion
}
