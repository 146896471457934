import { TemplateRef } from '@angular/core';
import { DialogSettings } from '@progress/kendo-angular-dialog';
import { applicationEnvironment } from 'src/environments/application.environment';

export class DialogHelper {
  //#region -- methods --

  public static templateDialogSettings = (template: TemplateRef<any>): DialogSettings =>
    DialogHelper.compile(template, applicationEnvironment.dialogs.default);

  public static selectUserSettingsSettings = (template: TemplateRef<any>): DialogSettings =>
    DialogHelper.compile(template, applicationEnvironment.dialogs.userSettings);

  public static selectImpersonationSettingsSettings = (template: TemplateRef<any>): DialogSettings =>
    DialogHelper.compile(template, applicationEnvironment.dialogs.impersonisationSettings);

  public static editorDialogSettings = (template: TemplateRef<any>): DialogSettings =>
    DialogHelper.compile(template, applicationEnvironment.dialogs.editor);

  public static transactionDialogSettings = (template: TemplateRef<any>): DialogSettings =>
    DialogHelper.compile(template, applicationEnvironment.dialogs.transaction);

  public static broadcastDialogSettings = (template: TemplateRef<any>): DialogSettings =>
    DialogHelper.compile(template, applicationEnvironment.dialogs.broadcast);

  public static configuratorDialogSettings = (template: TemplateRef<any>): DialogSettings =>
    DialogHelper.compile(template, applicationEnvironment.dialogs.configurator);

  public static pdfDialogSettings = (template: TemplateRef<any>): DialogSettings =>
    DialogHelper.compile(template, applicationEnvironment.dialogs.pdf);

  public static newsViewDialogSettings = (template: TemplateRef<any>): DialogSettings =>
    DialogHelper.compile(template, applicationEnvironment.dialogs.newsView);


  private static compile = (template: TemplateRef<any>, sizeSet: any): DialogSettings =>
    <DialogSettings>{
      content: template,
      minHeight: sizeSet?.minHeight,
      maxHeight: sizeSet?.maxHeight,
      minWidth: sizeSet?.minWidth,
      maxWidth: sizeSet?.maxWidth
    };

  //#endregion
}
