<div class="profile-info">
  <div *ngIf="isLoggedIn"
       class="info">
    <div class="icon">
      <span [className]="icon"
            (click)="isAdmin
            ? showImpersonationSettings(impersonationSettings)
            : showUserSettings(userSettings)"></span>
    </div>
    <div class="user">
      <div>
        {{ name }}
      </div>
      <div>
        {{ userName }} ({{ role }})
      </div>
    </div>
  </div>
  <div class="commands">
    <clevermailing-command-button *ngFor="let command of commands"
                                  [command]="command"></clevermailing-command-button>
  </div>
  <div class="templates">
    <ng-template #userSettings>
      <clevermailing-user-settings [dialogRef]="openDialogRef"></clevermailing-user-settings>
    </ng-template>
    <ng-template #impersonationSettings>
      <clevermailing-impersonation-settings [dialogRef]="openDialogRef"></clevermailing-impersonation-settings>
    </ng-template>
  </div>
</div>