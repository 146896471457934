import { HttpParams } from '@angular/common/http';
import { keyValueHttpParamaterCodec } from '../http/codecs/keyValueHttpParamaterCodec';
import { isNotNullOrUndefined } from './object.helper';

export class HttpHelper {
  // #region -- methods --

  public static getHttpParamsFromAny = (params: any): HttpParams => {
    let httpParams = new HttpParams({
     encoder: new  keyValueHttpParamaterCodec()
    });

    if (params) {
      Object
        .keys(params)
        .forEach(key => {
          const value = params[key];

          if (isNotNullOrUndefined(value)) {
            if (Array.isArray(value)) {
              if (value.length > 0)
                httpParams = httpParams.set(key, JSON.stringify(value).replace(/"([^"]+)":/g, '$1:'));
            } else
              httpParams = httpParams.set(key, value);
          }
        });
    }

    return httpParams;
  };

  //#endregion
}


