<div class="view-title">
  <div class="title">
    <kendo-badge-container>
      <span>
        {{ title | translate }}
      </span>
      <kendo-badge *ngIf="notificationCount"
                   [shape]="'pill'"
                   [position]="'outside'">
        {{ notificationCount }}
      </kendo-badge>
    </kendo-badge-container>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>