/* eslint-disable no-bitwise */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { List } from 'linqts';
import { ComboboxAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/comboboxAttributeDefinition';
import { AttributeValue } from 'src/common/webapi/contracts/attributes/values/attributeValue';
import { isNullOrUndefined } from 'src/shared/helper/object.helper';
import { OptionCache } from '../../models/cache/optionCache';
import { OptionTypesToViewTypesConverter } from '../../models/enums/converter/optionTypesToViewTypes.converter';
import { ViewItemTypes } from '../../models/enums/viewItemTypes';
import { ItemBuilderFactory } from '../../models/itemBuilder/itemBuilderFactory';
import { OptionBuilderContext } from '../../models/itemBuilder/optionBuilderContext';
import { OptionBase } from '../../models/options/option.base';
import { CacheProvider } from '../../provider/cache.provider';
import { CellBaseComponent } from '../cell.base.component';

@Component({
  selector: 'clevermailing-combobox-cell',
  templateUrl: './combobox-cell.component.html',
  styleUrls: ['./combobox-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComboboxCellComponent
  extends CellBaseComponent<ComboboxAttributeDefinition> {
  //#region -- export enum --

  viewItemTypes = ViewItemTypes;

  //#endregion

  //#region -- fields --

  private readonly _itemBuilderFactory: ItemBuilderFactory;
  private readonly _cacheProvider: CacheProvider;

  private _cache: OptionCache<OptionBase>;

  //#endregion

  //#region -- properties --

  public get type(): ViewItemTypes {
    if (isNullOrUndefined(this.cache.viewItemType))
      this.cache.viewItemType = OptionTypesToViewTypesConverter.convert(this.definition.valueType);

    return this.cache?.viewItemType;
  }

  private get options(): OptionBase[] {
    if (isNullOrUndefined(this.cache.options)) {
      this.cache.options = new List(this.definition.options)
        .Select(option => <AttributeValue>{
          definitionKey: this.definition.key,
          value: option.value
        })
        .Select(value => <OptionBase>this._itemBuilderFactory.create(this.type, new OptionBuilderContext(this.definition, value)))
        .ToArray();
    }

    return this.cache?.options;
  }

  private get cache(): OptionCache<OptionBase> {
    if (isNullOrUndefined(this._cache))
      this._cache = this._cacheProvider.get<OptionCache<OptionBase>>(this.definition.key);

    return this._cache;
  };

  //#endregion

  //#region -- constructor --

  public constructor(
    itemBuilderFactory: ItemBuilderFactory,
    cacheProvider: CacheProvider
  ) {
    super();

    this._itemBuilderFactory = itemBuilderFactory;
    this._cacheProvider = cacheProvider;
  }

  //#endregion

  //#region -- methods --

  public getValue = (): OptionBase[] => {
    const values = Array.isArray(this.attributeValue.value)
      ? new List<number>(this.attributeValue.value)
      : new List<number>([this.attributeValue.value]);

    return new List(this.options)
      .Where(option => values.Contains(option.value))
      .ToArray();
  };

  //#endregion
}
