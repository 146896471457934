<div class="broadcast">
  <clevermailing-view-title [title]="'MODULES.INTERESTED_PARTIES.BROADCASTS.TITLE' | translate:{displayName:displayName}"
                            [notificationCount]="notificationCount">
    <clevermailing-global-search class="search"
                                 [(searchPhrase)]="searchPhrase"></clevermailing-global-search>
    <div class="commands">
      <clevermailing-command-button *ngFor="let command of viewCommands"
                                    [command]="command"></clevermailing-command-button>
    </div>
  </clevermailing-view-title>
  <clevermailing-view-content>
    <div class="grid">
      <clevermailing-simple-grid #broadcastGrid
                                 [height]="gridHeight"
                                 [contentGroup]="contentGroups.broadcast"
                                 [gridCommands]="gridCommands"
                                 [customFilters]="customFilters"
                                 [searchPhrase]="searchPhrase"
                                 (totalCountChanged)="onTotalCountChanged($event)"></clevermailing-simple-grid>
    </div>
    <div class="templates">
      <ng-template #columnSelector>
        <clevermailing-column-selector [dialogRef]="openDialogRef"
                                       [(visibleColumns)]="broadcastGrid.visibleColumns">
        </clevermailing-column-selector>
      </ng-template>
      <ng-template #pdf>
        <clevermailing-pdf-viewer [dialogRef]="openDialogRef"
                                  [pdf]="blob"></clevermailing-pdf-viewer>
      </ng-template>
    </div>
  </clevermailing-view-content>
</div>