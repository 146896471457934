import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiEndpointConfiguration } from 'src/common/models/apiEndpointConfiguration';
import { Resource } from 'src/common/webapi/contracts/resource';
import { ResourceDefinition } from 'src/common/webapi/contracts/resourceDefinition';
import { LoopingHttpClient } from 'src/shared/http/loopingHttpClient';
import { isNotNullOrUndefined } from '../helper/object.helper';
import { ResponsibleServiceBase } from './responsible.service.base';

@Injectable()
export class ItemService
  extends ResponsibleServiceBase
  implements OnDestroy {
  //#region -- fields --

  private readonly _httpClient: LoopingHttpClient;
  private readonly _definitionSource: Subject<ResourceDefinition>;
  private readonly _resourceSource: Subject<Resource>;

  private _urlToDefaults: string;
  private _urlToItem: string;

  //#endregion

  //#region -- properties --

  public get definition(): Observable<ResourceDefinition> {
    return this._definitionSource.asObservable();
  }

  public get resource(): Observable<Resource> {
    return this._resourceSource.asObservable();
  }


  protected get httpClient(): LoopingHttpClient {
    return this._httpClient;
  }

  protected get definitionSource(): Subject<ResourceDefinition> {
    return this._definitionSource;
  }

  protected get resourceSource(): Subject<Resource> {
    return this._resourceSource;
  }

  protected get urlToDefaults(): string {
    return this._urlToDefaults;
  }

  protected get urlToItem(): string {
    return this._urlToItem;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    httpClient: LoopingHttpClient,
    apiEndpointConfiguration?: ApiEndpointConfiguration,
    responsibleFor?: string
  ) {
    super(responsibleFor);

    this._httpClient = httpClient;

    this._urlToDefaults = `${apiEndpointConfiguration.urlItem}/defaults`;
    this._urlToItem = apiEndpointConfiguration.urlItem;

    this._definitionSource = new Subject<ResourceDefinition>();
    this._resourceSource = new Subject<Resource>();
  }

  //#endregion

  //#region -- methods ..

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public getDefinitions = (): Observable<ResourceDefinition> =>
    this._httpClient
      .get<ResourceDefinition>(this._urlToDefaults);

  public fetchDefinitions = (): void =>
    this.callWithLoadingIndicator(
      this.getDefinitions(),
      (result: ResourceDefinition) => this._definitionSource.next(result));

  public get = (id: number): Observable<Resource> =>
    this._httpClient
      .get<Resource>(this.getResourceIdPath(this._urlToItem, id));

  public fetch = (id: number): void =>
    this.callWithLoadingIndicator(
      this.get(id),
      (result: Resource) => this._resourceSource.next(result));

  public delete = (id: number): Observable<any> =>
    this._httpClient
      .delete<Resource>(this.getResourceIdPath(this._urlToItem, id));

  public update = (resource: Resource): Observable<any> =>
    this._httpClient
      .update(this.getResourceIdPath(this._urlToItem), resource);

  public save = (resource: Resource): Observable<any> =>
    this._httpClient
      .create(this.getResourceIdPath(this._urlToItem), resource);

  protected getResourceIdPath = (basePath: string, id?: number | string): string =>
    isNotNullOrUndefined(id)
      ? `${basePath}/${id}`
      : basePath;
  //#endregion
}


