<div>
  <div class="min">
    <span>{{ 'GRID.FILTER.NUMERIC_RANGE.FROM' | translate }}</span>
    <kendo-numerictextbox [(value)]="model.start"
                          [format]="format"
                          [autoCorrect]="true"
                          [min]="min"
                          [max]="max"> </kendo-numerictextbox>
  </div>
  <div class="max">
    <span>{{ 'GRID.FILTER.NUMERIC_RANGE.TO' | translate }}</span>
    <kendo-numerictextbox [(value)]="model.end"
                          [format]="format"
                          [autoCorrect]="true"
                          [min]="min"
                          [max]="max"> </kendo-numerictextbox>
  </div>
</div>
