import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DateTime } from 'luxon';
import { DatetimeAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/datetimeAttributeDefinition';
import { isNotNullOrUndefined } from 'src/shared/helper/object.helper';
import { EditorBaseComponent } from '../editor.base.component';

@Component({
  selector: 'clevermailing-datetime-editor',
  templateUrl: './datetime-editor.component.html',
  styleUrls: ['./datetime-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatetimeEditorComponent
  extends EditorBaseComponent<DatetimeAttributeDefinition, Date, string> {
  //#region -- constructor --

  constructor() {
    super(
      (value: Date) => DateTime.fromJSDate(value)?.toUTC()?.toISO(),
      (value: string) =>
        isNotNullOrUndefined(value)
          ? new Date(value)
          : undefined
    );
  }

  //#endregion
}
