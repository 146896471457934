import { NgModule } from '@angular/core';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { SharedModule } from 'src/shared/shared.module';
import { BroadcastComponent } from './broadcast/broadcast.component';
import { CompanyInfoComponent } from './company-info/company-info.component';
import { DetailComponent } from './detail/detail.component';
import { DialogBaseComponent } from './dialog-base/dialog-base.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ImpersonationSettingsComponent } from './impersonation-settings/impersonation-settings.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { QuestionDialogComponent } from './question-dialog/question-dialog.component';
import { SimpleGridComponent } from './simple-grid/simple-grid.component';
import { TransactionComponent } from './transaction/transaction.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { ViewContentComponent } from './view-content/view-content.component';
import { ViewTitleComponent } from './view-title/view-title.component';
import { WidgetBaseComponent } from './widget-base/widget-base.component';

@NgModule({
    imports: [
        SharedModule,
        NgxExtendedPdfViewerModule
    ],
    declarations: [
        HeaderComponent,
        ProfileInfoComponent,
        CompanyInfoComponent,
        FooterComponent,
        UserSettingsComponent,
        ImpersonationSettingsComponent,
        ViewTitleComponent,
        ViewContentComponent,
        DialogBaseComponent,
        SimpleGridComponent,
        PdfViewerComponent,
        BroadcastComponent,
        DetailComponent,
        TransactionComponent,
        WidgetBaseComponent,
        QuestionDialogComponent
    ],
    exports: [
        HeaderComponent,
        ProfileInfoComponent,
        CompanyInfoComponent,
        FooterComponent,
        UserSettingsComponent,
        ImpersonationSettingsComponent,
        ViewTitleComponent,
        ViewContentComponent,
        DialogBaseComponent,
        SimpleGridComponent,
        PdfViewerComponent,
        BroadcastComponent,
        DetailComponent,
        TransactionComponent,
        WidgetBaseComponent,
        QuestionDialogComponent
    ]
})
export class ComponentsModule { }
