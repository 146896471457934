import { fromValue, mapFrom } from '@automapper/core';
import { Option } from 'src/common/webapi/contracts/attributes/options/option';
import { ClientUser } from 'src/common/webapi/contracts/clientUser';
import { OptionTypes } from 'src/common/webapi/contracts/enum/optionTypes';

export const clientUserProfile = (mapper: any) => {
  mapper.createMap(ClientUser, Option)
    .forMember((destination: { value: number }) => destination.value, mapFrom(source => source.id))
    .forMember((destination: { text: string }) => destination.text, mapFrom(source => `${source.lastname}, ${source.firstname}`))
    .forMember((destination: { type: OptionTypes }) => destination.type, fromValue(OptionTypes.Text));
};
