import { convertUsing, mapFrom } from '@automapper/core';
import { LinkAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/linkAttributeDefinition';
import { IconLinkValue } from 'src/common/webapi/contracts/attributes/values/iconLinkValue';
import { ItemBuilderContext } from 'src/shared/components/grid/models/itemBuilder/itemBuilderContext';
import { IconLink } from 'src/shared/components/grid/models/links/iconLink';
import { targetToTargetStringConverter } from './converter/linkTargetToTargetString.converter';

export const iconLinkProfile = (mapper: any) => {
  mapper.createMap(ItemBuilderContext, IconLink)
    .forMember((destination: { url: string }) => destination.url, mapFrom((source: ItemBuilderContext) => source.value<IconLinkValue>().url))
    .forMember((destination: { hexColor: string }) => destination.hexColor, mapFrom((source: ItemBuilderContext) => source.value<IconLinkValue>().hexColor))
    .forMember((destination: { icon: string }) => destination.icon, mapFrom((source: ItemBuilderContext) => source.value<IconLinkValue>().icon))
    .forMember((destination: { tooltip: string }) => destination.tooltip, mapFrom((source: ItemBuilderContext) => source.value<IconLinkValue>().tooltip))
    .forMember((destination: { target: string }) => destination.target, convertUsing(targetToTargetStringConverter, (source: ItemBuilderContext) => source.definition<LinkAttributeDefinition>().target))
    ;
};
