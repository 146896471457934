import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TextAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/textAttributeDefinition';
import { CellBaseComponent } from '../cell.base.component';

@Component({
  selector: 'clevermailing-text-cell',
  templateUrl: './text-cell.component.html',
  styleUrls: ['./text-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextCellComponent
  extends CellBaseComponent<TextAttributeDefinition> {
  //#region -- methods --

  public getValue = (): string => this.attributeValue?.value;

  //#endregion
}
