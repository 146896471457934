import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GridColumn } from 'src/common/models/gridColumn';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { UserService } from 'src/shared/services/user.service';

@Component({
  selector: 'clevermailing-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  //#region -- fields --

  private readonly _userService: UserService;
  private readonly _isSelectAllChangedEventEmitter: EventEmitter<boolean>;

  private _gridColumn: GridColumn;
  private _isSelectAll: boolean;

  //#endregion

  //#region -- properties --

  @Input()
  public set gridColumn(value: GridColumn) {
    this._gridColumn = value;
  }

  public get title(): string {
    return this._gridColumn?.title;
  }

  public get isDisabled(): boolean {
    return this._userService.isAdmin;
  }

  public get showSelectAll(): boolean {
    return AttributeDefinitionBase.isSelectAllDependency(this._gridColumn.definition);
  }

  public get isSelectAll(): boolean {
    return this._isSelectAll;
  }

  @Input()
  public set isSelectAll(value: boolean) {
    this._isSelectAll = value;

    if (this.showSelectAll)
      this._isSelectAllChangedEventEmitter.emit(value);
  }

  @Output()
  public get isSelectAllChange(): EventEmitter<boolean> {
    return this._isSelectAllChangedEventEmitter;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    userService: UserService
  ) {
    this._userService = userService;
    this._gridColumn = new GridColumn();
    this._isSelectAllChangedEventEmitter = new EventEmitter<boolean>();
  }

  //#endregion
}
