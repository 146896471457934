import { ChangeDetectionStrategy, Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Subscription } from 'rxjs';
import { ResourceTypes } from 'src/common/webapi/contracts/enum/resourceTypes';
import { Resource } from 'src/common/webapi/contracts/resource';
import { applicationEnvironment } from 'src/environments/application.environment';
import { SubscriptionBase } from 'src/shared/base/subscription.base';
import { ColumnSelectorComponent } from 'src/shared/components/column-selector/column-selector.component';
import { GridCommand } from 'src/shared/components/grid/models/command/gridCommand';
import { ResourceCommandModel } from 'src/shared/components/grid/models/command/resourceCommandModel';
import { ViewCommand } from 'src/shared/components/grid/models/command/viewCommand';
import { CustomFilterBase } from 'src/shared/components/grid/models/filter/base/customFilter.base';
import { ParameterFilter } from 'src/shared/components/grid/models/filter/parameterFilter';
import { DialogHelper } from 'src/shared/helper/dialog.helper';
import { DocumentService } from 'src/shared/services/document.service';
import { DialogResult } from '../models/dialogResult';
import { DialogResultState } from '../models/enums/dialogResultState';
import { PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';
import { SimpleGridComponent } from '../simple-grid/simple-grid.component';

@Component({
  selector: 'clevermailing-broadcast',
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BroadcastComponent
  extends SubscriptionBase
  implements OnDestroy {
  //#region -- exports --

  contentGroups: any = applicationEnvironment.contentGroups;

  //#endregion

  //#region -- commands --

  private readonly _viewCommands: ViewCommand<any>[] =
    [
      new ViewCommand('VIEW.COMMANDS.COLUMN_SELECTOR', 'fa-solid fa-columns', '', () => this.canOpenColumnSelector(), () => this.openColumnSelector()),
      new ViewCommand('DIALOG_BASE.COMMANDS.CLOSE', 'fa-solid fa-times', '', () => true, () => this.closeDialog())
    ];

  private _gridCommands: GridCommand[] =
    [
      new GridCommand('MODULES.INTERESTED_PARTIES.BROADCASTS.COMMANDS.SHOW_PDF', 'fa-solid fa-file-pdf', '', () => true, model => this.openPDF(model)),
    ];

  //#endregion

  //#region -- fields --

  private readonly _dialogService: DialogService;
  private readonly _documentService: DocumentService;

  private _columnSelectorTemplate: TemplateRef<ColumnSelectorComponent>;
  private _pdfTemplate: TemplateRef<PdfViewerComponent>;
  private _openDialogRef: DialogRef;
  private _searchPhrase: string;
  private _dialogRef: DialogRef;
  private _resource: Resource;
  private _notificationCount: number;
  private _displayName: string;
  private _blob: Blob;
  private _grid: SimpleGridComponent;

  //#endregion

  //#region -- properties --

  @ViewChild('broadcastGrid')
  public set grid(value: SimpleGridComponent) {
    this._grid = value;
  }

  @ViewChild('columnSelector')
  public set columnSelectorTemplate(value: TemplateRef<ColumnSelectorComponent>) {
    this._columnSelectorTemplate = value;
  }

  @ViewChild('pdf')
  public set pdfTemplate(value: TemplateRef<PdfViewerComponent>) {
    this._pdfTemplate = value;
  }

  @Input()
  public set dialogRef(value: DialogRef) {
    this._dialogRef = value;
  }

  @Input()
  public set resource(value: Resource) {
    this._resource = value;

    this._displayName = value.displayName;
  }

  public get displayName(): string {
    return this._displayName;
  }

  public get openDialogRef(): DialogRef {
    return this._openDialogRef;
  }

  public get gridHeight(): number {
    return 707;
  }

  public get gridCommands(): GridCommand[] {
    return this._gridCommands;
  }

  public get viewCommands(): ViewCommand<any>[] {
    return this._viewCommands;
  }

  public set searchPhrase(value: string) {
    this._searchPhrase = value;
  }

  public get searchPhrase(): string {
    return this._searchPhrase;
  }

  public get notificationCount(): number {
    return this._notificationCount;
  }

  public get customFilters(): CustomFilterBase<any>[] {
    return [
      new ParameterFilter(`${ResourceTypes[this._resource.type]}id`, `${this._resource.id}`)
    ];
  }

  public get blob(): Blob {
    return this._blob;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    dialogService: DialogService,
    documentService: DocumentService
  ) {
    super();

    this._dialogService = dialogService;
    this._documentService = documentService;
  }

  //#endregion

  //#region -- methods --

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public onTotalCountChanged = (totalCount: number): number =>
    this._notificationCount = totalCount;

  private canOpenColumnSelector = (): boolean =>
    this._grid?.visibleColumns?.visibleColumns?.length > 0;

  private openColumnSelector = (): DialogRef =>
    this._openDialogRef = this._dialogService
      .open(DialogHelper.templateDialogSettings(this._columnSelectorTemplate));

  private openPDF = (model: ResourceCommandModel): Subscription =>
    this._documentService
      .getDocument(model.resource)
      .subscribe(document => {
        this._blob = document;

        this._openDialogRef = this._dialogService
          .open(DialogHelper.pdfDialogSettings(this._pdfTemplate));
      });

  private closeDialog = (): void =>
    this._dialogRef.close(new DialogResult(DialogResultState.Closed));

  //#endregion
}
