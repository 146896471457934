import { Component } from '@angular/core';
import { NumberAttributedefinitionBase } from 'src/common/webapi/contracts/attributes/bases/numberAttributedefinitionBase';
import { TextAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/textAttributeDefinition';
import { ValueFilter } from '../../models/filter/valueFilter';
import { FilterBaseComponent } from '../filter.base.component';

@Component({
  selector: 'clevermailing-value-filter',
  templateUrl: './value-filter.component.html',
  styleUrls: ['./value-filter.component.scss']
})
export class ValueFilterComponent
  extends FilterBaseComponent<TextAttributeDefinition | NumberAttributedefinitionBase, ValueFilter, string> { }
