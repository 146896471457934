import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { NumberAttributedefinitionBase } from 'src/common/webapi/contracts/attributes/bases/numberAttributedefinitionBase';
import { DecimalNumberAttributeDefinition } from 'src/common/webapi/contracts/attributes/definitions/decimalNumberAttributeDefinition';
import { isNotNullOrUndefined } from 'src/shared/helper/object.helper';
import { EditorBaseComponent } from '../editor.base.component';

@Component({
  selector: 'clevermailing-number-editor',
  templateUrl: './number-editor.component.html',
  styleUrls: ['./number-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberEditorComponent
  extends EditorBaseComponent<NumberAttributedefinitionBase, number, number> {
  //#region -- properties --

  public get format(): NumberFormatOptions {
    const fractionDigits = isNotNullOrUndefined((<DecimalNumberAttributeDefinition>this.definition)?.precision)
      ? (<DecimalNumberAttributeDefinition>this.definition)?.precision?.fractional
      : 0;

    return <NumberFormatOptions>{
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits
    };
  }

  //#endregion

  //#region -- constructor --

  constructor() {
    super(
      (value: number) => value,
      (value: number) => value
    );
  }

  //#endregion

}
