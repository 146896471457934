import { CustomFilterBase } from 'src/shared/components/grid/models/filter/base/customFilter.base';
import { SortingDirections } from './enums/sortingDirections';

export class SearchCriteria {
  //#region -- fields --
  [key: string]: any
  public sortBy?: string;
  public sortDirection?: SortingDirections;
  public skip?: number;
  public take?: number;
  public phrase?: string;
  public filters?: CustomFilterBase<any>[];
  public visibledefinitions?: string[];

  //#endregion
}
