import { mapFrom } from '@automapper/core/src/lib/member-map-functions';
import { Option } from 'src/common/webapi/contracts/attributes/options/option';
import { OptionBuilderContext } from 'src/shared/components/grid/models/itemBuilder/optionBuilderContext';
import { TextOption } from 'src/shared/components/grid/models/options/textOption';

export const textOptionProfile = (mapper: any) => {
  mapper.createMap(OptionBuilderContext, TextOption)
    .forMember((destination: { value: number }) => destination.value, mapFrom((source: OptionBuilderContext) => source.option<Option>()?.value))
    .forMember((destination: { isDisabled: boolean }) => destination.isDisabled, mapFrom((source: OptionBuilderContext) => source.option<Option>()?.isDisabled))
    .forMember((destination: { text: string }) => destination.text, mapFrom((source: OptionBuilderContext) => source.option<Option>()?.text));
};
