import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationSettings } from '@progress/kendo-angular-notification';
import { applicationEnvironment } from 'src/environments/application.environment';

@Injectable()
export class ToastHelperService {
  //#region -- fields --

  private readonly _translationService: TranslateService;

  //#endregion

  //#region -- constructor --

  public constructor(
    translateService: TranslateService
  ) {
    this._translationService = translateService;
  }

  //#endregion

  //#region -- methods --

  public success = (message: string): NotificationSettings =>
    <NotificationSettings>{
      content: this._translationService.instant(message),
      hideAfter: applicationEnvironment.notification.hideAfter,
      position: {
        horizontal: 'center',
        vertical: 'bottom'
      },
      animation: {
        type: 'fade',
        duration: applicationEnvironment.notification.fadeDuration
      },
      type: {
        style: 'success',
        icon: true
      }
    };

  public httpError = (error: HttpErrorResponse): NotificationSettings =>
    <NotificationSettings>{
      content: this._translationService.instant(error.message),
      hideAfter: applicationEnvironment.notification.hideAfter,
      position: {
        horizontal: 'center',
        vertical: 'bottom'
      },
      animation: {
        type: 'fade',
        duration: applicationEnvironment.notification.fadeDuration
      },
      type: {
        style: 'error',
        icon: true
      }
    };

  //#endregion
}
