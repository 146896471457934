<ng-container [ngSwitch]="type">
  <clevermailing-text-editor *ngSwitchCase="attributeTypes.Text"
                             [editorAttribute]="editorAttribute"> </clevermailing-text-editor>
  <clevermailing-datetime-editor *ngSwitchCase="attributeTypes.Datetime"
                                 [editorAttribute]="editorAttribute"> </clevermailing-datetime-editor>
  <clevermailing-number-editor *ngSwitchCase="attributeTypes.Number"
                               [editorAttribute]="editorAttribute"></clevermailing-number-editor>
  <clevermailing-checkbox-editor *ngSwitchCase="attributeTypes.Checkbox"
                                 [editorAttribute]="editorAttribute"></clevermailing-checkbox-editor>
  <clevermailing-combobox-editor *ngSwitchCase="attributeTypes.Combobox"
                                 [editorAttribute]="editorAttribute"></clevermailing-combobox-editor>
  <div *ngSwitchDefault
       class="missing">
    [{{ attributeTypes[type] }}] {{ value?.value | json }}
  </div>
</ng-container>