import { Directive, Injectable, Input } from '@angular/core';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { CustomFilterBase } from '../models/filter/base/customFilter.base';

@Injectable()
@Directive()
export abstract class FilterBaseComponent<
  TDefinition extends AttributeDefinitionBase,
  TFilter extends CustomFilterBase<TModel>,
  TModel extends any> {
  //#region -- fields --

  private _definition: TDefinition;
  private _itemFilter: TFilter;

  //#endregion

  //#region -- properties --

  @Input()
  public set definition(value: TDefinition) {
    this._definition = value;
  }

  public get definition(): TDefinition {
    return this._definition;
  }

  @Input()
  public set itemFilter(value: TFilter) {
    this._itemFilter = value;
  }

  public get regex(): string {
    return this._itemFilter.regex;
  }

  public get model(): TModel {
    return this._itemFilter.model;
  }

  public set model(value: TModel) {
    this._itemFilter.model = value;
  }

  //#endregion
}
