import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { AttributeTypes } from 'src/common/webapi/contracts/enum/attributeTypes';
import { Resource } from 'src/common/webapi/contracts/resource';
import { ValueChangedInfo } from './valueChangedInfo';

@Component({
  selector: 'clevermailing-cell-selector',
  templateUrl: './cell-selector.component.html',
  styleUrls: ['./cell-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellSelectorComponent {
  //#region -- export enum --

  attributeTypes = AttributeTypes;

  //#endregion

  //#region -- fields --

  private readonly _valueChangedEventEmitter: EventEmitter<ValueChangedInfo>;

  private _attributeDefinition: AttributeDefinitionBase;
  private _resource: Resource;

  //#endregion

  //#region -- properties --

  @Input()
  public set resource(value: Resource) {
    this._resource = value;
  }

  public get resource(): Resource {
    return this._resource;
  }

  @Input()
  public set definition(value: AttributeDefinitionBase) {
    this._attributeDefinition = value;
  }

  public get definition(): AttributeDefinitionBase {
    return this._attributeDefinition;
  }

  @Output()
  public get valueChanged(): EventEmitter<ValueChangedInfo> {
    return this._valueChangedEventEmitter;
  }

  //#endregion

  //#region -- constructor --

  public constructor() {
    this._valueChangedEventEmitter = new EventEmitter<ValueChangedInfo>();
  }

  //#endregion

  //#region -- methods --

  public onValueChanged = (value: any): void =>
    this._valueChangedEventEmitter.emit(<ValueChangedInfo>{
      definition: this.definition,
      value: value
    });

  //#endregion
}
