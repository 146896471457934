import { OptionBase } from './option.base';

export class TextOption
  extends OptionBase {
  //#region -- fields --

  public text: string;

  //#endregion
}
