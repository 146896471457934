import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'clevermailing-view-title',
  templateUrl: './view-title.component.html',
  styleUrls: ['./view-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewTitleComponent {
  //#region -- fields --

  private _title: string;
  private _itemCount: number;

  //#endregion

  //#region -- properties --

  @Input()
  public set title(value: string) {
    this._title = value;
  }

  public get title(): string {
    return this._title;
  }

  @Input()
  public set notificationCount(value: number) {
    this._itemCount = value;
  }

  public get notificationCount(): number {
    return this._itemCount;
  }

  //#endregion
}
