import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TextOption } from '../../models/options/textOption';
import { ItemBaseComponent } from '../item.base.component';

@Component({
  selector: 'clevermailing-text-option-item',
  templateUrl: './text-option-item.component.html',
  styleUrls: ['./text-option-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextOptionItemComponent
  extends ItemBaseComponent<TextOption>{ }
