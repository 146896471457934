import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { List } from 'linqts';
import { UserInfo } from 'src/common/webapi/contracts/authorization/userInfo';
import { SubscriptionBase } from 'src/shared/base/subscription.base';
import { ViewCommand } from 'src/shared/components/grid/models/command/viewCommand';
import { DialogHelper } from 'src/shared/helper/dialog.helper';
import { AuthenticationService } from 'src/shared/services/authentication.service';
import { UserService } from 'src/shared/services/user.service';
import { ImpersonationSettingsComponent } from '../impersonation-settings/impersonation-settings.component';
import { UserSettingsComponent } from '../user-settings/user-settings.component';

@Component({
  selector: 'clevermailing-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileInfoComponent
  extends SubscriptionBase
  implements OnDestroy {
  //#region -- configuration --

  private readonly _pathToLogin: string = '/login';

  //#endregion

  //#region -- commands --

  private readonly _commands: List<{ isLoggedIn: boolean; command: ViewCommand<any> }> = new List<{ isLoggedIn: boolean; command: ViewCommand<any> }>(
    [
      {
        isLoggedIn: true,
        command: new ViewCommand('PROFILE.LOGOUT', 'fa-solid fa-sign-out-alt', '', () => this._userService.isLoggedIn, () => this.logout())
      }
    ]);

  //#endregion

  //#region -- fields --

  private readonly _userService: UserService;
  private readonly _changeDetector: ChangeDetectorRef;
  private readonly _dialogService: DialogService;
  private readonly _authorizationService: AuthenticationService;
  private readonly _router: Router;

  private _openDialogRef: any;

  //#endregion

  //#region -- properties --

  public get icon(): string {
    return 'far fa-user';
  }

  public get name(): string {
    return `${this._userService.userInfo?.lastName}, ${this._userService.userInfo?.firstName}`;
  }

  public get userName(): string {
    return this._userService.userInfo?.name;
  }

  public get role(): string {
    return this._userService.userInfo?.groups?.join(', ');
  }

  public get isLoggedIn(): boolean {
    return this._userService.isLoggedIn;
  }

  public get userInfo(): UserInfo {
    return this._userService.userInfo;
  }

  public get commands(): ViewCommand<any>[] {
    return this._commands
      .Where(item => item.isLoggedIn === this._userService.isLoggedIn)
      .Select(item => item.command)
      .ToArray();
  }

  public get openDialogRef(): DialogRef {
    return this._openDialogRef;
  }

  public get isAdmin(): boolean {
    return this._userService.mustImpersonate;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    userService: UserService,
    changeDetector: ChangeDetectorRef,
    dialogService: DialogService,
    authorizationService: AuthenticationService,
    router: Router
  ) {
    super();

    this._userService = userService;
    this._changeDetector = changeDetector;
    this._dialogService = dialogService;
    this._authorizationService = authorizationService;
    this._router = router;

    this.addSubscriptions(
      [
        this._userService
          .userInfoChanged
          .subscribe(() => this._changeDetector.detectChanges())
      ]);
  }

  //#endregion

  //#region -- methods --

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  public showUserSettings = (template: TemplateRef<UserSettingsComponent>): DialogRef =>
    this._openDialogRef = this._dialogService.open(DialogHelper.selectUserSettingsSettings(template));

  public showImpersonationSettings = (template: TemplateRef<ImpersonationSettingsComponent>): DialogRef =>
    this._openDialogRef = this._dialogService.open(DialogHelper.selectImpersonationSettingsSettings(template));

  private logout = (): void => {
    this._authorizationService.logout();
    this._userService.logout();
  };

  //#endregion
}
