<div>
  <span>{{ 'GRID.FILTER.VALUES.HINT' | translate }}</span>
  <kendo-multiselect [data]="items"
                     [(ngModel)]="model"
                     [valueField]="'value'"
                     [textField]="'text'"
                     [valuePrimitive]="true">
    <ng-template kendoMultiSelectItemTemplate
                 let-dataItem
                 [ngSwitch]="type">
      <clevermailing-text-option-item *ngSwitchCase="viewItemTypes.TextOption"
                                      [model]="dataItem"></clevermailing-text-option-item>
      <clevermailing-icon-option-item *ngSwitchCase="viewItemTypes.IconOption"
                                      [model]="dataItem"></clevermailing-icon-option-item>
      <clevermailing-icon-text-option-item *ngSwitchCase="viewItemTypes.IconTextOption"
                                           [model]="dataItem"></clevermailing-icon-text-option-item>
    </ng-template>
    <ng-template kendoMultiSelectTagTemplate
                 let-dataItem
                 [ngSwitch]="type">
      <clevermailing-text-option-item *ngSwitchCase="viewItemTypes.TextOption"
                                      [model]="dataItem"></clevermailing-text-option-item>
      <clevermailing-icon-option-item *ngSwitchCase="viewItemTypes.IconOption"
                                      [model]="dataItem"></clevermailing-icon-option-item>
      <clevermailing-icon-text-option-item *ngSwitchCase="viewItemTypes.IconTextOption"
                                           [model]="dataItem"></clevermailing-icon-text-option-item>
    </ng-template>
  </kendo-multiselect>
</div>