import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { AttributeTypes } from 'src/common/webapi/contracts/enum/attributeTypes';
import { isNullOrUndefined } from 'src/shared/helper/object.helper';
import { EditorAttribute } from '../models/editorAttribute';
import { EditorItem } from '../models/editorItem';

@Component({
  selector: 'clevermailing-editor-selector',
  templateUrl: './editor-selector.component.html',
  styleUrls: ['./editor-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorSelectorComponent {
  //#region -- export enum --

  attributeTypes = AttributeTypes;

  //#endregion

  //#region -- fields --

  private _attributeDefinition: AttributeDefinitionBase;
  private _item: EditorItem;
  private _editorItemAttribute: EditorAttribute;

  //#endregion

  //#region -- properties --

  @Input()
  public set item(value: EditorItem) {
    this._item = value;
    this._editorItemAttribute = this._item.getEditorAttribute(this._attributeDefinition);
  }

  @Input()
  public set definition(value: AttributeDefinitionBase) {
    this._attributeDefinition = value;
  }

  public get type(): AttributeTypes {
    return this._attributeDefinition.type;
  }

  public get editorAttribute(): EditorAttribute {
    if (isNullOrUndefined(this._editorItemAttribute))
      this._editorItemAttribute = this._item.getEditorAttribute(this._attributeDefinition);

    return this._editorItemAttribute;
  }

  //#endregion
}
