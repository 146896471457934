import { isNotNullOrUndefinedOrEmpty } from 'src/shared/helper/object.helper';

export abstract class LinkBase {
  //#region -- fields --

  public url: string;
  public target: string;

  //#endregion

  //#region -- properties --

  public get hasLink(): boolean {
    return isNotNullOrUndefinedOrEmpty(this.url);
  }

  //#endregion
}
