/* eslint-disable no-bitwise */
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Mapper } from '@automapper/types';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { List } from 'linqts';
import { GridColumn } from 'src/common/models/gridColumn';
import { VisibleColumnInfoModel } from 'src/common/models/visibleColumnInfoModel';
import { MAPPER } from 'src/common/token/tokens';
import { AttributeDefinitionBase } from 'src/common/webapi/contracts/attributes/bases/attributeDefinitionBase';
import { AttributeAccessibilities } from 'src/common/webapi/contracts/enum/attributeAccessibilities';
import { SubscriptionBase } from 'src/shared/base/subscription.base';
import { EnumHelper } from 'src/shared/helper/enum.helper';
import { isNullOrUndefined } from 'src/shared/helper/object.helper';
import { ViewCommand } from '../grid/models/command/viewCommand';

@Component({
  selector: 'clevermailing-column-selector',
  templateUrl: './column-selector.component.html',
  styleUrls: ['./column-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnSelectorComponent
  extends SubscriptionBase {
  //#region -- commands --

  private readonly _commands: ViewCommand<any>[] =
    [
      new ViewCommand('COLUMN_SELECTOR.COMMANDS.ACCEPT', 'fa-solid fa-check', '', () => this.canAccept(), () => this.accept()),
      new ViewCommand('COLUMN_SELECTOR.COMMANDS.DELETE', 'fa-solid fa-undo-alt', '', () => this.canReset(), () => this.reset()),
      new ViewCommand('COLUMN_SELECTOR.COMMANDS.CLOSE', 'fa-solid fa-times', '', () => true, () => this._dialogRef.close())
    ];

  //#endregion

  //#region -- fields --

  private readonly _mapper: Mapper;
  private readonly _visibleColumnsChanged: EventEmitter<any>;

  private _mappedDefinitonColumns: List<GridColumn>;
  private _visibleColumns: GridColumn[];
  private _dialogRef: DialogRef;
  private _data: GridColumn[];
  private _visibleColumnInfoModel: VisibleColumnInfoModel;

  //#endregion

  //#region -- properties --

  @Input()
  public set dialogRef(value: DialogRef) {
    this._dialogRef = value;
  }

  @Input()
  public set visibleColumns(value: VisibleColumnInfoModel) {
    if (isNullOrUndefined(value))
      return;

    this._visibleColumnInfoModel = value;

    this._mappedDefinitonColumns = GridColumn
      .getOrderedDefinitions(this._visibleColumnInfoModel.definition)
      .Select(column => this._mapper.map(column, GridColumn, <any>AttributeDefinitionBase));
    this._visibleColumns = this._visibleColumnInfoModel.visibleColumns;

    this.mapState();
  }

  @Output()
  public get visibleColumnsChange(): EventEmitter<VisibleColumnInfoModel> {
    return this._visibleColumnsChanged;
  }

  public get isAllSelected(): boolean {
    return new List(this._data)
      .Where(item => !this.isRequired(item))
      .All(item => item.isSelected);
  }

  public get isIndeterminate(): boolean {
    const data = new List(this._data)
      .Where(item => !this.isRequired(item));

    return data.Any(item => item.isSelected)
      && data.Any(item => !item.isSelected);
  }

  public get data(): GridColumn[] {
    return this._data;
  }

  public get commands(): ViewCommand<any>[] {
    return this._commands;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    @Inject(MAPPER) mapper: Mapper
  ) {
    super();

    this._mapper = mapper;

    this._visibleColumnsChanged = new EventEmitter<any>();
  }

  //#endregion

  //#region -- methods --

  public toggle = (): void => {
    const state = !this.isAllSelected;

    new List(this._data)
      .Where(item => !this.isRequired(item))
      .ForEach(dataItem => dataItem.isSelected = state);
  };

  public canReset = (): boolean =>
    this.canSelectAllColumns() && this.hasAnythingChanged();

  public reset = (): void => {
    const defaultColumns = GridColumn.getDefaultColumns(this._mappedDefinitonColumns);

    new List(this._data)
      .ForEach(dataItem => dataItem.isSelected = defaultColumns.Any(defdaultItem => defdaultItem.definition.key === dataItem.definition.key));
  };

  public canAccept = (): boolean =>
    this.canSelectAllColumns() && this.hasAnythingChanged();

  public accept = (): void => {
    this._visibleColumns = new List(this._data)
      .Where(item => item.isSelected)
      .ToArray();

    this._visibleColumnInfoModel.visibleColumns = this._visibleColumns;
    this._visibleColumnsChanged.emit(this._visibleColumnInfoModel);

    this._dialogRef.close();
  };

  public isRequired = (item: GridColumn): boolean =>
    EnumHelper.matchFlag<AttributeAccessibilities>(item.definition.accessibility, AttributeAccessibilities.ColumnSelectorRequiredColumn);

  public canSelectAllColumns = (): boolean =>
    !(new List(this._data).All(item => EnumHelper.matchFlag<AttributeAccessibilities>(item.definition.accessibility, AttributeAccessibilities.ColumnSelectorRequiredColumn)));

  private mapState = (): void => {
    if (isNullOrUndefined(this._mappedDefinitonColumns) || isNullOrUndefined(this._visibleColumns))
      return;

    const visibleColumns = new List(this._visibleColumns)
      .Select(column => column.definition.key);

    this._mappedDefinitonColumns
      .ForEach(mapped => mapped.isSelected = visibleColumns.Contains(mapped.definition.key));

    this._data = this._mappedDefinitonColumns.ToArray();
  };


  // todo
  private hasAnythingChanged = (): boolean => {
    return true;
  };

  //#endregion
}
