import { applicationEnvironment } from 'src/environments/application.environment';
import { AttributeValue } from './attributes/values/attributeValue';
import { ResourceTypes } from './enum/resourceTypes';

export class Resource {
  //#region -- fields --

  public id: number;
  public type: ResourceTypes;
  public displayName: string;
  public values: AttributeValue[];

  //#endregion

  //#region -- constructors --

  public constructor() {
    this.values = <AttributeValue[]>[];
  }

  //#endregion

  //#region -- methods  --

  public static createNew = (): Resource =>
    <Resource>{
      id: applicationEnvironment.defaults.identifier.forNewItemId,
      values: <AttributeValue[]>[]
    };

  //#endregion
}
