/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ClientUser } from 'src/common/webapi/contracts/clientUser';
import { LoopingHttpClient } from 'src/shared/http/loopingHttpClient';
import { ClientInfo } from '../../common/webapi/contracts/clientInfo';

@Injectable({
  providedIn: 'root',
})
export class ImpersonationService {
  //#region -- configuration --

  private static readonly urlToClients: string = `impersonation/clients`;
  private static readonly urlToClientUsers: (id: number) => string = id => `impersonation/client/${id}/users`;
  private static readonly urlToImpersonate: (id: number) => string = id => `impersonation/${id}`;

  //#endregion

  //#region -- fields --

  private readonly _httpClient: LoopingHttpClient;
  private readonly _clientsSource: Subject<ClientInfo[]>;
  private readonly _usersSource: Subject<ClientUser[]>;
  private readonly _impersonationSource: Subject<boolean>;

  //#endregion

  //#region -- properties --

  public get clientsChanged(): Observable<ClientInfo[]> {
    return this._clientsSource.asObservable();
  }

  public get usersChanged(): Observable<ClientUser[]> {
    return this._usersSource.asObservable();
  }

  public get impersonationChanged(): Observable<boolean> {
    return this._impersonationSource.asObservable();
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    httpClient: LoopingHttpClient
  ) {
    this._httpClient = httpClient;

    this._clientsSource = new Subject<ClientInfo[]>();
    this._usersSource = new Subject<ClientUser[]>();
    this._impersonationSource = new Subject<boolean>();
  }

  //#endregion

  //#region -- methods --

  public fetchClients = (): Subscription =>
    this._httpClient
      .get<ClientInfo[]>(ImpersonationService.urlToClients)
      .subscribe(clients => this._clientsSource.next(clients));

  public fetchUsersForClient = (clientId: number): Subscription =>
    this._httpClient
      .get<ClientUser[]>(ImpersonationService.urlToClientUsers(clientId))
      .subscribe(users => this._usersSource.next(users));

  public impersonate = (userId: number): Subscription =>
    this._httpClient
      .update(ImpersonationService.urlToImpersonate(userId), undefined)
      .subscribe(() => this._impersonationSource.next(true));

  //#endregion
}
