import { NgModule } from '@angular/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/de/all';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
  imports:
    [
      GridModule,
      DialogsModule,
      InputsModule,
      ButtonsModule,
      DateInputsModule,
      DropDownsModule,
      IntlModule,
      NotificationModule,
      LayoutModule,
      IndicatorsModule,
      TooltipModule
    ],
  exports:
    [
      GridModule,
      DialogsModule,
      InputsModule,
      ButtonsModule,
      DateInputsModule,
      DropDownsModule,
      IntlModule,
      NotificationModule,
      LayoutModule,
      IndicatorsModule,
      TooltipModule
    ]
})
export class KendoModule { }
