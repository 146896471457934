import { Injectable } from '@angular/core';
import { Resource } from 'src/common/webapi/contracts/resource';
import { ItemService } from '../../../shared/services/item.service';

@Injectable()
export class UserSettingsItemService
  extends ItemService {
  //#region -- methods --

  public fetch = (): void =>
    this.callWithLoadingIndicator(
      this.httpClient
        .get<Resource>(this.getResourceIdPath(this.urlToItem)),
      result => this.resourceSource.next(result));

  //#endregion
}
