import { DialogResultState } from './enums/dialogResultState';

export class DialogResult {
  //#region -- fields --

  private _state: DialogResultState;

  //#endregion

  //#region -- properties --

  public get state(): DialogResultState {
    return this._state;
  }

  //#endregion

  //#region -- constructor --

  public constructor(
    state: DialogResultState
  ) {
    this._state = state;
  }

  //#endregion
}
